import { useCallback } from "react";
import { useQuery } from "react-query";
import { UserEntity } from "../../utils/ntgidapi/generatedApi/data-contracts";
import { ntgIdApi } from "../../utils/ntgidapi/NtgIdApi";
import { QueryWrapper } from "../../utils/QueryWrapper";
import { FilteredUserListWithAddRemove } from "../Users/UserListWithAddRemove";

export function RoleUsers({ id, name }: { id: number, name: string }) {
  const qRoleUsers = useQuery(['RoleUsers'], () => ntgIdApi.v0UserroleList().then(response => response.data));

  const userFilter = useCallback(function (user: UserEntity) {
    return !!(qRoleUsers.data?.find(ur => ur.roleId === id && ur.userId === user.id));
  }, [qRoleUsers.data, id]);

  return (
    <QueryWrapper QueryResult={qRoleUsers}>
      <FilteredUserListWithAddRemove userFilter={userFilter} ensembleId={id} ensembleType='Role' ensembleName={name} />
    </QueryWrapper>);
}