import { Controller, FieldValues } from "react-hook-form";
import { FormInputProp } from "./FormInputPropType";
import { FormInputText, FormInputTextProp } from "./FormInputText";

export type FormTextAreaProps<TfieldValues extends FieldValues> = Omit<FormInputTextProp<TfieldValues>, 'multiline'>;

export function FormTextArea<TfieldValues extends FieldValues> (props: FormTextAreaProps<TfieldValues>) {
  const extra = {
    ...props,
    multiline: true,
  };
  return <FormInputText {...extra} />;
}