import { useMemo } from "react";
import { useQuery } from "react-query";
import { UserEntity } from "../generatedApi/data-contracts";
import { ntgIdApi } from "../NtgIdApi";

export function useUser(userId: number) {
  const qUser = useQuery(['user', userId], () => {
    if (userId <= 0) {
      return new Promise<UserEntity>((resolve) => {
        const emptyUser: UserEntity = {
          id: 0,
          userName: "login",
          firstName: "First Name",
          lastName: "Last Name",
          email: "Email"
        }
        resolve(emptyUser);
      });
    }
    else {
      return ntgIdApi.v0UserDetail(userId).then(response => response.data);
    }
  });

  return qUser;
}

export function useGoogleUser(userId: number) {
  const qGoogleUsers = useQuery(['googleUser'], () => ntgIdApi.v0GoogleUserList());

  const data = useMemo(() => {
    if (!qGoogleUsers.data) {
      return undefined;
    } else {
      return qGoogleUsers.data.data.find(g => g.userId === userId);
    }
  }, [qGoogleUsers.data, userId]);

  return {
    ...qGoogleUsers,
    data
  };
}
