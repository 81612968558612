import { Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConfirmDialog, useAxiosDescription, useConfirmDialog } from "../../hooks/useConfirmDialog";
import { ntgIdApi } from "../../utils/ntgidapi/NtgIdApi";
import { useAudience } from "../../utils/ntgidapi/hooks/useAudience";
import { QueryWrapper } from "../../utils/QueryWrapper";
import { BaseRole, RoleEntityStrict } from "./RoleBase";

export function RoleCreate() {
  const [audId, setAudId] = useState(0);
  const qAud = useAudience(audId);
  const roleCreateMut = useConfirmDialog({ mutationFn: (role: RoleEntityStrict) => ntgIdApi.v0RoleCreate(role) });
  const descCallback = useCallback((arg: RoleEntityStrict) => (<>
    <Typography variant="h6">Audience: <QueryWrapper QueryResult={qAud}>{qAud.data?.name}</QueryWrapper></Typography>
    <Typography variant="h6">Description</Typography>
    <Typography variant="body1">{arg.description}</Typography>
  </>), [qAud.data]);
  const description = useAxiosDescription(descCallback);

  const roleBaseSubmit = useCallback((role: RoleEntityStrict) => {
    setAudId(role.audienceId);
    roleCreateMut.startDiag(role);
  }, [roleCreateMut.startDiag]);

  const Role = {
    id: 0,
    name: '',
    description: '',
    audienceId: 0
  }

  const navigate = useNavigate();

  return (<>
    <Typography variant='h5'>Create role</Typography>
    <ConfirmDialog ConfirmDialogHookResult={roleCreateMut} Description={description} Title={(arg) => `Create role ${arg.name}`} onSuccess={(data) => navigate(`/Role/${data.data.id}`)} CloseOnSuccess={true} />
    <BaseRole mode='create' Entity={Role} onSubmit={roleBaseSubmit} />
  </>);
}