import { Box } from "@mui/material";
import React, { ReactNode } from "react";
import { UseQueryResult } from "react-query";
import { AxiosError } from "../components/Errors/AxiosErrorHandler";
import { Loading } from "../components/Loading/Loading";

export interface UseQueryLikeResultError<TError> {
  isError: true;
  isLoading: false;
  error: TError;
}

export interface UseQueryLikeResultErrorLoading<TError> {
  isError: true;
  isLoading: true;
  error: TError;
}

export interface UseQueryLikeResultLoading {
  isError: false;
  isLoading: true;
  error: null;
}

export interface UseQueryLikeResultDone {
  isError: false;
  isLoading: false;
  error: null;
}

export type UseQueryLikeResult<TError>  = UseQueryLikeResultError<TError> | UseQueryLikeResultLoading | UseQueryLikeResultErrorLoading<TError> | UseQueryLikeResultDone;

export interface useQueryWrapperProps<TError = unknown> {
  QueryResult: UseQueryLikeResult<TError>;
  ErrorRenderer?: (arg: TError) => ReactNode;
  LoadingRenderer?: () => ReactNode;
  children: ReactNode;
}

export function QueryWrapper<TError>({
  QueryResult,
  children,
  ErrorRenderer,
  LoadingRenderer }: useQueryWrapperProps<TError>) {
  let content: ReactNode = undefined;

  if (QueryResult.isLoading) {
    if (LoadingRenderer) {
      content = LoadingRenderer();
    } else {
      content = <Loading />;
    }
  } else if (QueryResult.isError) {
    if (ErrorRenderer) {
      content = ErrorRenderer(QueryResult.error);
    } else {
      content = <AxiosError text={'TODO'} error={QueryResult.error} />;
    }
  } else {
    content = children;
  }

  return <>{content}</>;
}

export interface useMultiQueryWrapperProps {
  QueryResults: UseQueryLikeResult<unknown>[];
  ErrorRenderer?: (arg: unknown) => ReactNode;
  LoadingRenderer?: () => ReactNode;
  children: ReactNode;
}

export function MultiQueryWrapper({
  QueryResults,
  children,
  ErrorRenderer,
  LoadingRenderer }: useMultiQueryWrapperProps) {
  let content: ReactNode = undefined;

  if (QueryResults.find(qr => qr.isLoading)) {
    if (LoadingRenderer) {
      content = LoadingRenderer();
    } else {
      content = <Loading />;
    }
  } else if (QueryResults.find(qr => qr.isError)) {
    const errors = QueryResults.filter(qr => qr.isError).map(qr => qr.error);
    if (ErrorRenderer) {
      content = errors.map(error => ErrorRenderer(error));
    } else {
      <AxiosError text={'TODO'} error={errors} />;
    }
  } else {
    content = children;
  }

  return <>{content}</>;
}