import { createTable } from "@tanstack/react-table";
import { useCallback, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AudienceWithScopesAndUsers } from "../../utils/ntgidapi/ntgIdTypes";
import { ntgIdFullAudiences, useNtgIdGetFullAudiences } from "../../utils/ntgidapi/useNtgIdGetAll";
import { scopesCellFormater } from "../../utils/baseScopeUtils";
import { AxiosError } from "../Errors/AxiosErrorHandler";
import { CustomLink } from "../Link/CustomLink";
import { TableUnified } from "../Table/TableUnified";
import { TextButton } from "../Buttons/TextButton";
import { User } from "../Users/User";
import { PartialList } from "../Utils/PartialList";

let table = createTable().setRowType<AudienceWithScopesAndUsers>();

export function FilteredAudiences({ audienceFilter }: { audienceFilter: (data: ntgIdFullAudiences) => AudienceWithScopesAndUsers[] }) {
  const { isLoading, isError, data, error } = useNtgIdGetFullAudiences();

  const columns = useMemo(() => ([
    table.createDataColumn('name', {
      header: () => <span>Audience Name</span>,
      footer: props => props.column.id,
      cell: info => {
        const audId = info.row.original?.id;
        return <CustomLink key={audId} to={"/Audience/" + audId}>{info.getValue()}</CustomLink>
      }
    }),
    table.createDataColumn('users', {
      header: () => <span>All users</span>,
      footer: props => props.column.id,
      cell: info => {
        var userItems = Array.from(info.getValue().values()).sort((ua, ub) => ua.userName.localeCompare(ub.userName)).map(user => {return {
          value: <CustomLink to={"/User/" + user.id}>{user.userName}</CustomLink>,
          key: user.id.toString()
        }});
        return <PartialList elems={userItems} />;
      }
    }),
    table.createDataColumn('scopes', {
      header: () => <span>All Scopes</span>,
      footer: props => props.column.id,
      cell: info => scopesCellFormater(Array.from(info.getValue().values()))
    }),
  ]), []);

  const audiences = useMemo(() => {
    if (!data)
      return [];

    return audienceFilter(data);

  }, [audienceFilter, data]);

  if (isLoading)
    return <div>Loading audiences ...</div>

  if (isError)
    return <AxiosError error={error} text={'Error loading audiences'} />

  return <div><TableUnified table={table} columns={columns} data={audiences} /></div>;
}

export function Audiences() {
  const filter = useCallback(function (data: ntgIdFullAudiences) {
    return data.fullAudiences.sort((aa, ab) => aa.name.localeCompare(ab.name));
  }, []);
  const navigate = useNavigate();

  return (<><TextButton onClick={() => navigate('/CreateAudience')}>Create Audience</TextButton>
    <FilteredAudiences audienceFilter={filter} /></>);
}