import { Typography } from "@mui/material";
import { createTable } from "@tanstack/react-table";
import { useCallback, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { ConfirmDialog, useAxiosDescription, useConfirmDialog } from "../../hooks/useConfirmDialog";
import { ntgIdApi } from "../../utils/ntgidapi/NtgIdApi";
import { flatGroup, flatNoGroup } from "../../utils/ntgidapi/ntgIdTypes";
import { ntgIdAllData, ntgIdAllDataExtended } from "../../utils/ntgidapi/useNtgIdGetAll";
import { QueryWrapper } from "../../utils/QueryWrapper";
import { AxiosError } from "../Errors/AxiosErrorHandler";
import { FilteredGroups } from "../Groups/Groups";
import { BaseRole, RoleBaseMode, RoleEntityStrict } from "./RoleBase";
import { RoleUsers } from "./RoleUsers";

let table = createTable().setRowType<flatNoGroup>();

export function RoutedRole() {
  const param = useParams();
  const id = +(param.RoleId || 0);

  if (id > 0)
    return <Role id={id} />
  else
    return <div>Error </div>
}

export function Role({ id }: { id: number }) {
  const qRole = useQuery(['role', id], () => ntgIdApi.v0RoleDetail(id));
  const [roleBaseMode, setRoleBaseMode] = useState<RoleBaseMode>('view');
  const roleEditMutation = useConfirmDialog({ mutationFn: (role: RoleEntityStrict) => ntgIdApi.v0RoleUpdate(role) });
  const title = useCallback(() => 'Edit role', []);
  const descCallback = useCallback((role: RoleEntityStrict) => (<>
    <Typography variant='h6'>Name</Typography>
    <Typography variant='body1'>{`${qRole.data?.data.name} => ${role.name}`}</Typography>
    <Typography variant="h6">Old Description: </Typography>
    <Typography variant="body1">{qRole.data?.data.description}</Typography>
    <Typography variant="h6">New Description: </Typography>
    <Typography variant="body1">{role.description}</Typography>
  </>
  ), [qRole.data]);
  const description = useAxiosDescription(descCallback);

  const roleBaseSubmit = useCallback((role: RoleEntityStrict) => {
    if (roleBaseMode === 'view') {
      setRoleBaseMode('edit');
    } else if (roleBaseMode === 'edit') {
      roleEditMutation.startDiag(role);
    }
  }, [roleBaseMode, roleEditMutation.startDiag, setRoleBaseMode])

  const roleData: RoleEntityStrict = useMemo(() => ({
    id: qRole.data?.data.id || 0,
    name: qRole.data?.data.name || '',
    description: qRole.data?.data.description || '',
    audienceId: qRole.data?.data.audienceId || 0
  }), [qRole.data?.data]);

  const groupFilter = useCallback(function (allData: ntgIdAllDataExtended) {
    const ret: Array<flatGroup> = [];
    allData.groupRoles.forEach(gr => {
      if (gr.roleId === id)
        ret.push(...(allData.flatGroups.get(gr.groupId || 0) || []));
    });
    return ret;
  }, [id]);

  const groupTitle = <Typography variant='h5'>Groups</Typography>;

  return (<>
    <Typography variant='h5'>Role</Typography>
    <QueryWrapper QueryResult={qRole}>
      <ConfirmDialog ConfirmDialogHookResult={roleEditMutation} Description={description} Title={title} onSuccess={() => setRoleBaseMode('view')} CloseOnSuccess={true} />
      <BaseRole Entity={roleData} mode={roleBaseMode} onSubmit={roleBaseSubmit} onCancel={roleBaseMode === 'edit' ? () => setRoleBaseMode('view') : undefined} />
      <FilteredGroups groupFilter={groupFilter} title={groupTitle} hideIfZeroRows />
      <Typography variant='h5'>Users</Typography>
      <RoleUsers id={id} name={qRole.data?.data.name || ''} />
    </QueryWrapper>
  </>);
}