import { Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ConfirmDialog, useAxiosDescription, useConfirmDialog } from "../../hooks/useConfirmDialog";
import { useAudience } from "../../utils/ntgidapi/hooks/useAudience";
import { ntgIdApi } from "../../utils/ntgidapi/NtgIdApi";
import { ntgIdAllDataExtended } from "../../utils/ntgidapi/useNtgIdGetAll";
import { QueryWrapper } from "../../utils/QueryWrapper";
import { FilteredRoles } from "../Roles/RoleList";
import { AudienceBase, AudienceBaseMode, AudienceEntityStrict } from "./AudienceBase";

export function RoutedAudience() {
  const param = useParams();
  const id = +(param.AudienceId || 0);

  if (id > 0)
    return <Audience id={id} />
  else
    return <div>Error</div>;
}

export function Audience({ id }: { id: number }) {
  const qAudience = useAudience(id);
  const [audBaseMode, setAudBaseMode] = useState<AudienceBaseMode>('view');
  const audEditMutation = useConfirmDialog({ mutationFn: (aud: AudienceEntityStrict) => ntgIdApi.v0AudienceUpdate(aud) });
  const descCallback = useCallback((arg: AudienceEntityStrict) => (<>
    <Typography variant="h6">Old Description: </Typography>
    <Typography variant="body1">{qAudience.data?.description}</Typography>
    <Typography variant="h6">New Description: </Typography>
    <Typography variant="body1">{arg.description}</Typography>
  </>), [qAudience.data]);
  const description = useAxiosDescription(descCallback);

  const audBaseSubmit = useCallback((aud: AudienceEntityStrict) => {
    if (audBaseMode === 'view') {
      setAudBaseMode('edit');
    } else if (audBaseMode === 'edit') {
      audEditMutation.startDiag(aud);
    }
  }, [audBaseMode, audEditMutation.startDiag, setAudBaseMode]);

  const Audience = useMemo(() => ({
    id: qAudience.data?.id || 0,
    name: qAudience.data?.name || '',
    description: qAudience.data?.description || ''
  }), [qAudience.data]);

  const roleTitle = useMemo(() => <Typography variant='h6'>Roles</Typography>, []);

  const roleFilter = useCallback((data: ntgIdAllDataExtended) => {
    const allRoles = Array.from(data.flatRoles.values());
    return allRoles.filter(r => r.audienceId === Audience.id);
  }, [Audience.id]);

  return (<>
    <Typography variant='h5'>Audience</Typography>
    <QueryWrapper QueryResult={qAudience}>
      <ConfirmDialog ConfirmDialogHookResult={audEditMutation} Description={description} Title={() => `Edit audience ${Audience.name}`} onSuccess={() => setAudBaseMode('view')} CloseOnSuccess={true} />
      <AudienceBase mode={audBaseMode} Audience={Audience} onSubmit={audBaseSubmit} onCancel={audBaseMode === 'edit' ? () => setAudBaseMode('view') : undefined} />
    <FilteredRoles title={roleTitle} roleFilter={roleFilter} hideIfZeroRows hideAudColumn/>
    </QueryWrapper>
  </>);
}