import { Typography } from "@mui/material";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ConfirmDialog, useAxiosDescription, useConfirmDialog } from "../../hooks/useConfirmDialog";
import { ntgIdApi } from "../../utils/ntgidapi/NtgIdApi";
import { CompanyBase, CompanyEntityStrict } from "./CompanyBase";

export function CompanyCreate() {
  const companyCreateMut = useConfirmDialog({ mutationFn: (company: CompanyEntityStrict) => ntgIdApi.v0CompanyCreate(company) });
  const descCallback = useCallback((arg: CompanyEntityStrict) => (<>
    <CompanyBase mode='view' Entity={arg} noButtons />
  </>), []);
  const description = useAxiosDescription(descCallback);

  const companyBaseSubmit = useCallback((company: CompanyEntityStrict) => {
    companyCreateMut.startDiag(company);
  }, [companyCreateMut.startDiag]);

  const Company = {
    id: 0,
    name: '',
    description: '',
    groupId: 0,
    googleHd: ''
  }

  const navigate = useNavigate();

  return (<>
    <Typography variant='h5'>Create company</Typography>
    <ConfirmDialog ConfirmDialogHookResult={companyCreateMut} Description={description} Title={(arg) => `Create company ${arg.name}`} onSuccess={(data) => navigate(`/Company/${data.data.id}`)} CloseOnSuccess={true} />
    <CompanyBase mode='create' Entity={Company} onSubmit={companyBaseSubmit} />
  </>);

}