import { useCallback } from "react";
import { useQuery } from "react-query";
import { UserEntity } from "../../utils/ntgidapi/generatedApi/data-contracts";
import { ntgIdApi } from "../../utils/ntgidapi/NtgIdApi";
import { QueryWrapper } from "../../utils/QueryWrapper";
import { FilteredUserListWithAddRemove } from "../Users/UserListWithAddRemove";

export function GroupUsers({ id, name }: { id: number, name: string }) {
  const qUg = useQuery(['UserGroups'], () => ntgIdApi.v0UsergroupList().then(response => response.data));

  const userFilter = useCallback(function (user: UserEntity) {
    return !!(qUg.data?.find(ug => ug.groupId === id && ug.userId === user.id));
  }, [qUg.data, id]);

  return (<QueryWrapper QueryResult={qUg}>
    <FilteredUserListWithAddRemove userFilter={userFilter} ensembleName={name} ensembleType="Group" ensembleId={id}/>
  </QueryWrapper>);
}