import { Typography } from "@mui/material";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ConfirmDialog, useAxiosDescription, useConfirmDialog } from "../../hooks/useConfirmDialog";
import { ntgIdApi } from "../../utils/ntgidapi/NtgIdApi";
import { GoogleClientBase, GoogleClientEntityStrict } from "./GoogleClientIdBase";

export function GoogleClientCreate() {
  const gcidCreateMut = useConfirmDialog({ mutationFn: (gcid: GoogleClientEntityStrict) => ntgIdApi.v0GoogleClientIdCreate(gcid) });
  const descCallback = useCallback((arg: GoogleClientEntityStrict) => (<>
    <Typography variant='h6'>Google Client Id</Typography>
    <Typography variant='body1'>{arg.googleClientId}</Typography>
  </>), []);
  const description = useAxiosDescription(descCallback);

  const baseSubmit = useCallback((arg: GoogleClientEntityStrict) => {
    gcidCreateMut.startDiag(arg);
  }, [gcidCreateMut]);

  const gcid = {
    id: 0,
    audienceId: 0,
    googleClientId: '',
    description: ''
  };

  const navigate = useNavigate();

  return (<>
    <Typography variant='h5'>Create google client id</Typography>
    <ConfirmDialog ConfirmDialogHookResult={gcidCreateMut} Description={description} Title={(arg) => `Create google client id`} onSuccess={(data) => navigate(`/GoogleClientId/${data.data.audienceId}/${data.data.googleClientId}`)} CloseOnSuccess={true} />
    <GoogleClientBase mode='create' Entity={gcid} onSubmit={baseSubmit} />
  </>);
}