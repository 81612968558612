import { BaseEntityStrict, BaseScopeAudience, BaseProps, BaseMode } from "../Common/BaseScopeAudience";

export type ScopeEntityStrict = BaseEntityStrict;

export interface ScopeBaseProps extends Omit<BaseProps, 'Entity'> {
  Scope: ScopeEntityStrict;
}

export type ScopeBaseMode = BaseMode;

export function ScopeBase({Scope, ...rest}: ScopeBaseProps) {
  return <BaseScopeAudience {...{...rest, Entity: Scope}} />;
}