import { Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { ConfirmDialog, useAxiosDescription, useConfirmDialog } from "../../hooks/useConfirmDialog";
import { ntgIdApi } from "../../utils/ntgidapi/NtgIdApi";
import { ntgIdFullAudiences } from "../../utils/ntgidapi/useNtgIdGetAll";
import { QueryWrapper } from "../../utils/QueryWrapper";
import { GoogleClientBase, GoogleClientBaseMode, GoogleClientEntityStrict } from "./GoogleClientIdBase";
import { FilteredAudiences } from '../Audience/AudienceList';
import { useParams } from "react-router-dom";

export interface GoogleClientIdProps {
  audienceId: number;
  googleClientId: string;
}

export function GoogleClientId({ audienceId, googleClientId }: GoogleClientIdProps) {
  const qGoogleClientId = useQuery(['googleClientId', audienceId, googleClientId],
    () => ntgIdApi.v0GoogleClientIdDetail(audienceId, googleClientId));
  const [gcidMode, setGcidMode] = useState<GoogleClientBaseMode>('view');
  const gcidEditMutation = useConfirmDialog({ mutationFn: (gcid: GoogleClientEntityStrict) => ntgIdApi.v0GoogleClientIdUpdate(gcid)});
  const descCallback = useCallback((arg: GoogleClientEntityStrict) => (<>
    <Typography variant="h6">Old Description: </Typography>
    <Typography variant="body1">{qGoogleClientId.data?.data.description}</Typography>
    <Typography variant="h6">New Description: </Typography>
    <Typography variant="body1">{arg.description}</Typography>
  </>), [qGoogleClientId.data]);
  const description = useAxiosDescription(descCallback);
  const submit = useCallback((gcid: GoogleClientEntityStrict) => {
    if (gcidMode === 'view') {
      setGcidMode('edit');
    } else if (gcidMode === 'edit') {
      gcidEditMutation.startDiag(gcid);
    }
  }, [qGoogleClientId.data, gcidMode, gcidEditMutation]);

  const audienceFilter = useCallback((data: ntgIdFullAudiences) => {
    if (!qGoogleClientId) {
      return [];
    } else {
      return data.fullAudiences.filter(a => a.id === qGoogleClientId.data?.data.audienceId);
    }
  }, [qGoogleClientId.data]);

  const gcid = useMemo(() => ({
    id: qGoogleClientId.data?.data.id || 0,
    audienceId: qGoogleClientId.data?.data.audienceId || 0,
    googleClientId: qGoogleClientId.data?.data.googleClientId || '',
    description: qGoogleClientId.data?.data.description || '',
  }), [qGoogleClientId]);

  return (<>
    <Typography variant='h5'>Google Client Id</Typography>
    <QueryWrapper QueryResult={qGoogleClientId}>
      <ConfirmDialog ConfirmDialogHookResult={gcidEditMutation} Description={description} Title={() => `Edit Google Client Id`} onSuccess={() => setGcidMode('view')} CloseOnSuccess={true} />
      <GoogleClientBase mode={gcidMode} Entity={gcid} onSubmit={submit} onCancel={gcidMode === 'edit' ? () => setGcidMode('view') : undefined} />
    <Typography variant='h5'>Audience</Typography>
      <FilteredAudiences audienceFilter={audienceFilter} />
    </QueryWrapper>
  </>)
}

export function RoutedGoogleClientId() {
  const param = useParams();
  const audienceId = +(param.AudienceId || 0);
  const googleClientId = param.GoogleClientId || '';

  return <GoogleClientId audienceId={audienceId} googleClientId={googleClientId} />;
}