import { useCallback, useMemo } from "react";
import { FieldValues, SubmitHandler, UnpackNestedValue, UseFormHandleSubmit, UseFormReset } from "react-hook-form";
import { TextButton } from "../../Buttons/TextButton";
import { BaseMode } from "../BaseScopeAudience";


export interface UseFormButtonsProps<TFieldValues extends FieldValues> {
  mode: BaseMode;
  onSubmit: SubmitHandler<TFieldValues>;
  onCancel?: () => void;
  ButtonLabel?: (arg: BaseMode) => string | undefined;
  noButtons?: boolean;
  reset: UseFormReset<TFieldValues>;
  resetParam: UnpackNestedValue<TFieldValues>;
  handleSubmit: UseFormHandleSubmit<TFieldValues>;
}

export function useFormButtons<TFieldValues extends FieldValues>({mode, onSubmit, onCancel, ButtonLabel, noButtons, reset, handleSubmit, resetParam} : UseFormButtonsProps<TFieldValues>) {
  const onCancelWrap = useCallback( () => {
    reset(resetParam);
    if (onCancel) {
      onCancel();
    }
  }, [onCancel, reset, resetParam]);

  const cancelButton = useMemo(() => {
    if (onCancel)
      return <TextButton onClick={onCancelWrap}>Cancel</TextButton>;
    return undefined;
  }, [onCancelWrap, onCancel]);

  const formButtons = useMemo(() => {
    if (noButtons) {
      return undefined;
    }
    let buttonLabel = ButtonLabel && ButtonLabel(mode);
    if (!buttonLabel) {
      if (mode === 'view') {
        buttonLabel = 'Edit';
      } else if (mode === 'edit') {
        buttonLabel = 'Save';
      } else {
        buttonLabel = 'Create';
      }
    }
    if (cancelButton) {
      return (<>
        {cancelButton}
        <TextButton onClick={handleSubmit(onSubmit)}>{buttonLabel}</TextButton>
      </>);
    }
    else return <TextButton onClick={handleSubmit(onSubmit)}>{buttonLabel}</TextButton>;
  }, [mode, ButtonLabel, cancelButton, noButtons, onSubmit, handleSubmit]);

  return formButtons;
}