import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { TextButton } from "../Buttons/TextButton";

export function Error404() {
  const navigate = useNavigate();

  return (<Box>
    404
    This page is not available, go <TextButton onClick={() => navigate(-1)}>Back</TextButton>
  </Box>);
}