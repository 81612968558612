import { Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { ConfirmDialog, useAxiosDescription, useConfirmDialog } from "../../hooks/useConfirmDialog";
import { ntgIdApi } from "../../utils/ntgidapi/NtgIdApi";
import { QueryWrapper } from "../../utils/QueryWrapper";
import { GroupBase, GroupBaseMode, GroupEntityStrict } from "./GroupBase";
import { GroupRoles } from "./GroupRoles";
import { GroupUsers } from "./GroupUsers";

export function RoutedGroup() {
  const param = useParams();
  const id = +(param.GroupId || 0);

  if (id > 0)
    return <Group id={id} />
  else
    return <div>Error </div>
}

export function Group({ id }: { id: number }) {
  const qGroup = useQuery(['group', id], () => ntgIdApi.v0GroupDetail(id));
  const [groupBaseMode, setGroupBaseMode] = useState<GroupBaseMode>('view');
  const groupEditMutation = useConfirmDialog({ mutationFn: (group: GroupEntityStrict) => ntgIdApi.v0GroupUpdate(group) });
  const groupData = useMemo(() => {
    if (!qGroup.data)
      return undefined;
    return qGroup.data.data;
  }, [qGroup.data?.data]);

  const descCallback = useCallback((arg: GroupEntityStrict) => (<>
    <Typography variant="h6">Old Description: </Typography>
    <Typography variant="body1">{groupData?.description}</Typography>
    <Typography variant="h6">New Description: </Typography>
    <Typography variant="body1">{arg.description}</Typography>
  </>), [qGroup.data]);
  const diagDescription = useAxiosDescription(descCallback);

  const groupBaseSubmit = useCallback((group: GroupEntityStrict) => {
    if (groupBaseMode === 'view') {
      setGroupBaseMode('edit');
    } else if (groupBaseMode === 'edit') {
      groupEditMutation.startDiag(group);
    }
  }, [groupBaseMode, groupEditMutation.startDiag, setGroupBaseMode]);

  const Group = useMemo(() => ({
    id: groupData?.id || 0,
    name: groupData?.name || '',
    description: groupData?.description || ''
  }), [groupData]);

  return (<>
    <Typography variant='h5'>Group</Typography>
    <QueryWrapper QueryResult={qGroup}>
      <ConfirmDialog ConfirmDialogHookResult={groupEditMutation} Description={diagDescription} Title={() => `Edit group ${Group.name}`} onSuccess={() => setGroupBaseMode('view')} CloseOnSuccess={true} />
      <GroupBase mode={groupBaseMode} Group={Group} onSubmit={groupBaseSubmit} onCancel={groupBaseMode === 'edit' ? () => setGroupBaseMode('view') : undefined} />
      <Typography variant='h5'>Roles</Typography>
      <GroupRoles groupId={id} groupName={qGroup.data?.data.name || ''} />
      <Typography variant='h5'>Users</Typography>
      <GroupUsers id={id} name={qGroup.data?.data.name || ''} />
    </QueryWrapper>
  </>);
}