import { Grid, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ConfirmDialog, useAxiosDescription, useConfirmDialog } from "../../hooks/useConfirmDialog";
import { UserEntity } from "../../utils/ntgidapi/generatedApi/data-contracts";
import { useCompany } from "../../utils/ntgidapi/hooks/useCompany";
import { ntgIdApi } from "../../utils/ntgidapi/NtgIdApi";
import { QueryWrapper } from "../../utils/QueryWrapper";
import { FilteredUserList } from "../Users/UserList";
import { CompanyBase, CompanyBaseMode, CompanyEntityStrict } from "./CompanyBase";

export interface CompanyProps {
  id: number;
}

export function RoutedCompany() {
  const param = useParams();
  const id = +(param.CompanyId || 0);

  if (id > 0)
    return <Company id={id} />
  else
    return <div>Error </div>
}

export function Company({ id }: CompanyProps) {
  const qCompany = useCompany(id);
  const [compBaseMode, setCompBaseMode] = useState<CompanyBaseMode>('view');
  const companyEditMutation = useConfirmDialog({ mutationFn: (comp: CompanyEntityStrict) => ntgIdApi.v0CompanyUpdate(comp) });
  const Company = useMemo(() => ({
    id: qCompany.data?.id || 0,
    name: qCompany.data?.name || '',
    description: qCompany.data?.description || '',
    googleHd: qCompany.data?.googleHd || '',
    groupId: qCompany.data?.groupId || 0
  }), [qCompany.data]);
  const descCallback = useCallback((arg: CompanyEntityStrict) => (<>
    <Grid container direction='row'>
      <Grid item>
        <Typography variant='h5'>Old</Typography>
        <CompanyBase mode='view' noButtons Entity={Company} />
      </Grid>
      <Grid item>
        <Typography variant='h5'>New</Typography>
        <CompanyBase mode='view' noButtons Entity={arg} />
      </Grid>
    </Grid>
  </>), [qCompany.data]);
  const description = useAxiosDescription(descCallback);

  const compBaseSubmit = useCallback((comp: CompanyEntityStrict) => {
    if (compBaseMode === 'view') {
      setCompBaseMode('edit');
    } else if (compBaseMode === 'edit') {
      companyEditMutation.startDiag(comp);
    }
  }, [compBaseMode, companyEditMutation.startDiag, setCompBaseMode]);

  const userFilter = useCallback((user: UserEntity) => user.companyId === Company.id, [Company]);

  return (<>
    <Typography variant='h5'>Company</Typography>
    <QueryWrapper QueryResult={qCompany}>
      <ConfirmDialog ConfirmDialogHookResult={companyEditMutation} Description={description} Title={() => `Edit company${Company.name}`} onSuccess={() => setCompBaseMode('view')} CloseOnSuccess={true} />
      <CompanyBase mode={compBaseMode} Entity={Company} onSubmit={compBaseSubmit} onCancel={compBaseMode === 'edit' ? () => setCompBaseMode('view') : undefined} />
      <Typography variant='h5'>Users</Typography>
      <FilteredUserList userFilter={userFilter} />
    </QueryWrapper>
  </>);

}


/*
      */