import { useCallback } from "react";
import { ntgIdApi } from "../../utils/ntgidapi/NtgIdApi";
import { ntgIdAllDataExtended } from "../../utils/ntgidapi/useNtgIdGetAll";
import { FilteredRoles } from "../Roles/RoleList";

export function GroupRoles({ groupId, groupName }: { groupId: number, groupName: string }) {
  const filter = useCallback((data: ntgIdAllDataExtended) => {
    const roles = data.flatGroups.get(groupId);

    if (roles && (roles.length > 1 || (roles.length === 1 && roles[0].roleName)))
      return roles;

    return [];
  }, [groupId]);
  const groupRoleAdd = useCallback((roleId: number) => ntgIdApi.v0GrouproleCreate({ roleId, groupId }), [groupId]);
  const groupRoleDel = useCallback((roleId: number) => ntgIdApi.v0GrouproleDelete({ roleId, groupId }), [groupId]);


  return <FilteredRoles roleFilter={filter} roleAddFn={groupRoleAdd} roleRemoveFn={groupRoleDel} ensembleType="Group" ensembleName={groupName} />
}