// https://gist.github.com/pmckee11/13b1dffbf1d271a782ed7f65480b978f

import React, { FunctionComponent, useEffect, useState } from 'react';
import { Helmet, HelmetProvider, HelmetTags } from 'react-helmet-async';
import { isLocalhost } from '../../serviceWorker';
import { ntgIdApi } from '../../utils/ntgidapi/NtgIdApi';

const googleUrl = 'https://accounts.google.com/gsi/client';

export interface GoogleCredentialResponse {
  credential: string;
}

interface GoogleButtonParams {
  onCredentialResponse: (response: GoogleCredentialResponse) => void;
}

const GoogleButton: FunctionComponent<GoogleButtonParams> = ({
  onCredentialResponse,
}) => {
  const [scriptLoaded, setScriptLoaded] = useState(
    typeof window !== 'undefined' && typeof (window as any).google !== 'undefined'
  );
  const divRef = React.createRef<HTMLDivElement>();

  // Helmet does not support the onLoad property of the script tag, so we have to manually add it like so
  const handleChangeClientState = (newState: any, addedTags: HelmetTags) => {
    if (addedTags && addedTags.scriptTags) {
      const foundScript = addedTags.scriptTags.find(
        ({ src }: {src: string}) => src === googleUrl
      );
      if (foundScript) {
        foundScript.addEventListener('load', () => {
          (window as any).google.accounts.id.initialize({
            client_id: isLocalhost ?
             '324316226925-vagodi6nu48kcr74uviaf5fn9o9g75pj.apps.googleusercontent.com' :
             '143232686552-uqhhcf342s097317plvhs78alatsj5k4.apps.googleusercontent.com',
            callback: onCredentialResponse,
          });
          (window as any).google.accounts.id.prompt();
          setScriptLoaded(true);
          }, {
          once: true,
        });
      }
    }
  };

  useEffect(() => {
    if (scriptLoaded && divRef.current) {
      (window as any).google.accounts.id.renderButton(divRef.current, {
        theme: 'outline',
        size: 'large',
        width: divRef.current.clientWidth,
      });
    }
  }, [scriptLoaded, divRef, onCredentialResponse]);

  return (
    <>
        <Helmet onChangeClientState={handleChangeClientState}>
          <script src={googleUrl} async defer />
        </Helmet>
      <div ref={divRef} />
    </>
  );
};

export default GoogleButton;
