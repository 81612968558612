import { createTable } from "@tanstack/react-table";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { ntgIdApi } from "../../utils/ntgidapi/NtgIdApi"
import { QueryWrapper } from "../../utils/QueryWrapper";
import { TextButton } from "../Buttons/TextButton";
import { CustomLink } from "../Link/CustomLink";
import { TableUnified } from "../Table/TableUnified";
import { ScopeEntityStrict } from "./ScopeBase";

let table = createTable().setRowType<ScopeEntityStrict>();

export function ScopeList() {
  const qScopes = useQuery(['scopeList'], () => ntgIdApi.v0ScopeList());
  const data = useMemo(() => {
    if (!qScopes.data)
      return [];
    const looseScopes = qScopes.data.data;

    return looseScopes.map(s => ({
      id: s.id || 0,
      name: s.name,
      description: s.description
    }));
  }, [qScopes.data, qScopes.status]);

  const columns = useMemo(() => ([
    table.createDataColumn('name', {
      header: () => <span>Scope Name</span>,
      footer: props => props.column.id,
      cell: info => {
        const scopeId = info.row.original?.id;
        return <CustomLink key={scopeId} to={"/Scope/" + scopeId}>{info.getValue()}</CustomLink>
      }
    }),
    table.createDataColumn('description', {
      header: () => <span>Description</span>,
      footer: props => props.column.id,
      cell: info => info.getValue()
    }),
  ]), []);

  return (<QueryWrapper QueryResult={qScopes}>
    <TableUnified table={table} columns={columns} data={data} withFilters />
  </QueryWrapper>);
}

export function Scopes() {
  const navigate = useNavigate();

  return (<>
    <TextButton onClick={() => navigate('/CreateScope')}>Create scope</TextButton>
    <ScopeList />
  </>);
}