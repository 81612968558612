import { ExpandCircleDownOutlined, ExpandLessOutlined, ExpandMoreOutlined } from "@mui/icons-material";
import { Box, Collapse, IconButton } from "@mui/material";
import { ReactNode, useMemo, useState } from "react";

export interface PartialListItem {
  value: ReactNode;
  key: string;
}

export interface PartialListProps {
  elems: PartialListItem[];
  maxElems?: number;
  displayedElems?: number;
}

export function PartialList({ elems, ...rest }: PartialListProps) {
  const maxElems = rest.maxElems || 18;
  const displayedElems = rest.displayedElems || 14;
  const [collapsed, setCollapsed] = useState(true);
  const needsCollapse = useMemo(() => elems.length > maxElems, [elems]);

  const showElems = useMemo(() =>
    elems.slice(0, needsCollapse ? displayedElems : maxElems)
    , [elems, needsCollapse, displayedElems, maxElems]);

  const extraElems = useMemo(() =>
    elems.slice(needsCollapse ? displayedElems : maxElems)
    , [elems, needsCollapse, displayedElems, maxElems]);

  const showHideControl = useMemo<ReactNode>(() => {
    const icon = collapsed ? <ExpandMoreOutlined /> : <ExpandLessOutlined />;
    if (needsCollapse) {
      return (
        <IconButton size='small' onClick={() => setCollapsed(!collapsed)}>
          {icon}
        </IconButton>
      );
    } else {
      return undefined;
    }
  }, [needsCollapse, collapsed]);

  return (<>
    {showElems.map(e => <div key={e.key}>{e.value}</div>)}
    <Collapse in={!collapsed}>
      {extraElems.map(e => <div key={e.key}>{e.value}</div>)}
    </Collapse>
    <Box alignItems='center' justifyContent='center' display='flex'>
      {showHideControl}
    </Box>
  </>);
}