import { BackspaceOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, OutlinedInputProps } from "@mui/material";
import { useMemo, useState } from "react";
import { Visible } from "../Utils/visible";

export interface FormPasswordRawProps extends OutlinedInputProps {
  inputLabel?: string;
  helperText?: string;
  reset?: () => void;
}

export function FormPasswordRaw({ helperText, inputLabel, reset, ...other }: FormPasswordRawProps) {
  const [showPassword, setShowPassword] = useState(false);

  const endAdornment = (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={() => setShowPassword(!showPassword)}
        onMouseDown={(event) => event.preventDefault()}
        edge="end" >
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  );

  const label = useMemo(() => inputLabel || 'Password', [inputLabel]);

  return (
    <FormControl sx={{ minWidth: 300 }} size='small' variant="outlined">
      <InputLabel htmlFor="outlined-adornment-password" error={other.error}>{label}</InputLabel>
      <OutlinedInput
        id="outlined-adornment-password"
        type={showPassword ? 'text' : 'password'}
        endAdornment={
          <>
          <Visible visible={!!(reset)}>
            <InputAdornment position="end">
              <IconButton
                aria-label="Clear password"
                onClick={() => { reset && reset() } }
                onMouseDown={(event) => event.preventDefault()}
                edge="end"
              >
                <BackspaceOutlined/>
              </IconButton>
            </InputAdornment>
            </Visible>
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={(event) => event.preventDefault()}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          </>
        }
        label={label}
        {...other}
      />
      {helperText ? <FormHelperText error={other.error}>{helperText}</FormHelperText> : undefined}
    </FormControl>
  );

};