import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from "@mui/material";
import { ReactNode, useCallback, useMemo, useReducer, useState } from "react";
import { MutationFunction, useMutation, UseMutationOptions, UseMutationResult } from "react-query";
import { TextButton } from "../components/Buttons/TextButton";
import { AxiosError } from "../components/Errors/AxiosErrorHandler";
import { Loading } from "../components/Loading/Loading";
import { useStaleQueriesFn } from "./useStaleQueriesFn";

export interface useConfirmDialogProps<TData = unknown, TVariables = void> {
  mutationFn: MutationFunction<TData, TVariables>;
}

type dialogState<TVariables> = dialogStateOpened<TVariables> | dialogStateClosed;

interface dialogStateOpened<TVariables> {
  opened: true;
  mutationParams: TVariables;
}

interface dialogStateClosed {
  opened: false;
  mutationParams: undefined;
}

function init() {
  const inittialState: dialogStateClosed = {
    opened: false,
    mutationParams: undefined
  };
  return inittialState;
}

interface actionOpen<TVariables> {
  type: 'open';
  mutationParams: TVariables;
}

interface actionClose {
  type: 'close';
}

type actionDiag<TVariables = void> = actionClose | actionOpen<TVariables>;

function reducer<TVariables = void>(state: dialogState<TVariables>, action : actionDiag<TVariables>) : dialogState<TVariables> {
  if (action.type === 'open' && state.opened === false) {
    return {
      opened: true,
      mutationParams: action.mutationParams
    }
  } else if (action.type === 'close' && state.opened) {
    return {
      opened: false,
      mutationParams: undefined
    }
  } else {
    throw new Error("Wrong dialog options");
  }
}

export interface useConfirmDialogResult<TData = unknown, TError = unknown, TVariables = void, TContext = unknown> {
  mutation: UseMutationResult<TData, TError, TVariables, TContext>;
  startDiag: (arg: TVariables) => void;
  close: () => void;
  state: dialogState<TVariables>;
}

export function useConfirmDialog<TData = unknown, TError = unknown, TVariables = void, TContext = unknown>({mutationFn} : useConfirmDialogProps<TData, TVariables>) : useConfirmDialogResult<TData, TError, TVariables, TContext> {
  const mutation: UseMutationResult<TData, TError, TVariables, TContext> = useMutation(mutationFn);
  const reducerFunc: (state: dialogState<TVariables>, action : actionDiag<TVariables>) => dialogState<TVariables> = reducer;
  const [state, dispatch] = useReducer(reducerFunc, undefined, init);
  const startDiag = useCallback((arg: TVariables) => {
    mutation.reset();
    dispatch({
      type: 'open',
      mutationParams: arg
    });
  }, [] );
  const close = useCallback(() => {
    dispatch({
      type: 'close',
    });
  }, []);

  return {
    mutation,
    startDiag,
    state,
    close
  };
}

export type mutationStatus = 'error' | 'idle' | 'loading' | 'success';

export interface ConfirmDialogProps<TData, TError, TVariables, TContext> extends Omit<DialogProps, 'open'> {
  ConfirmDialogHookResult: useConfirmDialogResult<TData, TError, TVariables, TContext>;
  Description: ((arg:  TVariables, status: mutationStatus, data?: TData, error?: TError | null) => ReactNode);
  CloseOnSuccess: boolean;
  onSuccess: (arg: TData) => void;
  Title: ((arg: TVariables) => ReactNode);
};

export function ConfirmDialog<TData, TError, TVariables, TContext>({ ConfirmDialogHookResult: {mutation, state, close }, Description, CloseOnSuccess, onSuccess, Title, ...other }: ConfirmDialogProps<TData, TError, TVariables, TContext>) {
  const stale = useStaleQueriesFn();

  const dContent = useMemo(() => {
    if (state.opened)
      return Description(state.mutationParams, mutation.status, mutation.data, mutation.error);
  }, [Description, , mutation.status, mutation.error, state]);

  const dTitle= useMemo(() => {
    if (state.opened)
      return Title(state.mutationParams);
  }, [Description, , mutation.status, mutation.error, state]);

  const onSuccessCloseCb = useCallback((arg: TData) => {
    close();
    stale();
    onSuccess(arg);
  }, [close, onSuccess]);

  const mutateCb = useCallback(() => {
    const options = {
      onSuccess: onSuccessCloseCb
    }
    if (state.opened)
      return mutation.mutate(state.mutationParams, CloseOnSuccess ? options : undefined);
  }, [mutation.mutate, state.opened, state.mutationParams, onSuccessCloseCb, CloseOnSuccess]);

  const retryCb = useCallback(() => {
    mutation.reset();
    mutateCb();
  }, [mutateCb]);

  const dFooter = useMemo(() => {
    const status = mutation.status;
    if (status === 'idle') {
      return (<>
        <TextButton autoFocus onClick={close}>Cancel</TextButton>
        <TextButton onClick={mutateCb}>Ok</TextButton>
      </>);
    }
    if (status === 'loading') {
      return <Loading />;
    }
    if (status === 'error') {
      return (<>
          <TextButton autoFocus onClick={close}>Cancel</TextButton>
          <TextButton onClick={retryCb}>Retry</TextButton>
        </>);
    }
    if (status === 'success') {
      return ( <TextButton autoFocus onClick={() => onSuccessCloseCb(mutation.data)}>Close</TextButton>);
    }
  }, [mutation.status, mutateCb, mutation.error]);

  return <Dialog open={state.opened} maxWidth="md" {...other}>
    <DialogTitle>{dTitle}</DialogTitle>
    <DialogContent dividers>{dContent}</DialogContent>
    <DialogActions>{dFooter}</DialogActions>
  </Dialog>;
}

export function useAxiosDescription<TVariables = void>(description: (arg: TVariables) => ReactNode) {
  const ret = useCallback((arg:  TVariables, status: mutationStatus, data?: unknown, error?: unknown) => {
    if (status !== 'error')
      return description(arg);
    else
      return <AxiosError error={error} text={''} />
  }, [description]);
  return ret;
}