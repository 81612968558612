import { Grid, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ConfirmDialog, useAxiosDescription, useConfirmDialog } from "../../hooks/useConfirmDialog";
import { useGoogleUser, useUser } from "../../utils/ntgidapi/hooks/useUser";
import { ntgIdApi } from "../../utils/ntgidapi/NtgIdApi";
import { QueryWrapper } from "../../utils/QueryWrapper";
import CompanyName from "../Company/CompanyName";
import { MailTo } from "../Email/mailto";
import { AxiosError } from "../Errors/AxiosErrorHandler";
import ErrorBoundary from "../Errors/ErrorBoundary";
import { Password } from "../Password/Password";
import { UserGroupRoleRights } from "../Rights/UserGroupRoleRights";
import { UserRights } from "../Rights/UserRights";
import { GoogleUser } from "./GoogleUser";
import { UserBase, UserBaseMode, UserEntityStrict, useStrictUser } from "./UserBase";

export function RoutedUser() {
  const param = useParams();
  const id = +(param.UserId || 0);

  if (id > 0)
    return <User id={id} />
  else
    return <div>Error</div>;
}

export function User({ id }: { id: number }) {
  const qUser = useUser(id);

  const [userBaseMode, setUserBaseMode] = useState<UserBaseMode>('view');
  const userEditMutation = useConfirmDialog({ mutationFn: (user: UserEntityStrict) => ntgIdApi.v0UserUpdate(user) });
  const userBaseSubmit = useCallback((user: UserEntityStrict) => {
    if (userBaseMode === 'view') {
      setUserBaseMode('edit');
    } else if (userBaseMode === 'edit') {
      userEditMutation.startDiag(user);
    }
  }, [userBaseMode, userEditMutation.startDiag, setUserBaseMode]);
  const user = useStrictUser(qUser.data);

  const descCallback = useCallback((arg: UserEntityStrict) => (<>
    <Grid container direction='row'>
      <Grid item>
        <Typography variant='subtitle2'>Old values</Typography>
        <UserBase mode='view' Entity={user} onSubmit={() => { }} noButtons />
      </Grid>
      <Grid item>
        <Typography variant='subtitle2'>New values</Typography>
        <UserBase mode='view' Entity={arg} onSubmit={() => { }} noButtons />
      </Grid>
    </Grid>
  </>), [user]);
  const description = useAxiosDescription(descCallback);

  return (<>
    <Typography variant='h5'>User</Typography>
    <QueryWrapper QueryResult={qUser}>
      <ConfirmDialog ConfirmDialogHookResult={userEditMutation} Description={description} Title={() => `Edit user ${user.userName}`} onSuccess={() => setUserBaseMode('view')} CloseOnSuccess={true} maxWidth='xl' fullWidth={false} />
      <Grid container alignItems="center" direction="row" spacing={1}>
        <Grid item>
          <UserBase mode={userBaseMode} Entity={user} onSubmit={userBaseSubmit} onCancel={userBaseMode === 'edit' ? () => setUserBaseMode('view') : undefined} />
        </Grid>
        <Grid item>
          <GoogleUser id={id} />
        </Grid>
        <Grid item>
          <Typography variant='h6'>Edit password</Typography>
          <Password User={user} buttonText='Edit password' />
        </Grid>
      </Grid>

      <div style={{ width: '100%' }}>
        <h2>Individual user rights</h2>
        <UserRights userId={qUser.data?.id || 0} />
        <ErrorBoundary>
          <h2>Combined user rights (company, goups and roles)</h2>
          <UserGroupRoleRights userId={qUser.data?.id || 0} />
        </ErrorBoundary>
      </div>
    </QueryWrapper>
  </>);
}