import { Autocomplete, AutocompleteProps, FormControl, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { Controller, FieldValues } from "react-hook-form";
import { FormInputDropdownOption, FormInputDropdownProp } from "./FormInputDropDown";

export function FormAutoCompleteCombo<TfieldValues extends FieldValues>({ name, control, options, label }: FormInputDropdownProp<TfieldValues>) {
  return (<Controller
    control={control}
    name={name}
    render={({ field: { onChange, value } }) => (
      <FormControl sx={{ minWidth: 230 }} size='small'>
        <Autocomplete size='small'
          renderOption={(props, option) => {
            if (option.value === 0 && option.label === '') {
              return <li {...props}><em>None</em></li>;
            }
            return <li {...props}>{option.label}</li>;
          }}
          disablePortal id="combo-box-demo" options={options}
          renderInput={(params) => <TextField {...params} label={label} />}
          onChange={(_event, value) =>
            onChange(value?.value || 0)}
          value={options.find(e => e.value === value && value !== 0) || null}
        />
      </FormControl>
    )}
  />);
}

export interface FormAutocompleteRawOption {
  label: string;
  value: number;
}

export interface FormAutoCompleteComboRawProp extends Omit<AutocompleteProps<FormAutocompleteRawOption, undefined, undefined, undefined>, 'renderInput' | 'options' | 'value' | 'onChange'> {
  label: string
  options: FormAutocompleteRawOption[];
  value: number;
  onValueChange: (newVal: number) => void;
}

export function FormAutoCompleteComboRaw({ label, value, onValueChange, options, ...other }: FormAutoCompleteComboRawProp) {

  return (
    <FormControl sx={{ minWidth: 230 }} size='small'>
      <Autocomplete size='small'
        renderOption={(props, option) => {
          if (option.value === 0 && option.label === '') {
            return <li {...props}><em>None</em></li>;
          }
          return <li {...props}>{option.label}</li>;
        }}
        value={options.find(v => v.value === value) || null}
        onChange={(_event, value) => onValueChange(value?.value || 0)}
        disablePortal id="combo-box-demo"
        renderInput={(params) => <TextField {...params} label={label} />}
        options={options}
        {...other}
      />
    </FormControl>
  );
}