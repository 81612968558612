import { Box, Container, Grid } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { enhancedScopeRenderer } from "../../utils/baseScopeUtils";
import { RoleRightEntity } from "../../utils/ntgidapi/generatedApi/data-contracts";
import { useScopes } from "../../utils/ntgidapi/hooks/useScopes";
import { ntgIdApi } from "../../utils/ntgidapi/NtgIdApi";
import { useNtgIdGetAll } from "../../utils/ntgidapi/useNtgIdGetAll";
import { QueryWrapper } from "../../utils/QueryWrapper";
import { TextButton } from "../Buttons/TextButton";
import { BaseMode } from "../Common/BaseScopeAudience";
import { FormAutoCompleteCombo } from "../Form/FormAutocompleteCombo";
import { FormInputDropdown } from "../Form/FormInputDropDown";
import { FormInputText, FormInputTextRaw } from "../Form/FormInputText";
import { FormTextArea } from "../Form/FormTextArea";
import { Pool, PoolElement } from "../Utils/pool";
import { Visible } from "../Utils/visible";

export interface RoleEntityStrict {
  id: number;
  name: string;
  description: string;
  audienceId: number;
}

export type RoleBaseMode = BaseMode;

export interface RoleBaseProps {
  Entity: RoleEntityStrict;
  mode: RoleBaseMode;
  onSubmit: (ent: RoleEntityStrict) => void;
  onCancel?: () => void;
  ButtonLabel?: string;
}

export function BaseRole({ Entity, mode, ButtonLabel, onSubmit, onCancel }: RoleBaseProps) {
  const { register, handleSubmit, control, formState: { errors } } = useForm<RoleEntityStrict>({
    defaultValues: Entity
  });
  const ntgIdAllData = useNtgIdGetAll();
  const qScopes = useScopes();

  const cancelButton = useMemo(() => {
    if (onCancel)
      return <TextButton onClick={onCancel}>Cancel</TextButton>;
    return undefined;
  }, [onCancel]);

  const formButtons = useMemo(() => {
    let buttonLabel: string | undefined = ButtonLabel;
    if (!buttonLabel) {
      if (mode === 'view') {
        buttonLabel = 'Edit';
      } else if (mode === 'edit') {
        buttonLabel = 'Save';
      } else {
        buttonLabel = 'Create';
      }
    }
    if (cancelButton) {
      return (<>
        {cancelButton}
        <TextButton onClick={handleSubmit(onSubmit)}>{buttonLabel}</TextButton>
      </>);
    }
    else return <TextButton onClick={handleSubmit(onSubmit)}>{buttonLabel}</TextButton>;
  }, [mode, ButtonLabel, cancelButton]);

  const inputPropsDescription = {
    readOnly: (mode === 'view')
  };

  const inputPropsName = {
    ...inputPropsDescription,
  };

  const audSelector = useMemo(() => {
    if (!ntgIdAllData.data) {
      return;
    }
    const label = 'Audience';
    const auds = ntgIdAllData.data.audiences;
    if (mode !== 'create') {
      const audName = auds.find(a => a.id === Entity.audienceId)?.name || 'Error audience name';
      const extraProp = {
        readOnly: (mode === 'view'),
        disabled: (mode === 'edit'),
      }
      return <FormInputTextRaw label={label} name="audienceName" value={audName} {...extraProp} />;
    } else {
      const options = ntgIdAllData.data.audiences.map(a => ({ value: a.id || 0, label: a.name }));
      return <FormAutoCompleteCombo control={control} label={label} name="audienceId" options={options} />
    }
  }, [mode, ntgIdAllData.data, Entity.audienceId]);

  const scopePool = useMemo(() => {
    if (!qScopes.data || !ntgIdAllData.data) {
      return [];
    }
    // TODO: sort ?
    return qScopes.data.data.map(s => ({
      ...s,
      id: s.id || 0,
      selected: !!(ntgIdAllData.data.roleRights.find(rr => rr.scopeId === s.id && rr.roleId === Entity.id))
    }));
  }, [ntgIdAllData.data, qScopes.data, Entity.id]);

  const addFn = useCallback((scope: PoolElement) => {
    const rr: RoleRightEntity = {
      roleId: Entity.id,
      scopeId: scope.id
    };
    return ntgIdApi.v0RolerightCreate(rr);
  }, [Entity.id]);

  const removeFn = useCallback((scope: PoolElement) => {
    const rr: RoleRightEntity = {
      roleId: Entity.id,
      scopeId: scope.id
    };
    return ntgIdApi.v0RolerightDelete(rr);
  }, [Entity.id]);

  const addTitle = useCallback((scope: PoolElement) => {
    return `Add scope to role ${Entity.name}`;
  }, [Entity.name]);

  const removeTitle = useCallback((scope: PoolElement) => {
    return `Remove scope from role ${Entity.name}`;
  }, [Entity.name]);

  const addRemDesc = useCallback((scope: PoolElement) => {
    return `${scope.name}`;
  }, [Entity.name]);

  const scopeRenderer = useCallback((scope: PoolElement) => {
    return enhancedScopeRenderer(scope);
  }, []);

  const poolLabel = useMemo(() => 'Scope', []);

  return (
    <Container maxWidth="xl">
      <Box component="form" >
        <input hidden defaultValue={Entity.id} {...register('id')} />
        <Grid container alignItems="left" direction="column" spacing={1}>
          <Grid item>
            <FormInputText label="Name" control={control} name="name" InputProps={inputPropsName} />
          </Grid>
          <Grid item alignItems="center">
            <FormTextArea label="Description" control={control} name="description" minRows={2} fullWidth={true} InputProps={inputPropsDescription} />
          </Grid>
          <QueryWrapper QueryResult={ntgIdAllData}>
            <Grid item>
              {audSelector}
            </Grid>
            <Grid item >
              {formButtons}
            </Grid>
          </QueryWrapper>
        </Grid>
      </Box>
      <Grid container alignItems="left" direction="column" spacing={1}>
        <Visible visible={mode !== 'create'}>
          <QueryWrapper QueryResult={qScopes}>
            <Grid item >
              <Pool addFn={addFn} removeFn={removeFn} addDescription={addRemDesc} removeDescription={addRemDesc} addTitle={addTitle} removeTitle={removeTitle} renderer={scopeRenderer} pool={scopePool} label={poolLabel} />
            </Grid>
          </QueryWrapper>
        </Visible>
      </Grid>
    </Container>
  );
}