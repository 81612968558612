import { ReactNode } from "react";

export interface VisibleProps {
  children: ReactNode;
  visible: boolean;
};

export function Visible({children, visible}: VisibleProps) {
  if (visible) {
    return <>{children}</>;
  }
  return <></>;
}