//import base64url from "base64url";
import { Box, Grid } from "@mui/material";
import { useState } from "react";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { base64urlDecode } from "../../utils/base64urlDecode";
import { FormInputTextRaw } from "../Form/FormInputText";
import { MuiSyntaxHighlighter } from "../Utils/MuiSyntaxHighlighter";

function decodeTokenPart(t: string, i: number) {
  if (t === "")
    return "";
  try {
    const encoded = t.split(".")[i];
    let decoded = base64urlDecode(encoded);
    try {
      let a = JSON.parse(decoded);
      return JSON.stringify(a, null, 4);
    } catch { }
    return "Json error:\n" + decoded;
  }
  catch (e) {
    let errorMessage = "";
    if (typeof e === "string") {
      errorMessage = e;
    } else if (e instanceof Error) {
      errorMessage = e.message;
    }
    return "Decode error: " + errorMessage;
  }
}

export default function TokenDisplay({ token }: { token: string | undefined }) {
  const [displayedToken, setDisplayedToken] = useState(token || "");
  const [jwtHeader, setJwtHeader] = useState(decodeTokenPart(token || "", 0));
  const [jwtPayload, setJwtPayload] = useState(decodeTokenPart(token || "", 1));

  function updateDisplayedToken(t: string) {
    setDisplayedToken(t);
    setJwtHeader(decodeTokenPart(t, 0));
    setJwtPayload(decodeTokenPart(t, 1));
  }


  return (
    <>
      <Box m={3} >
        <Grid container direction='column' maxWidth='xl' spacing={1}>
          <Grid item>
            <FormInputTextRaw label='Token' multiline name="context" value={displayedToken} onChange={e => updateDisplayedToken(e.target.value)} rows={10} fullWidth />
          </Grid>
          <Grid item>
            <MuiSyntaxHighlighter fullWidth language="json" label='JWT header' >{jwtHeader}</MuiSyntaxHighlighter>
          </Grid>
          <Grid item>
            <MuiSyntaxHighlighter fullWidth language="json" label='JWT Payload' >{jwtPayload}</MuiSyntaxHighlighter>
          </Grid>
        </Grid>
      </Box>
    </>);
}