import { AddCircleOutlineOutlined, RemoveCircleOutlineOutlined } from "@mui/icons-material";
import { Box, Card, IconButton, Typography } from "@mui/material";
import { ReactNode, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { MutationFunction } from "react-query";
import { ConfirmDialog, mutationStatus, useConfirmDialog, useConfirmDialogResult } from "../../hooks/useConfirmDialog";
import { AddButtonIcon } from "../Buttons/AddButtonIcon";
import { RemoveButtonIcon } from "../Buttons/RemoveButtonIcon";
import { FormAutoCompleteCombo } from "../Form/FormAutocompleteCombo";
import { FormInputDropdown } from "../Form/FormInputDropDown";
import { OutlinedBox } from "./OutlinedBox";

export interface PoolPropVars {
  id: number;
  name: string;
}

export interface PoolElement extends PoolPropVars {
  selected: boolean;
}

export interface PoolProps<TPoolElement, TError> {
  addFn: MutationFunction<unknown, TPoolElement>;
  removeFn: MutationFunction<unknown, TPoolElement>;
  addDescription: ((arg: TPoolElement, status: mutationStatus, data?: unknown, error?: TError | null) => ReactNode);
  removeDescription: ((arg: TPoolElement, status: mutationStatus, data?: unknown, error?: TError | null) => ReactNode);
  addTitle: (arg: TPoolElement) => ReactNode;
  removeTitle: (arg: TPoolElement) => ReactNode;
  renderer: (arg: TPoolElement) => ReactNode;
  pool: TPoolElement[];
  label: string;
}

interface Id {
  id: number;
}

export function Pool<TVariables extends PoolElement, TError>({ addFn, removeFn, addDescription, removeDescription, addTitle, removeTitle, renderer, pool, label }: PoolProps<TVariables, TError>) {
  const addMut: useConfirmDialogResult<unknown, TError, TVariables, unknown> = useConfirmDialog({ mutationFn: addFn });
  const delMut: useConfirmDialogResult<unknown, TError, TVariables, unknown> = useConfirmDialog({ mutationFn: removeFn });
  const { handleSubmit, control, formState: { errors } } = useForm<Id>({
    defaultValues: { id: 0 }
  });
  const addSubmit = (arg: Id) => {
    const addElem = pool.find(e => e.id === arg.id);
    if (addElem)
      addMut.startDiag(addElem);
  };

  const selectedArray: ReactNode[] = useMemo(() => {
    const elems = pool.filter(e => e.selected).map(e => {
      const eRend = renderer(e);
      return (<Box component="span" key={e.id}>
        {eRend}<RemoveButtonIcon onClick={() => delMut.startDiag(e)} /> </Box>);
    });
    return elems;
  }, [pool]);

  const options = useMemo(() =>{
    const ret = pool.filter(e => !e.selected).map(e => ({ value: e.id, label: e.name }));
    ret.unshift({ value: 0, label: ''});
    return ret;
  }, [pool]);

  return (
    <OutlinedBox fullWidth>
      <Typography variant='h6'>Scopes</Typography>
      <Box sx={{ wordSpacing: '1em' }}>
        <ConfirmDialog ConfirmDialogHookResult={addMut} Description={addDescription} Title={addTitle} onSuccess={() => { }} CloseOnSuccess={true} />
        <ConfirmDialog ConfirmDialogHookResult={delMut} Description={removeDescription} Title={removeTitle} onSuccess={() => { }} CloseOnSuccess={true} />
        {selectedArray.map(e => (
          e))}
        <Box component='span'><Box component="form" sx={{ mt: 2 }}>
          <FormAutoCompleteCombo label={label} options={options} name='id' control={control} />
          <AddButtonIcon onClick={handleSubmit(addSubmit)} />
        </Box> </Box>
      </Box>
    </OutlinedBox>
  );
}