import { BaseEntityStrict, BaseScopeAudience } from "../Common/BaseScopeAudience";

export type AudienceEntityStrict = BaseEntityStrict;

export type AudienceBaseMode = 'edit' | 'view' | 'create';

export interface AudienceBaseProps {
  Audience: AudienceEntityStrict;
  mode: AudienceBaseMode;
  onSubmit: (aud: AudienceEntityStrict) => void;
  onCancel?: () => void;
}

export function AudienceBase(props: AudienceBaseProps) {
  const transformedProps = {
    ...props,
    Entity: props.Audience
  };
  return <BaseScopeAudience {...transformedProps} />;
}