import { Typography } from "@mui/material";
import { createTable } from "@tanstack/react-table";
import { ReactNode, useState, useMemo, useCallback } from "react";
import { MutationFunction, useQuery } from "react-query";
import { StringMappingType } from "typescript";
import { ConfirmDialog, useAxiosDescription, useConfirmDialog } from "../../hooks/useConfirmDialog";
import { useUserAddRemoveDiag, UseUserAddRemoveDiagParamsBase, UseUserAddRemResult } from "../../hooks/useUserAddRemoveDiag";
import { UserEntity } from "../../utils/ntgidapi/generatedApi/data-contracts";
import { useUser } from "../../utils/ntgidapi/hooks/useUser";
import { ntgIdApi } from "../../utils/ntgidapi/NtgIdApi";
import { MultiQueryWrapper, QueryWrapper } from "../../utils/QueryWrapper";
import { AddButtonIcon } from "../Buttons/AddButtonIcon";
import { RemoveButtonIcon } from "../Buttons/RemoveButtonIcon";
import CompanyLink from "../Company/CompanyLink";
import CompanyName from "../Company/CompanyName";
import { MailTo } from "../Email/mailto";
import { AxiosError } from "../Errors/AxiosErrorHandler";
import { FormAutoCompleteComboRaw, FormAutocompleteRawOption } from "../Form/FormAutocompleteCombo";
import { FormInputDropdownOption, FormInputDropdownRaw } from "../Form/FormInputDropDown";
import { CustomLink } from "../Link/CustomLink";
import { TableUnified } from "../Table/TableUnified";

interface UserEntityRenderer {
  id: number,
  Login: ReactNode,
  userName: string,
  firstName: string,
  lastName: string,
  email: string,
  last: boolean,
  companyId: number,
  companyName: string
}

let table = createTable().setRowType<UserEntityRenderer>();

export interface FilteredUserListWithAddRemoveProps {
  userFilter: ((u: UserEntity) => boolean);
  ensembleType: 'Group' | 'Role';
  ensembleName: string;
  ensembleId: number;
}

export function FilteredUserListWithAddRemove({ userFilter, ensembleId, ensembleName, ensembleType }:
  FilteredUserListWithAddRemoveProps) {
  const qUsers = useQuery(['v0UserList', 'all'], () => { return ntgIdApi.v0UserList(); });
  const qCompanies = useQuery(['v0CompanyList', 'all'], () => { return ntgIdApi.v0CompanyList(); });

  const getEnsembleName = useCallback(() => ensembleName, [ensembleName]);

  const userAddRemove = useUserAddRemoveDiag({
    ensembleId,
    getEnsembleName,
    ensembleType,
    mode: 'user',
  });
  const [userId, setUserId] = useState(0);

  const users = useMemo(() => {
    if (!qUsers.data)
      return [];

    const data = qUsers.data.data;
    const userRenderers = data.map(user => {
      const userRenderer: UserEntityRenderer = {
        id: user.id || 0,
        Login: <><CustomLink to={"/User/" + user.id}>{user.userName}</CustomLink><RemoveButtonIcon onClick={() => {
          if (user.id && user.id > 0)
            userAddRemove.startRemoveDiag(user.id);
        }} /></>,
        userName: user.userName || "",
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        email: user.email || "",
        last: false,
        companyId: user.companyId || 0,
        companyName: qCompanies.data?.data.find(c => c.id === user.companyId)?.name || ""
      };
      return userRenderer;
    })

    const ret = userRenderers.filter(userFilter);
    const selectableUsers = userRenderers.filter(u => !userFilter(u));

    if (userId > 0 && !selectableUsers.find(u => u.id === userId)) {
      setUserId(0);
    }

    const selectOptions: FormAutocompleteRawOption[] = selectableUsers.sort((a, b) => a.userName.localeCompare(b.userName)).map(u => ({ label: u.userName, value: u.id }));
    selectOptions.unshift({ label: '', value: 0 });

    const selector = (<><FormAutoCompleteComboRaw label="UserName" value={userId} onValueChange={setUserId} options={selectOptions} />
      <AddButtonIcon onClick={() => {
        if (userId > 0)
          userAddRemove.startAddDiag(userId);
      }} />
    </>);

    const selectedUser = qUsers.data.data.find(u => u.id === userId);
    const lastRow = {
      id: 0,
      last: true,
      Login: selector,
      userName: selectedUser ? (selectedUser.userName || '') : 'User Name',
      firstName: selectedUser ? (selectedUser.firstName || '') : 'First Name',
      lastName: selectedUser ? (selectedUser.lastName || '') : 'Last Name',
      email: selectedUser ? (selectedUser.email || '') : 'Email',
      companyId: selectedUser ? (selectedUser.companyId || 0) : 0,
      companyName: selectedUser ? (qCompanies.data?.data.find(c => c.id === selectedUser.companyId)?.name || "") : 'Company'
    };

    ret.push(lastRow);
    return ret;
  }, [qUsers.data, userId, userFilter, userAddRemove.startAddDiag, userAddRemove.startRemoveDiag]);

  const columns = useMemo(
    () =>
      ([
        table.createDataColumn('Login', {
          header: () => <span>Login</span>,
          sortingFn: (rowA, rowB, columnId) => {
            const userNameA = rowA.original?.userName;
            const userNameB = rowB.original?.userName;
            if (typeof userNameA === 'string' && typeof userNameB === 'string')
              return userNameA.localeCompare(userNameB);
            return 0;
          },
          cell: info => info.getValue()
        }),
        table.createDataColumn('firstName', {
          header: () => <span>First Name</span>,
        }),
        table.createDataColumn('lastName', {
          header: () => <span>Last Name</span>,
        }),
        table.createDataColumn('email', {
          header: () => 'Email Address',
          cell: info => {
            if (info.getValue().includes("@"))
              return <MailTo email={info.getValue()} />;
            return info.getValue();
          },
        }),
        table.createDataColumn('companyName', {
          header: () => <span>Company</span>,
          cell: (info) => {
            const companyId = info.row.original?.companyId || 0;
            return <CompanyLink id={+companyId} />;
          }
        }),
      ]), []);

  return (
    <MultiQueryWrapper QueryResults={[qUsers, qCompanies]}>
      <ConfirmDialog {...userAddRemove.addConfirmDiagProps} />
      <ConfirmDialog {...userAddRemove.removeConfirmDiagProps} />
      <TableUnified table={table} columns={columns} data={users} withLastRow={true} />
    </MultiQueryWrapper>);
}