import { Box, Grid, Typography } from "@mui/material";
import { useCallback } from "react";
import { ConfirmDialog, mutationStatus, useConfirmDialog } from "../../hooks/useConfirmDialog";
import { ntgIdApi } from "../../utils/ntgidapi/NtgIdApi";
import { TextButton } from "../Buttons/TextButton";
import { BasePassword } from "./BasePassword";

export interface SelfPasswordProps {
}

export function SelfPassword(props: SelfPasswordProps) {
  const passEditMutation = useConfirmDialog({ mutationFn: (password: string) => ntgIdApi.v0PasswordUpdate({ password: password }) });

  const description = useCallback((_password: string, status: mutationStatus, _data?: unknown, error?: unknown) => {
    if (status !== 'error') {
      return (<>{'Update password'}</>);
    } else {
      const err: any = error;
      if (err && err.response && err.response.status === 400 && err.response.data?.message) {
        return (<Box maxWidth='sm'>
          <Typography variant='h6'>Error</Typography>
          {err.response.data.message}
        </Box>);
      } else {
        return <>Error while trying to change password</>;
      }
    }
  }, []);

  const passControl = useCallback((password: string, resetPassword: () => void) => {
    return (<>
      <ConfirmDialog
        ConfirmDialogHookResult={passEditMutation}
        Description={description} Title={() => `Changing your password`}
        onSuccess={() => { resetPassword(); }}
        CloseOnSuccess={true}
        maxWidth='xl'
        fullWidth={false} />
      <TextButton onClick={() => passEditMutation.startDiag(password)}>Ok</TextButton>
    </>);
  }, []);


  return (<Grid container direction='column' spacing={2} alignItems='center'>
    <Grid item>
      <BasePassword passwordAction={passControl} />
    </Grid>
  </Grid>);
}
