import { Buffer } from 'buffer'

export function base64urlDecode(encodedBase64Url: string): string
{
  let encodedBase64 = encodedBase64Url.replace(/-/g, '+').replace(/_/g, '/');
  const pad = encodedBase64.length % 4;
  if (pad)
  {
    if (pad === 1)
      throw new Error('InvalidLengthError: Input base64url string is the wrong length to determine padding');
    encodedBase64 += new Array(5 - pad).join("=");
  }
  return Buffer.from(encodedBase64, "base64").toString();
}