import { useQuery } from "react-query";
import { ntgIdApi } from "../../utils/ntgidapi/NtgIdApi";
import { QueryWrapper } from "../../utils/QueryWrapper";
import { CustomLink } from "../Link/CustomLink";

export default function CompanyLink({ id, name }: { id: number | undefined, name?: string }) {
  const qComp = useQuery(['companyName', id], () => {
    return ntgIdApi.v0CompanyDetail(id || 0);
  }, { enabled: (!!id) && !name });

  if (!id) {
    return <></>;
  }

  if (name) {
    return <CustomLink to={`/Company/${id}`} >{name}</CustomLink>
  }

  return (<QueryWrapper QueryResult={qComp}>
    <CustomLink to={`/Company/${qComp.data?.data.id}`} >{qComp.data?.data.name}</CustomLink>
  </QueryWrapper>);
}