import { Typography } from "@mui/material";
import { useCallback, useMemo } from "react";
import { MutationFunction, useQuery } from "react-query";
import { ntgIdApi } from "../utils/ntgidapi/NtgIdApi";
import { QueryWrapper } from "../utils/QueryWrapper";
import { ConfirmDialogProps, useAxiosDescription, useConfirmDialog } from "./useConfirmDialog";

interface mutParam {
  userId: number;
  ensembleId: number;
}

export interface UseUserAddRemoveDiagParamsBase {
  ensembleType: 'Group' | 'Role';
  getEnsembleName: (id: number) => string;
}

export interface UseUserAddRemoveDiagParamsUser extends  UseUserAddRemoveDiagParamsBase {
  mode: 'user',
  ensembleId: number,
  userId?: undefined
}

export interface UseUserAddRemoveDiagParamsEnsemble extends  UseUserAddRemoveDiagParamsBase {
  mode: 'ensemble',
  userId: number,
  ensembleId?: undefined
}

export type UseUserAddRemoveDiagParams = UseUserAddRemoveDiagParamsUser |  UseUserAddRemoveDiagParamsEnsemble ;

export interface UseUserAddRemResult {
  addConfirmDiagProps: ConfirmDialogProps<unknown, unknown, number, unknown>,
  removeConfirmDiagProps: ConfirmDialogProps<unknown, unknown, number, unknown>,
  startAddDiag: (id: number) => void,
  startRemoveDiag: (id: number) => void,
}

export function useUserAddRemoveDiag({ ensembleType, getEnsembleName, mode, ensembleId, userId}: UseUserAddRemoveDiagParams):
 UseUserAddRemResult {
  const qUsers = useQuery(['v0UserList', 'all'], () => { return ntgIdApi.v0UserList(); });

  const getParam = useCallback((id: number) => {
    const uId = (mode === 'user') ? id : userId;
    const eId = (mode === 'user') ? ensembleId : id;
    return {
      roleId: eId,
      groupId: eId,
      userId: uId,
      eId
    };
  }, [mode, ensembleId, userId]);

  const userAddFn = useCallback((id: number) => {
    if (ensembleType === 'Role')
      return ntgIdApi.v0UserroleCreate(getParam(id));
    else
      return ntgIdApi.v0UsergroupCreate(getParam(id));
  }, [getParam, ensembleType]);

  const userRemoveFn = useCallback((id: number) => {
    if (ensembleType === 'Role')
      return ntgIdApi.v0UserroleDelete(getParam(id));
    else
      return ntgIdApi.v0UsergroupDelete(getParam(id));
  }, [getParam, ensembleType]);

  const addConfirmDiag = useConfirmDialog({ mutationFn: userAddFn });
  const removeConfirmDiag = useConfirmDialog({ mutationFn: userRemoveFn });
  const descCallback = useCallback((id: number) => {
    const params = getParam(id);
    return (<QueryWrapper QueryResult={qUsers}>
      <Typography variant='body1'>
        {ensembleType}: {getEnsembleName(params.eId)}
      </Typography>
      <Typography variant='body1'>
        User: {qUsers.data?.data.find(u => u.id === params.userId)?.userName}
      </Typography>
    </QueryWrapper>);
  }, [qUsers.data, ensembleType, getParam]);
  const Description = useAxiosDescription(descCallback);

  const removeTitle = useCallback(() => {
    return `Remove User from ${ensembleType}`;
  }, [ensembleType]);

  const addTitle = useCallback(() => {
    return `Add User to ${ensembleType}`;
  }, [ensembleType]);

  const startAddDiag = useCallback((id: number) => id && addConfirmDiag.startDiag(id), []);
  const startRemoveDiag = useCallback((id: number) => id && removeConfirmDiag.startDiag(id), []);

  const CloseOnSuccess = useMemo(() => true, []);
  const onSuccess = useCallback(() => {}, []);

  return {
    addConfirmDiagProps: {
      ConfirmDialogHookResult: addConfirmDiag,
      Title: addTitle,
      Description,
      CloseOnSuccess,
      onSuccess
    },
    removeConfirmDiagProps: {
      ConfirmDialogHookResult: removeConfirmDiag,
      Title: removeTitle,
      Description,
      CloseOnSuccess,
      onSuccess
    },
    startAddDiag,
    startRemoveDiag
  };
}