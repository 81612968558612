import { AxiosResponse } from 'axios';
import { useState } from 'react';
//import { BooleanLiteral } from 'typescript';
//import { threadId } from 'worker_threads';
//import Logout from '../../components/Login/Logout';
import { base64urlDecode } from '../base64urlDecode';
import { Api } from './generatedApi/Api'
import { UserRightRequestEntity } from './generatedApi/data-contracts';

export type NtgIdServerType = 'prod' | 'test' | 'local';

const serverUrls: Record<NtgIdServerType, string> = {
  prod: 'https://ntgid.netgemplatform.net',
  test: 'https://ntgidtest.netgemplatform.net',
  local: 'https://localhost:44312'
}

const serverAudience: Record<NtgIdServerType, string> = {
  prod: 'ntgid.netgemplatform.net',
  test: 'ntgidtest.netgemplatform.net',
  local: 'ntgidtest.netgemplatform.net'
}

export const googleClientId: Record<NtgIdServerType, string> = {
  prod: '143232686552-uqhhcf342s097317plvhs78alatsj5k4.apps.googleusercontent.com',
  test: '324316226925-vagodi6nu48kcr74uviaf5fn9o9g75pj.apps.googleusercontent.com',
  local: '324316226925-vagodi6nu48kcr74uviaf5fn9o9g75pj.apps.googleusercontent.com'
}

class NtgIdApi<SecurityDataType = unknown> extends Api<SecurityDataType>{
  private token: string = "";
  private username: string = "";
  public isLoggedInCallback: ((val: boolean, username: string) => void) | undefined;
  private isLoggedInCurrent: boolean;
  private serverType: NtgIdServerType = "test";
  private scopes: string[] = [];

  constructor(serverType: NtgIdServerType = "test") {
    super({ baseURL: serverUrls[serverType]});
    this.serverType = serverType;
    this.isLoggedInCurrent = false;
    if (this.isLoggedInCallback)
      this.isLoggedInCallback(this.isLoggedInCurrent, this.username);
  }

  getServerType(): NtgIdServerType {
    return this.serverType;
  }

  getUser(): string {
    return this.username;
  }

  getToken(): string {
    return this.token;
  }

  setToken(token: string): void {
    this.token = token;
    const tokenPayload = token ? this.getPayload() : {};
    const username = tokenPayload['ntgid:username'] || '';
    this.username = username;
    this.scopes = tokenPayload['scope']?.split(' ') || [];
    this.instance.defaults.headers.common.Authorization = 'Bearer ' + this.token;
    this.isLoggedIn();
  }

  getHasAdvRights() : boolean {
    return !!this.scopes.find(s => s.includes('read:') || s.includes('*:'));
  }

  getHasPassRights() : boolean {
    return !!this.scopes.find(s => s.includes('update:password') || s.includes('update:*') || s.includes('*:*') || s.includes('*:password'));
  }

  login(username: string, password: string): Promise<AxiosResponse<string, any>> {
    const userRight: UserRightRequestEntity = {
      audience: serverAudience[this.serverType], // TODO: adjust to support prod/test
      userName: username,
      password: password
    };
    return this.v0TokenCreate(userRight).then(ax => {
      this.setToken(ax.data);
      return ax;
    });
  }

  loginGoogle(googleToken: string): Promise<AxiosResponse<string, any>> {
    const userRight: UserRightRequestEntity = {
      audience: serverAudience[this.serverType], // TODO: adjust to support prod/test
    };
    return this.v0TokenGoogleCreate(userRight, { headers: { Authorization: 'Bearer ' + googleToken}}).then(ax => {
      this.setToken(ax.data);
      return ax;
    });
  }

  getPayload() {
    const encoded = this.token.split(".")[1];
    const decoded = base64urlDecode(encoded);
    return JSON.parse(decoded);
  }

  isLoggedIn(): boolean {
    let isLoggedInVal = false;
    if (this.token) {
      try {
        const decodedJwt = this.getPayload();
        const epoch = Date.now() / 1000;
        isLoggedInVal = (decodedJwt.nbf <= epoch && decodedJwt.exp >= epoch);
      } catch { }
    }
    if (isLoggedInVal !== this.isLoggedInCurrent) {
      this.isLoggedInCurrent = isLoggedInVal;
      if (this.isLoggedInCallback)
        this.isLoggedInCallback(this.isLoggedInCurrent, this.username);
    }
    return isLoggedInVal;
  }

  Logout(): void {
    this.setToken("");
  }

  SetNtgIdServer(serverType: NtgIdServerType): void {
    this.serverType = serverType;
    this.instance.defaults.baseURL = serverUrls[serverType]
  }
}

export const ntgIdApi = new NtgIdApi();