import { ReactNode, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { ntgIdApi } from "../../utils/ntgidapi/NtgIdApi";
import { createTable } from "@tanstack/react-table";
import { UserEntity } from "../../utils/ntgidapi/generatedApi/data-contracts";
import CompanyName from "../Company/CompanyName";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "../Errors/AxiosErrorHandler";
import { CustomLink } from "../Link/CustomLink";
import { MailTo } from "../Email/mailto";
import { TableUnified } from "../Table/TableUnified";
import { TextButton } from "../Buttons/TextButton";

let table = createTable().setRowType<UserEntity>();

const emptyUser: UserEntity = {
  userName: "",
  firstName: "",
  lastName: "",
  email: ""
}

export function FilteredUserList({ userFilter }: { userFilter?: ((u: UserEntity) => boolean) }) {
  const { isLoading, isError, data, error } = useQuery(['users'], () => ntgIdApi.v0UserList().then(
    response => response.data.map(user => {
      user.userName = user.userName || "";
      user.firstName = user.firstName || "";
      user.lastName = user.lastName || "";
      user.email = user.email || "";
      return user;
    })));
  const columns = useMemo(
    () =>
      ([
        table.createDataColumn('userName', {
          cell: info => {
            const userId = info.row.original?.id;
            return (<CustomLink to={"/User/" + userId}>{info.getValue()}</CustomLink>);
          },
          header: () => <span>Login</span>,
          footer: props => props.column.id,
        }),
        table.createDataColumn('firstName', {
          cell: info => info.getValue(),
          header: () => <span>First Name</span>,
          footer: props => props.column.id,
        }),
        table.createDataColumn(row => row.lastName, {
          id: 'lastName',
          cell: info => info.getValue(),
          header: () => <span>Last Name</span>,
          footer: props => props.column.id,
        }),
        table.createDataColumn('email', {
          header: () => 'Email Address',
          cell: info => <MailTo email={info.getValue()} />,
          footer: props => props.column.id,
        }),
        table.createDataColumn('companyId', {
          header: () => <span>Company</span>,
          cell: info => {
            const value = info.getValue();
            if (value && value > 0)
              return (<CompanyName id={value} />);
            else
              return (<span></span>);
          },
          footer: props => props.column.id,
        }),
      ]), []);

  const users = useMemo(() => {
    if (!data)
      return [];
    return userFilter ? data.filter(userFilter) : data;
  }, [data, userFilter]);

  if (isLoading)
    return (<div> Loading ... </div>);

  if (isError)
    return (<AxiosError error={error} text={'User list'} />);

  return (<TableUnified table={table} columns={columns} data={users} withFilters />);
}

export default function UserList() {
  const navigate = useNavigate();

  return (<>
    <TextButton onClick={() => navigate("/CreateUser")}>Create User</TextButton>
    <FilteredUserList />
  </>);
}
