import { useQuery } from "react-query";
import { ScopeEntity } from "../generatedApi/data-contracts";
import { ntgIdApi } from "../NtgIdApi";

export function useScope(scopeId: number) {
  const qScope = useQuery(['scope', scopeId], () => {
    if (scopeId <= 0) {
      return new Promise<ScopeEntity>((resolve) => {
        const emptyScope: ScopeEntity = {
          id: 0,
          name: "Scope Name",
          description: 'Scope description'
        }
        resolve(emptyScope);
      });
    }
    else {
      return ntgIdApi.v0ScopeDetail(scopeId).then(response => response.data);
    }
  });

  return qScope;
}