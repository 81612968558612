import { Typography } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { ConfirmDialog, useAxiosDescription, useConfirmDialog } from "../../hooks/useConfirmDialog";
import { ntgIdApi } from "../../utils/ntgidapi/NtgIdApi";
import { UserBase, UserEntityStrict, useStrictUser } from "./UserBase";

export function UserCreate() {
  const userCreateMut = useConfirmDialog({ mutationFn: (user: UserEntityStrict) => ntgIdApi.v0UserCreate(user) });
  const descCallback = useCallback((arg: UserEntityStrict) => (<>
    <UserBase mode='view' Entity={arg} onSubmit={() => {}} noButtons />
  </>), []);
  const description = useAxiosDescription(descCallback);

  const userBaseSubmit = useCallback((user: UserEntityStrict) => {
    userCreateMut.startDiag(user);
  }, [userCreateMut.startDiag]);

  const User = useStrictUser();

  const navigate = useNavigate();

  return (<>
    <Typography variant='h5'>Create user</Typography>
    <ConfirmDialog ConfirmDialogHookResult={userCreateMut} Description={description} Title={(arg) => `Create user ${arg.userName}`} onSuccess={(data) => navigate(`/User/${data.data.id}`)} CloseOnSuccess={true} />
    <UserBase mode='create' Entity={User} onSubmit={userBaseSubmit} />
  </>);
}