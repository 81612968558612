import { createTable } from "@tanstack/react-table";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { audienceWithScopes } from "../../utils/ntgidapi/ntgIdTypes";
import { ntgIdApi } from "../../utils/ntgidapi/NtgIdApi";
import { scopesCellFormater } from "../../utils/baseScopeUtils";
import { TableUnified } from "../Table/TableUnified";
import { CustomLink } from "../Link/CustomLink";

let table = createTable().setRowType<audienceWithScopes>();

export function UserRights({ userId }: { userId: number }) {
  const { isLoading, isError, data, error } = useQuery(['userRight'], () => ntgIdApi.v0RightList());

  const myData = useMemo(() => {
    if (!data)
      return [];
    const rights = data.data;
    const dict: { [k: number]: audienceWithScopes } = {};
    rights.forEach(right => {
      if (right.userId === userId) {
        let current = dict[right.audienceId || 0];
        if (!current) {
          current = { id: right.audienceId || 0, name: right.audienceName || "", scopes: [] };
          dict[right.audienceId || 0] = current;
        }
        current.scopes.push({ id: right.scopeId || 0, name: right.scopeName || "" });
      }
    });
    const ret: audienceWithScopes[] = [];
    for (const key in dict) {
      ret.push(dict[key]);
    }
    return ret;
  }, [data, userId]);

  const columns = useMemo(() => ([
    table.createDataColumn('name', {
      header: () => <span>Audience Name</span>,
      footer: props => props.column.id,
      cell: info => {
        const audienceId = info.row.original?.id;
        return <CustomLink to={"/Audience/" + audienceId}>{info.getValue()}</CustomLink>
      }
    }),
    table.createDataColumn('scopes', {
      header: () => <span>Scopes</span>,
      footer: props => props.column.id,
      cell: info => scopesCellFormater(info.getValue())
    }),
  ]), []);

  /*
  const [sorting, setSorting] = useState<SortingState>([]);
  const instance = useTable(table, {
    data: myData,
    columns,
    state: { sorting },
    onSortingChange: setSorting,
    sortRowsFn: sortRowsFn,
    debugTable: true
  });
  */

  if (isLoading)
    return (<div> Loading ... </div>);

  if (isError)
    return (<div> Error </div>);

  if (myData.length > 0)
    return <TableUnified table={table} columns={columns} data={myData} />

  return <div></div>
}