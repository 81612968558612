import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Fab from "@mui/material/Fab";
import Slide from "@mui/material/Slide";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Zoom from "@mui/material/Zoom";
import { ReactNode, SyntheticEvent, useContext, useEffect, useMemo, useState } from "react";
import { Link, Route, useLocation } from "react-router-dom";
import { UserContext } from "../../App";
import Home from "../Home/Home";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MailIcon from '@mui/icons-material/Mail';
import Tooltip from "@mui/material/Tooltip";
import { Logout } from "@mui/icons-material";
import { ntgIdApi, NtgIdServerType } from "../../utils/ntgidapi/NtgIdApi";
import { Grid, Stack } from "@mui/material";
import { CustomLink, ExternalLink } from "../Link/CustomLink";
import { useTheme } from '@mui/material/styles';

interface LinkTabProps {
  to: string,
  label: string
}

function LinkTab(props: LinkTabProps) {
  return (
    <Tab
      component={Link}
      {...props}
    />
  );
}

function RenderIfLogged({ children }: Children) {
  let user = useContext(UserContext);
  if (!user)
    return <></>
  else
    return <>{children}</>;
}

export interface Children {
  children: ReactNode;
}

export interface LayoutProps extends Children {
  serverType: NtgIdServerType;
}

function HideOnScroll({ children }: Children) {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      <div>
        {children}
      </div>
    </Slide>
  );
}

function ScrollTop(props: Children) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Zoom>
  );
}

function Footer() {
  const app_name = process.env.REACT_APP_NAME;
  const app_version = process.env.REACT_APP_VERSION;
  return (
    <footer>
      <Box m={5} maxWidth='xl'>
        <Stack direction="row" justifyContent="end">
          <Stack direction='column'>
            <Typography variant='body2'>Copyright © 2022 <ExternalLink href='https://www.netgem.com/'>Netgem</ExternalLink>. All rights reserved.</Typography>
            <Typography variant='body2'>{app_name}: {app_version}</Typography>
          </Stack>
        </Stack>
      </Box>
    </footer>);
}

export default function Layout({ children, serverType }: LayoutProps) {
  let user = useContext(UserContext);
  const [value, setValue] = useState(0);
  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const hasPassRigths = ntgIdApi.getHasPassRights();
  const hasAdvRights = ntgIdApi.getHasAdvRights();

  const linkTabArray = useMemo(() => {
    const ret = [];
    ret.push(
      {
        label: "Home",
        to: "",
      });
    if (hasAdvRights) {
      ret.push(
        {
          to: "Users",
          match: (path: string) => path.toLowerCase().includes('user') && !path.toLowerCase().includes('google'),
        },
        {
          to: "Companies",
          match: (path: string) => path.toLowerCase().includes('compan'),
        },
        {
          to: "Groups",
          match: (path: string) => path.toLowerCase().includes('group'),
        },
        {
          to: "Roles",
          match: (path: string) => path.toLowerCase().includes('role'),
        },
        {
          to: "Audiences",
          match: (path: string) => path.toLowerCase().includes('audience'),
        },
        {
          to: "Scopes",
          match: (path: string) => path.toLowerCase().includes('scope'),
        },
        {
          label: "Google Client Ids",
          to: "GoogleClientIds",
          match: (path: string) => path.toLowerCase().includes('google'),
        });

    }
    ret.push(
      {
        to: "Token",
        match: (path: string) => path.toLowerCase().includes('token'),
        alwaysShow: true,
      });
    if (hasPassRigths || hasAdvRights)
      ret.push(
        {
          label: "Password",
          to: !hasAdvRights ? "SelfPassword" : "Password",
          match: (path: string) => path.toLowerCase().includes('password'),
        });
    return ret;
  }, [hasPassRigths, hasAdvRights]);

  const location = useLocation();
  useEffect(() => {
    const idx = linkTabArray.findIndex(e => e.match && e.match(location.pathname));
    if (idx >= 0)
      setValue(idx);
    else if (location.pathname === '/')
      setValue(0)
  }, [location, linkTabArray]);

  const theme = useTheme();

  const colors = useMemo<Record<NtgIdServerType, string>>(() => {
    return {
      'local': '#8f2222',
      'test': theme.palette.secondary.main,
      'prod': theme.palette.primary.main,
    };
  }, []);

  const appBarStyle = useMemo(() => {
    return {
      background: colors[serverType]
    };
  }, [serverType, colors]);

  return (<>
    <Box>
      <HideOnScroll>
        <AppBar sx={appBarStyle}>
          <Toolbar>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item>
                  <Typography variant="h6" component="div">
                    {serverType}
                  </Typography>
                </Grid>
                <Grid item>
                  <RenderIfLogged>
                    <Typography variant="h6" component="div">
                      {user}
                    </Typography>
                  </RenderIfLogged>
                </Grid>
              </Grid>
            </Box>

            <RenderIfLogged>
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Logout">
                  <IconButton size="large" aria-label="logout" color="inherit" onClick={() => ntgIdApi.Logout()}>
                    <Logout />
                  </IconButton>
                </Tooltip>
              </Box>
            </RenderIfLogged>
          </Toolbar>
        </AppBar>
        <Toolbar id="back-to-top-anchor" />

        <RenderIfLogged>
          <Tabs value={value} onChange={handleChange} aria-label="nav tabs example">
            {linkTabArray.map(e => <LinkTab key={e.to} to={e.to} label={e.label ?? e.to} />)}
          </Tabs>
        </RenderIfLogged>
      </HideOnScroll>
      <Container>
        {children}
      </Container>

      <ScrollTop>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </Box>
    <Footer />
  </>);
}