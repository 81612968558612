import { Accordion, AccordionDetails, AccordionSummary, Button, Container, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getLeadingCommentRanges } from 'typescript';
import { googleClientId, ntgIdApi, NtgIdServerType } from '../../utils/ntgidapi/NtgIdApi';
import { TextButton } from '../Buttons/TextButton';
import { FormInputTextRaw } from '../Form/FormInputText';
import { FormPasswordRaw } from '../Form/FormPassword';
import { Loading } from '../Loading/Loading';
import GoogleButton from './GoogleLogin';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormInputDropdownOption, FormInputDropdownRaw } from '../Form/FormInputDropDown';

export interface LoginProps {
  advancedOptions?: boolean;
  serverType: NtgIdServerType;
  setServerType: (newValue: NtgIdServerType) => void;
}

interface ServerOption {
  label: string;
  value: NtgIdServerType;
}

const serverOptions: ServerOption[] = [
  {
    label: 'Prod',
    value: 'prod',
  },
  {
    label: 'Test',
    value: 'test',
  },
  {
    label: 'Local',
    value: 'local',
  },
];

export default function Login({ advancedOptions, serverType, setServerType }: LoginProps) {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const login = useMutation(({ u, p }: { u: string, p: string }) => {
    return ntgIdApi.login(u, p);
  }, {
    onSuccess: () => { },
    onError: () => { setPassword("") },
  },
  );

  const googleLogin = useMutation((t: string) => {
    return ntgIdApi.loginGoogle(t);
  }, {
    onSuccess: () => { },
    onError: () => { setPassword("") },
  });

  const handleSubmit = async (e: React.SyntheticEvent/* React.FormEvent<HTMLFormElement>*/) => {
    e.preventDefault();
    login.mutate({ u: username, p: password });
    //ntgIdApi.login(username, password);
  }

  if (login.isLoading) {
    return <Loading />;
  }

  return (
    <Container maxWidth='xl'>
      <Grid container direction='column' alignItems='center' spacing={3}>
        <Grid item>
          <Typography variant='h4'>Log In</Typography>
        </Grid>
        <Grid item>
          <FormInputTextRaw sx={{ minWidth: 300 }} label='UserName' value={username} onChange={(e => setUserName(e.target.value))} />
        </Grid>
        <Grid item>
          <FormPasswordRaw value={password} onChange={(e => setPassword(e.target.value))} />
        </Grid>
        <Grid item>
          <TextButton onClick={handleSubmit}>Log In</TextButton>
        </Grid>
        <Grid item>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header" >
              <Typography variant='h6'>Advanced</Typography>
            </AccordionSummary>
            <AccordionDetails>

              <FormInputDropdownRaw label='API server' options={serverOptions} value={serverType}
                onChange={e => {
                  let val: NtgIdServerType = 'test';
                  if (e.target.value === 'prod') {
                    val = 'prod';
                  } else if (e.target.value === 'local') {
                    val = 'local';
                  } else if (e.target.value !== 'test') {
                    throw new Error('Forbidden value');
                  }
                  setServerType(val);
                }} />

            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item>
          <GoogleButton onCredentialResponse={({ credential }) => googleLogin.mutate(credential)} />
        </Grid>
      </Grid>
    </Container>
  )
}