import { Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { useGoogleUser } from "../../utils/ntgidapi/hooks/useUser";
import { QueryWrapper } from "../../utils/QueryWrapper";
import { MailTo } from "../Email/mailto";
import { AxiosError } from "../Errors/AxiosErrorHandler";


export function GoogleUser({ id }: { id: number }) {
  const qGoogleUser = useGoogleUser(id);

  if (qGoogleUser.data) {
    const gUser = qGoogleUser.data;
    return (<>
      <QueryWrapper QueryResult={qGoogleUser} >
        <Typography variant='h6'>Google User Info</Typography>
        <Table size='small'>
          <TableBody>
            <TableRow>
              <TableCell variant='head' align="left">Sub</TableCell>
              <TableCell>{gUser.sub}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head' align="left">Email</TableCell>
              <TableCell><MailTo email={gUser.email} /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head' align="left">Family Name</TableCell>
              <TableCell>{gUser.familyName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head' align="left">Given Name</TableCell>
              <TableCell>{gUser.givenName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head' align="left">hd</TableCell>
              <TableCell>{gUser.hd}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head' align="left">User Name</TableCell>
              <TableCell>{gUser.userName}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </QueryWrapper>
    </>);
  } else {
    return (
      <QueryWrapper QueryResult={qGoogleUser} >
        <Typography variant='h6'>Not a google user</Typography>
      </QueryWrapper >
    );
  }
}