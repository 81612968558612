/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AudienceEntity,
  CompanyEntity,
  GoogleClientIdEditModel,
  GoogleClientIdEntity,
  GoogleUserEditModel,
  GoogleUserEntity,
  GroupEntity,
  GroupRoleEntity,
  PasswordEditModel,
  RightEntity,
  RightRequestEntity,
  RoleEntity,
  RoleRightEntity,
  ScopeEntity,
  UserEntity,
  UserGroupEntity,
  UserRightRequestEntity,
  UserRoleEntity,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Api<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description <br>Canonical Scope: `read:audience` <br>Other accepted scopes: `*:audience`, `read:*`, `*:*`
   *
   * @tags Audience
   * @name V0AudienceList
   * @request GET:/api/v0/audience
   * @secure
   */
  v0AudienceList = (params: RequestParams = {}) =>
    this.request<AudienceEntity[], any>({
      path: `/api/v0/audience`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `create:audience` <br>Other accepted scopes: `*:audience`, `create:*`, `*:*`
   *
   * @tags Audience
   * @name V0AudienceCreate
   * @request POST:/api/v0/audience
   * @secure
   */
  v0AudienceCreate = (data: AudienceEntity, params: RequestParams = {}) =>
    this.request<AudienceEntity, any>({
      path: `/api/v0/audience`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `update:audience` <br>Other accepted scopes: `*:audience`, `update:*`, `*:*`
   *
   * @tags Audience
   * @name V0AudienceUpdate
   * @request PUT:/api/v0/audience
   * @secure
   */
  v0AudienceUpdate = (data: AudienceEntity, params: RequestParams = {}) =>
    this.request<AudienceEntity, any>({
      path: `/api/v0/audience`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `read:audience` <br>Other accepted scopes: `*:audience`, `read:*`, `*:*`
   *
   * @tags Audience
   * @name V0AudienceDetail
   * @request GET:/api/v0/audience/{id}
   * @secure
   */
  v0AudienceDetail = (id: number, params: RequestParams = {}) =>
    this.request<AudienceEntity, any>({
      path: `/api/v0/audience/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `update:audience` <br>Other accepted scopes: `*:audience`, `update:*`, `*:*`
   *
   * @tags Audience
   * @name V0AudienceUpdate2
   * @request PUT:/api/v0/audience/{id}
   * @originalName v0AudienceUpdate
   * @duplicate
   * @secure
   */
  v0AudienceUpdate2 = (id: number, data: AudienceEntity, params: RequestParams = {}) =>
    this.request<AudienceEntity, any>({
      path: `/api/v0/audience/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `delete:audience` <br>Other accepted scopes: `*:audience`, `delete:*`, `*:*`
   *
   * @tags Audience
   * @name V0AudienceDelete
   * @request DELETE:/api/v0/audience/{id}
   * @secure
   */
  v0AudienceDelete = (id: number, params: RequestParams = {}) =>
    this.request<number, any>({
      path: `/api/v0/audience/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `read:company` <br>Other accepted scopes: `*:company`, `read:*`, `*:*`
   *
   * @tags Company
   * @name V0CompanyList
   * @request GET:/api/v0/company
   * @secure
   */
  v0CompanyList = (params: RequestParams = {}) =>
    this.request<CompanyEntity[], any>({
      path: `/api/v0/company`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `create:company` <br>Other accepted scopes: `*:company`, `create:*`, `*:*`
   *
   * @tags Company
   * @name V0CompanyCreate
   * @request POST:/api/v0/company
   * @secure
   */
  v0CompanyCreate = (data: CompanyEntity, params: RequestParams = {}) =>
    this.request<CompanyEntity, any>({
      path: `/api/v0/company`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `update:company` <br>Other accepted scopes: `*:company`, `update:*`, `*:*`
   *
   * @tags Company
   * @name V0CompanyUpdate
   * @request PUT:/api/v0/company
   * @secure
   */
  v0CompanyUpdate = (data: CompanyEntity, params: RequestParams = {}) =>
    this.request<CompanyEntity, any>({
      path: `/api/v0/company`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `read:company` <br>Other accepted scopes: `*:company`, `read:*`, `*:*`
   *
   * @tags Company
   * @name V0CompanyDetail
   * @request GET:/api/v0/company/{id}
   * @secure
   */
  v0CompanyDetail = (id: number, params: RequestParams = {}) =>
    this.request<CompanyEntity, any>({
      path: `/api/v0/company/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `update:company` <br>Other accepted scopes: `*:company`, `update:*`, `*:*`
   *
   * @tags Company
   * @name V0CompanyUpdate2
   * @request PUT:/api/v0/company/{id}
   * @originalName v0CompanyUpdate
   * @duplicate
   * @secure
   */
  v0CompanyUpdate2 = (id: number, data: CompanyEntity, params: RequestParams = {}) =>
    this.request<CompanyEntity, any>({
      path: `/api/v0/company/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `delete:company` <br>Other accepted scopes: `*:company`, `delete:*`, `*:*`
   *
   * @tags Company
   * @name V0CompanyDelete
   * @request DELETE:/api/v0/company/{id}
   * @secure
   */
  v0CompanyDelete = (id: number, params: RequestParams = {}) =>
    this.request<number, any>({
      path: `/api/v0/company/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `read:googleClientId` <br>Other accepted scopes: `*:googleClientId`, `read:*`, `*:*`
   *
   * @tags GoogleClientId
   * @name V0GoogleClientIdList
   * @request GET:/api/v0/googleClientId
   * @secure
   */
  v0GoogleClientIdList = (params: RequestParams = {}) =>
    this.request<GoogleClientIdEntity[], any>({
      path: `/api/v0/googleClientId`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `update:googleClientId` <br>Other accepted scopes: `*:googleClientId`, `update:*`, `*:*`
   *
   * @tags GoogleClientId
   * @name V0GoogleClientIdUpdate
   * @request PUT:/api/v0/googleClientId
   * @secure
   */
  v0GoogleClientIdUpdate = (data: GoogleClientIdEditModel, params: RequestParams = {}) =>
    this.request<GoogleClientIdEntity, any>({
      path: `/api/v0/googleClientId`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `create:googleClientId` <br>Other accepted scopes: `*:googleClientId`, `create:*`, `*:*`
   *
   * @tags GoogleClientId
   * @name V0GoogleClientIdCreate
   * @request POST:/api/v0/googleClientId
   * @secure
   */
  v0GoogleClientIdCreate = (data: GoogleClientIdEditModel, params: RequestParams = {}) =>
    this.request<GoogleClientIdEntity, any>({
      path: `/api/v0/googleClientId`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `read:googleClientId` <br>Other accepted scopes: `*:googleClientId`, `read:*`, `*:*`
   *
   * @tags GoogleClientId
   * @name V0GoogleClientIdDetail
   * @request GET:/api/v0/googleClientId/{AudienceId}/{GoogleClientId}
   * @secure
   */
  v0GoogleClientIdDetail = (audienceId: number, googleClientId: string, params: RequestParams = {}) =>
    this.request<GoogleClientIdEntity, any>({
      path: `/api/v0/googleClientId/${audienceId}/${googleClientId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `delete:googleClientId` <br>Other accepted scopes: `*:googleClientId`, `delete:*`, `*:*`
   *
   * @tags GoogleClientId
   * @name V0GoogleClientIdDelete
   * @request DELETE:/api/v0/googleClientId/{AudienceId}/{GoogleClientId}
   * @secure
   */
  v0GoogleClientIdDelete = (audienceId: number, googleClientId: string, params: RequestParams = {}) =>
    this.request<number, any>({
      path: `/api/v0/googleClientId/${audienceId}/${googleClientId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `read:googleUser` <br>Other accepted scopes: `*:googleUser`, `read:*`, `*:*`
   *
   * @tags GoogleUser
   * @name V0GoogleUserList
   * @request GET:/api/v0/googleUser
   * @secure
   */
  v0GoogleUserList = (params: RequestParams = {}) =>
    this.request<GoogleUserEntity[], any>({
      path: `/api/v0/googleUser`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `update:googleUser` <br>Other accepted scopes: `*:googleUser`, `update:*`, `*:*`
   *
   * @tags GoogleUser
   * @name V0GoogleUserUpdate
   * @request PUT:/api/v0/googleUser
   * @secure
   */
  v0GoogleUserUpdate = (data: GoogleUserEditModel, params: RequestParams = {}) =>
    this.request<GoogleUserEntity, any>({
      path: `/api/v0/googleUser`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `read:googleUser` <br>Other accepted scopes: `*:googleUser`, `read:*`, `*:*`
   *
   * @tags GoogleUser
   * @name V0GoogleUserDetail
   * @request GET:/api/v0/googleUser/{sub}
   * @secure
   */
  v0GoogleUserDetail = (sub: string, params: RequestParams = {}) =>
    this.request<GoogleUserEntity, any>({
      path: `/api/v0/googleUser/${sub}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `delete:googleUser` <br>Other accepted scopes: `*:googleUser`, `delete:*`, `*:*`
   *
   * @tags GoogleUser
   * @name V0GoogleUserDelete
   * @request DELETE:/api/v0/googleUser/{sub}
   * @secure
   */
  v0GoogleUserDelete = (sub: string, params: RequestParams = {}) =>
    this.request<number, any>({
      path: `/api/v0/googleUser/${sub}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `read:group` <br>Other accepted scopes: `*:group`, `read:*`, `*:*`
   *
   * @tags Group
   * @name V0GroupList
   * @request GET:/api/v0/group
   * @secure
   */
  v0GroupList = (params: RequestParams = {}) =>
    this.request<GroupEntity[], any>({
      path: `/api/v0/group`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `create:group` <br>Other accepted scopes: `*:group`, `create:*`, `*:*`
   *
   * @tags Group
   * @name V0GroupCreate
   * @request POST:/api/v0/group
   * @secure
   */
  v0GroupCreate = (data: GroupEntity, params: RequestParams = {}) =>
    this.request<GroupEntity, any>({
      path: `/api/v0/group`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `update:group` <br>Other accepted scopes: `*:group`, `update:*`, `*:*`
   *
   * @tags Group
   * @name V0GroupUpdate
   * @request PUT:/api/v0/group
   * @secure
   */
  v0GroupUpdate = (data: GroupEntity, params: RequestParams = {}) =>
    this.request<GroupEntity, any>({
      path: `/api/v0/group`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `read:group` <br>Other accepted scopes: `*:group`, `read:*`, `*:*`
   *
   * @tags Group
   * @name V0GroupDetail
   * @request GET:/api/v0/group/{id}
   * @secure
   */
  v0GroupDetail = (id: number, params: RequestParams = {}) =>
    this.request<GroupEntity, any>({
      path: `/api/v0/group/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `update:group` <br>Other accepted scopes: `*:group`, `update:*`, `*:*`
   *
   * @tags Group
   * @name V0GroupUpdate2
   * @request PUT:/api/v0/group/{id}
   * @originalName v0GroupUpdate
   * @duplicate
   * @secure
   */
  v0GroupUpdate2 = (id: number, data: GroupEntity, params: RequestParams = {}) =>
    this.request<GroupEntity, any>({
      path: `/api/v0/group/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `delete:group` <br>Other accepted scopes: `*:group`, `delete:*`, `*:*`
   *
   * @tags Group
   * @name V0GroupDelete
   * @request DELETE:/api/v0/group/{id}
   * @secure
   */
  v0GroupDelete = (id: number, params: RequestParams = {}) =>
    this.request<number, any>({
      path: `/api/v0/group/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `read:role` <br>Other accepted scopes: `*:role`, `read:*`, `*:*`
   *
   * @tags GroupRole
   * @name V0GrouproleList
   * @request GET:/api/v0/grouprole
   * @secure
   */
  v0GrouproleList = (params: RequestParams = {}) =>
    this.request<GroupRoleEntity[], any>({
      path: `/api/v0/grouprole`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `create:role` <br>Other accepted scopes: `*:role`, `create:*`, `*:*`
   *
   * @tags GroupRole
   * @name V0GrouproleCreate
   * @request POST:/api/v0/grouprole
   * @secure
   */
  v0GrouproleCreate = (data: GroupRoleEntity, params: RequestParams = {}) =>
    this.request<GroupRoleEntity, any>({
      path: `/api/v0/grouprole`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `delete:role` <br>Other accepted scopes: `*:role`, `delete:*`, `*:*`
   *
   * @tags GroupRole
   * @name V0GrouproleDelete
   * @request DELETE:/api/v0/grouprole
   * @secure
   */
  v0GrouproleDelete = (query?: { groupId?: number; roleId?: number }, params: RequestParams = {}) =>
    this.request<number, any>({
      path: `/api/v0/grouprole`,
      method: "DELETE",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `update:password` <br>Other accepted scopes: `*:password`, `update:*`, `*:*`
   *
   * @tags Password
   * @name V0PasswordUpdate
   * @request PUT:/api/v0/password
   * @secure
   */
  v0PasswordUpdate = (data: PasswordEditModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v0/password`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `read:rights` <br>Other accepted scopes: `*:rights`, `read:*`, `*:*`
   *
   * @tags Right
   * @name V0RightList
   * @request GET:/api/v0/right
   * @secure
   */
  v0RightList = (params: RequestParams = {}) =>
    this.request<RightEntity[], any>({
      path: `/api/v0/right`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `create:rights` <br>Other accepted scopes: `*:rights`, `create:*`, `*:*`
   *
   * @tags Right
   * @name V0RightCreate
   * @request POST:/api/v0/right
   * @secure
   */
  v0RightCreate = (data: RightEntity, params: RequestParams = {}) =>
    this.request<RightEntity, any>({
      path: `/api/v0/right`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `delete:rights` <br>Other accepted scopes: `*:rights`, `delete:*`, `*:*`
   *
   * @tags Right
   * @name V0RightDelete
   * @request DELETE:/api/v0/right
   * @secure
   */
  v0RightDelete = (query?: { userId?: number; audienceId?: number; scopeId?: number }, params: RequestParams = {}) =>
    this.request<number, any>({
      path: `/api/v0/right`,
      method: "DELETE",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `read:role` <br>Other accepted scopes: `*:role`, `read:*`, `*:*`
   *
   * @tags Role
   * @name V0RoleList
   * @request GET:/api/v0/role
   * @secure
   */
  v0RoleList = (params: RequestParams = {}) =>
    this.request<RoleEntity[], any>({
      path: `/api/v0/role`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `create:role` <br>Other accepted scopes: `*:role`, `create:*`, `*:*`
   *
   * @tags Role
   * @name V0RoleCreate
   * @request POST:/api/v0/role
   * @secure
   */
  v0RoleCreate = (data: RoleEntity, params: RequestParams = {}) =>
    this.request<RoleEntity, any>({
      path: `/api/v0/role`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `update:role` <br>Other accepted scopes: `*:role`, `update:*`, `*:*`
   *
   * @tags Role
   * @name V0RoleUpdate
   * @request PUT:/api/v0/role
   * @secure
   */
  v0RoleUpdate = (data: RoleEntity, params: RequestParams = {}) =>
    this.request<RoleEntity, any>({
      path: `/api/v0/role`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `read:role` <br>Other accepted scopes: `*:role`, `read:*`, `*:*`
   *
   * @tags Role
   * @name V0RoleDetail
   * @request GET:/api/v0/role/{id}
   * @secure
   */
  v0RoleDetail = (id: number, params: RequestParams = {}) =>
    this.request<RoleEntity, any>({
      path: `/api/v0/role/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `update:role` <br>Other accepted scopes: `*:role`, `update:*`, `*:*`
   *
   * @tags Role
   * @name V0RoleUpdate2
   * @request PUT:/api/v0/role/{id}
   * @originalName v0RoleUpdate
   * @duplicate
   * @secure
   */
  v0RoleUpdate2 = (id: number, data: RoleEntity, params: RequestParams = {}) =>
    this.request<RoleEntity, any>({
      path: `/api/v0/role/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `delete:role` <br>Other accepted scopes: `*:role`, `delete:*`, `*:*`
   *
   * @tags Role
   * @name V0RoleDelete
   * @request DELETE:/api/v0/role/{id}
   * @secure
   */
  v0RoleDelete = (id: number, params: RequestParams = {}) =>
    this.request<number, any>({
      path: `/api/v0/role/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `read:rights` <br>Other accepted scopes: `*:rights`, `read:*`, `*:*`
   *
   * @tags RoleRight
   * @name V0RolerightList
   * @request GET:/api/v0/roleright
   * @secure
   */
  v0RolerightList = (params: RequestParams = {}) =>
    this.request<RoleRightEntity[], any>({
      path: `/api/v0/roleright`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `create:rights` <br>Other accepted scopes: `*:rights`, `create:*`, `*:*`
   *
   * @tags RoleRight
   * @name V0RolerightCreate
   * @request POST:/api/v0/roleright
   * @secure
   */
  v0RolerightCreate = (data: RoleRightEntity, params: RequestParams = {}) =>
    this.request<RoleRightEntity, any>({
      path: `/api/v0/roleright`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `delete:rights` <br>Other accepted scopes: `*:rights`, `delete:*`, `*:*`
   *
   * @tags RoleRight
   * @name V0RolerightDelete
   * @request DELETE:/api/v0/roleright
   * @secure
   */
  v0RolerightDelete = (query?: { roleId?: number; scopeId?: number }, params: RequestParams = {}) =>
    this.request<number, any>({
      path: `/api/v0/roleright`,
      method: "DELETE",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `read:scope` <br>Other accepted scopes: `*:scope`, `read:*`, `*:*`
   *
   * @tags Scope
   * @name V0ScopeList
   * @request GET:/api/v0/scope
   * @secure
   */
  v0ScopeList = (params: RequestParams = {}) =>
    this.request<ScopeEntity[], any>({
      path: `/api/v0/scope`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `create:scope` <br>Other accepted scopes: `*:scope`, `create:*`, `*:*`
   *
   * @tags Scope
   * @name V0ScopeCreate
   * @request POST:/api/v0/scope
   * @secure
   */
  v0ScopeCreate = (data: ScopeEntity, params: RequestParams = {}) =>
    this.request<ScopeEntity, any>({
      path: `/api/v0/scope`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `update:scope` <br>Other accepted scopes: `*:scope`, `update:*`, `*:*`
   *
   * @tags Scope
   * @name V0ScopeUpdate
   * @request PUT:/api/v0/scope
   * @secure
   */
  v0ScopeUpdate = (data: ScopeEntity, params: RequestParams = {}) =>
    this.request<ScopeEntity, any>({
      path: `/api/v0/scope`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `read:scope` <br>Other accepted scopes: `*:scope`, `read:*`, `*:*`
   *
   * @tags Scope
   * @name V0ScopeDetail
   * @request GET:/api/v0/scope/{id}
   * @secure
   */
  v0ScopeDetail = (id: number, params: RequestParams = {}) =>
    this.request<ScopeEntity, any>({
      path: `/api/v0/scope/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `update:scope` <br>Other accepted scopes: `*:scope`, `update:*`, `*:*`
   *
   * @tags Scope
   * @name V0ScopeUpdate2
   * @request PUT:/api/v0/scope/{id}
   * @originalName v0ScopeUpdate
   * @duplicate
   * @secure
   */
  v0ScopeUpdate2 = (id: number, data: ScopeEntity, params: RequestParams = {}) =>
    this.request<ScopeEntity, any>({
      path: `/api/v0/scope/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `delete:scope` <br>Other accepted scopes: `*:scope`, `delete:*`, `*:*`
   *
   * @tags Scope
   * @name V0ScopeDelete
   * @request DELETE:/api/v0/scope/{id}
   * @secure
   */
  v0ScopeDelete = (id: number, params: RequestParams = {}) =>
    this.request<number, any>({
      path: `/api/v0/scope/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TokenApi
   * @name TokenCreate
   * @request POST:/api/Token
   * @secure
   */
  tokenCreate = (data: UserRightRequestEntity, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/Token`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description <ntgScope />
   *
   * @tags TokenApi
   * @name V0TokenCreate
   * @request POST:/api/v0/Token
   * @secure
   */
  v0TokenCreate = (data: UserRightRequestEntity, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v0/Token`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TokenApi
   * @name V0TokenGoogleCreate
   * @request POST:/api/v0/Token/Google
   * @secure
   */
  v0TokenGoogleCreate = (data: RightRequestEntity, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v0/Token/Google`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `read:user` <br>Other accepted scopes: `*:user`, `read:*`, `*:*`
   *
   * @tags User
   * @name V0UserList
   * @request GET:/api/v0/user
   * @secure
   */
  v0UserList = (params: RequestParams = {}) =>
    this.request<UserEntity[], any>({
      path: `/api/v0/user`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `create:user` <br>Other accepted scopes: `*:user`, `create:*`, `*:*`
   *
   * @tags User
   * @name V0UserCreate
   * @request POST:/api/v0/user
   * @secure
   */
  v0UserCreate = (data: UserEntity, params: RequestParams = {}) =>
    this.request<UserEntity, any>({
      path: `/api/v0/user`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `update:user` <br>Other accepted scopes: `*:user`, `update:*`, `*:*`
   *
   * @tags User
   * @name V0UserUpdate
   * @request PUT:/api/v0/user
   * @secure
   */
  v0UserUpdate = (data: UserEntity, params: RequestParams = {}) =>
    this.request<UserEntity, any>({
      path: `/api/v0/user`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `read:user` <br>Other accepted scopes: `*:user`, `read:*`, `*:*`
   *
   * @tags User
   * @name V0UserDetail
   * @request GET:/api/v0/user/{id}
   * @secure
   */
  v0UserDetail = (id: number, params: RequestParams = {}) =>
    this.request<UserEntity, any>({
      path: `/api/v0/user/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `update:user` <br>Other accepted scopes: `*:user`, `update:*`, `*:*`
   *
   * @tags User
   * @name V0UserUpdate2
   * @request PUT:/api/v0/user/{id}
   * @originalName v0UserUpdate
   * @duplicate
   * @secure
   */
  v0UserUpdate2 = (id: number, data: UserEntity, params: RequestParams = {}) =>
    this.request<UserEntity, any>({
      path: `/api/v0/user/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `delete:user` <br>Other accepted scopes: `*:user`, `delete:*`, `*:*`
   *
   * @tags User
   * @name V0UserDelete
   * @request DELETE:/api/v0/user/{id}
   * @secure
   */
  v0UserDelete = (id: number, params: RequestParams = {}) =>
    this.request<number, any>({
      path: `/api/v0/user/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `read:group` <br>Other accepted scopes: `*:group`, `read:*`, `*:*`
   *
   * @tags UserGroup
   * @name V0UsergroupList
   * @request GET:/api/v0/usergroup
   * @secure
   */
  v0UsergroupList = (params: RequestParams = {}) =>
    this.request<UserGroupEntity[], any>({
      path: `/api/v0/usergroup`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `create:group` <br>Other accepted scopes: `*:group`, `create:*`, `*:*`
   *
   * @tags UserGroup
   * @name V0UsergroupCreate
   * @request POST:/api/v0/usergroup
   * @secure
   */
  v0UsergroupCreate = (data: UserGroupEntity, params: RequestParams = {}) =>
    this.request<UserGroupEntity, any>({
      path: `/api/v0/usergroup`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `delete:group` <br>Other accepted scopes: `*:group`, `delete:*`, `*:*`
   *
   * @tags UserGroup
   * @name V0UsergroupDelete
   * @request DELETE:/api/v0/usergroup
   * @secure
   */
  v0UsergroupDelete = (query?: { userId?: number; groupId?: number }, params: RequestParams = {}) =>
    this.request<number, any>({
      path: `/api/v0/usergroup`,
      method: "DELETE",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `read:role` <br>Other accepted scopes: `*:role`, `read:*`, `*:*`
   *
   * @tags UserRole
   * @name V0UserroleList
   * @request GET:/api/v0/userrole
   * @secure
   */
  v0UserroleList = (params: RequestParams = {}) =>
    this.request<UserRoleEntity[], any>({
      path: `/api/v0/userrole`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `create:role` <br>Other accepted scopes: `*:role`, `create:*`, `*:*`
   *
   * @tags UserRole
   * @name V0UserroleCreate
   * @request POST:/api/v0/userrole
   * @secure
   */
  v0UserroleCreate = (data: UserRoleEntity, params: RequestParams = {}) =>
    this.request<UserRoleEntity, any>({
      path: `/api/v0/userrole`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description <br>Canonical Scope: `delete:role` <br>Other accepted scopes: `*:role`, `delete:*`, `*:*`
   *
   * @tags UserRole
   * @name V0UserroleDelete
   * @request DELETE:/api/v0/userrole
   * @secure
   */
  v0UserroleDelete = (query?: { userId?: number; roleId?: number }, params: RequestParams = {}) =>
    this.request<number, any>({
      path: `/api/v0/userrole`,
      method: "DELETE",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
}
