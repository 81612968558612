import { Link as MuiLink, LinkProps as MuiLinkProps } from "@mui/material";
import { Link as RouterLink, LinkProps } from "react-router-dom";

export interface CustomLinkProps extends LinkProps {
}

export function CustomLink({ ...other }: CustomLinkProps) {
  return <MuiLink component={RouterLink}  {...other} />;
}

export function ExternalLink({ ...other }: MuiLinkProps) {
  return <MuiLink {...other} />;
}