import { Grid } from "@mui/material";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { ConfirmDialog } from "../../hooks/useConfirmDialog";
import { TextButton } from "../Buttons/TextButton";
import { FormPasswordRaw } from "../Form/FormPassword";
import { Visible } from "../Utils/visible";

export interface BasePasswordProps {
  passwordAction: (password: string, resetPassword: () => void) => ReactNode;
}

export function BasePassword({ passwordAction }: BasePasswordProps) {
  const [passDontMatch, setPassDontMatch] = useState(false);
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');

  //{ password: password1}
  useEffect(() => {
    setPassDontMatch(password1 !== password2 && !!(password1.length || password2.length));
  }, [password1, password2]);

  const resetPassword = useCallback(() => {
    setPassword1('');
    setPassword2('');
  }, []);

  return (<>
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <FormPasswordRaw
          value={password1}
          onChange={(e => setPassword1(e.target.value))}
          reset={() => setPassword1('')}
        />
      </Grid>
      <Grid item>
        <FormPasswordRaw
          value={password2}
          onChange={(e => setPassword2(e.target.value))}
          error={passDontMatch}
          helperText={passDontMatch ? "Passwords don't match" : undefined}
          inputLabel="Repeat password"
          reset={() => setPassword2('')}
        />
      </Grid>
      <Grid item>
        <Visible visible={!passDontMatch && password1.length > 0}>
          {passwordAction(password1, resetPassword)}
        </Visible>
      </Grid>
    </Grid>
  </>);
}