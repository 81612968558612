import { useCallback, useMemo } from "react";
import { flatCompany, flatGroup, flatNoGroup } from "../../utils/ntgidapi/ntgIdTypes";
import { ntgIdAllDataExtended, useNtgIdGetAllExtended } from "../../utils/ntgidapi/useNtgIdGetAll";
import { createTable } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { scopesCellFormater } from "../../utils/baseScopeUtils";
import { TableUnified, TableUnifiedRootProps } from "../Table/TableUnified";
import { QueryWrapper } from "../../utils/QueryWrapper";
import { CustomLink } from "../Link/CustomLink";
import { TextButton } from "../Buttons/TextButton";
import CompanyLink from "../Company/CompanyLink";

let table = createTable().setRowType<flatGroup | flatNoGroup | flatCompany>();

export interface FilteredGroupsProps extends TableUnifiedRootProps {
  groupFilter: (data: ntgIdAllDataExtended) => Array<flatGroup | flatNoGroup | flatCompany>;
  withCompanies?: boolean;
}

export function FilteredGroups({ groupFilter, withCompanies = false, ...extra }: FilteredGroupsProps) {
  const qNtgIdAll = useNtgIdGetAllExtended();

  const myData = useMemo(() => {
    if (!qNtgIdAll.data)
      return [];

    return groupFilter(qNtgIdAll.data);

  }, [qNtgIdAll.data, groupFilter]);

  const columns = useMemo(() => {
    const dataColumns = [
      table.createDataColumn('groupName', {
        header: () => <span>Group Name</span>,
        footer: props => props.column.id,
        cell: info => {
          const groupId = info.row.original?.groupId || 0;
          if (groupId)
            return <CustomLink to={"/Group/" + groupId}>{info.getValue() || ""}</CustomLink>
        }
      }),
      table.createDataColumn('roleName', {
        header: () => <span>Role Name</span>,
        footer: props => props.column.id,
        cell: info => {
          const roleId = info.row.original?.roleId || 0;
          if (+roleId > 0)
            return <CustomLink to={"/Role/" + roleId}>{info.getValue()}</CustomLink>;
        }
      }),
      table.createDataColumn('audienceName', {
        header: () => <span>Audience Name</span>,
        footer: props => props.column.id,
        cell: info => {
          const audienceId = info.row.original?.audienceId || 0;
          if (+audienceId)
            return <CustomLink to={"/Audience/" + audienceId}>{info.getValue()}</CustomLink>
        }
      }),
      table.createDataColumn('scopes', {
        header: () => <span>Scopes</span>,
        footer: props => props.column.id,
        cell: info => scopesCellFormater(Array.from(info.getValue()))
      }),
    ];
    if (withCompanies) {
      dataColumns.unshift(
        table.createDataColumn('companyName', {
          header: () => <span>Company Name</span>,
          footer: props => props.column.id,
          cell: info => {
            const companyId = info.row.original?.companyId || 0;
            if (companyId)
              return <CompanyLink id={+companyId} name={info.getValue()} />;
          }
        }));
    }
    return (dataColumns);
  }, [withCompanies]);

  const columnIds = useMemo(() => {
    const ret = ['groupName', 'audienceName'];
    if (withCompanies)
      ret.unshift('companyName');
    return ret
  }, [withCompanies]);

  /*
  const [sorting, setSorting] = useState<SortingState>([]);
  const instance = useTable(table, {
    data: myData,
    columns,
    state: { sorting },
    onSortingChange: setSorting,
    sortRowsFn: sortRowsFn,
    debugTable: true
  });
  */

  return (<QueryWrapper QueryResult={qNtgIdAll}>
    <TableUnified table={table} columns={columns} data={myData} columnIds={columnIds} {...extra} />
  </QueryWrapper>
  );
}

export function Groups() {
  const groupFilter = useCallback(function (data: ntgIdAllDataExtended) {
    const ret: Array<flatGroup> = [];
    data.flatGroups.forEach((value) => {
      if (value.length > 0)
        ret.push(...value);
    });
    return ret;
  }, []);
  const navigate = useNavigate();

  return (<>
    <TextButton onClick={() => navigate('/CreateGroup')}>Create Group</TextButton>
    <FilteredGroups groupFilter={groupFilter} withFilters />
  </>);
}