import { ReactNode, useMemo } from "react";
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectProps } from "@mui/material";
import { Controller, FieldValues } from "react-hook-form";
import { FormInputProp } from "./FormInputPropType";

export interface FormInputDropdownOption {
  label: ReactNode;
  value: string | number;
}

export interface FormInputDropdownProp<TfieldValues extends FieldValues> extends FormInputProp<TfieldValues> {
  options: FormInputDropdownOption[];
}

function useMenuItems(options: FormInputDropdownOption[]) {
  const selectOptions = useMemo(() => {
    return options.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  }, [options]);

  return selectOptions;
}

export function FormInputDropdown<TfieldValues extends FieldValues>({ name, control, options, label }: FormInputDropdownProp<TfieldValues>) {
  const selectOptions = useMenuItems(options);

  return <Controller
    control={control}
    name={name}
    render={({ field: { onChange, value } }) => (
      <FormControl sx={{ minWidth: 230 }} size='small'>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select onChange={onChange} value={value || ''} label={label} autoWidth={true} labelId="demo-simple-select-label" fullWidth >
          {selectOptions}
        </Select>
      </FormControl>
    )}
  />
};


export interface FormInputDropdownRawProp<T> extends SelectProps<T> {
  label: string;
  options: FormInputDropdownOption[];
}

export function FormInputDropdownRaw<T>({ options, label, ...other }: FormInputDropdownRawProp<T>) {
  const selectOptions = useMenuItems(options);

  return (<FormControl sx={{ minWidth: 230 }} size='small'>
    <InputLabel id="demo-simple-select-label">{label}</InputLabel>
    <Select label={label} autoWidth={true} labelId="demo-simple-select-label" fullWidth  {...other}>
      {selectOptions}
    </Select>
  </FormControl>);
}