import { Box, Container, Grid } from "@mui/material";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { TextButton } from "../Buttons/TextButton";
import { FormInputText } from "../Form/FormInputText";
import { FormTextArea } from "../Form/FormTextArea";

export type BaseEntityStrict = {
  id: number;
  name: string;
  description: string;
}

export type BaseMode = 'edit' | 'view' | 'create';

export interface BaseProps {
  Entity: BaseEntityStrict;
  mode: BaseMode;
  onSubmit: (ent: BaseEntityStrict) => void;
  onCancel?: () => void;
  ButtonLabel?: (mode: BaseMode) => (string | undefined);
  noDisabled?: boolean;
}

export function BaseScopeAudience({ Entity, mode, ButtonLabel, onSubmit, onCancel, noDisabled }: BaseProps) {
  const { register, handleSubmit, control, formState: { errors } } = useForm<BaseEntityStrict>({
    defaultValues: Entity
  });

  const cancelButton = useMemo(() => {
    if (onCancel)
      return <TextButton onClick={onCancel}>Cancel</TextButton>;
    return undefined;
  }, [onCancel]);

  const formButtons = useMemo(() => {
    let buttonLabel: string | undefined = ButtonLabel && ButtonLabel(mode);
    if (!buttonLabel) {
      if (mode === 'view') {
        buttonLabel = 'Edit Description';
      } else if (mode === 'edit') {
        buttonLabel = 'Save';
      } else {
        buttonLabel = 'Create';
      }
    }
    if (cancelButton) {
      return (<>
        {cancelButton}
        <TextButton onClick={handleSubmit(onSubmit)}>{buttonLabel}</TextButton>
      </>);
    }
    else return <TextButton onClick={handleSubmit(onSubmit)}>{buttonLabel}</TextButton>;
  }, [mode, ButtonLabel, cancelButton]);

  const inputPropsDescription = {
    readOnly: (mode === 'view')
  };

  const inputPropsName = {
    ...inputPropsDescription,
    disabled: !noDisabled && (mode === 'edit'),
  };

  return (
    <Container maxWidth="xl">
      <Box component="form" >
        <input hidden defaultValue={Entity.id} {...register('id')} />
        <Grid container alignItems="left" direction="column" spacing={1}>
          <Grid item>
            <FormInputText label="Name" control={control} name="name" InputProps={inputPropsName} />
          </Grid>
          <Grid item alignItems="center">
            <FormTextArea label="Description" control={control} name="description" minRows={2} fullWidth={true} InputProps={inputPropsDescription} />
          </Grid>
          <Grid item >
            {formButtons}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}