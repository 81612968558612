import { SelectChangeEvent, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { ConfirmDialog } from "../../hooks/useConfirmDialog";
import { useUserAddRemoveDiag } from "../../hooks/useUserAddRemoveDiag";
import { flatCompany, flatGroup, flatNoGroup } from "../../utils/ntgidapi/ntgIdTypes";
import { ntgIdAllDataExtended, useNtgIdGetAllExtended } from "../../utils/ntgidapi/useNtgIdGetAll";
import { QueryWrapper } from "../../utils/QueryWrapper";
import { AddButtonIcon } from "../Buttons/AddButtonIcon";
import { RemoveButtonIcon } from "../Buttons/RemoveButtonIcon";
import { TextButton } from "../Buttons/TextButton";
import { FormAutoCompleteComboRaw, FormAutocompleteRawOption } from "../Form/FormAutocompleteCombo";
import { FormInputDropdownOption, FormInputDropdownRaw } from "../Form/FormInputDropDown";
import { FilteredGroups } from "../Groups/Groups";

const noneSelect = { value: 0, label: '' };

export function UserGroupRoleRights({ userId }: { userId: number }) {
  const qAll = useNtgIdGetAllExtended();

  const [removeFromGroupId, setRemoveFromGroupId] = useState(0);
  const [removeFromRoleId, setRemoveFromRoleId] = useState(0);
  const [addToGroupId, setAddToGroupId] = useState(0);
  const [addToRoleId, setAddToRoleId] = useState(0);

  const data = qAll.data;

  const groupNames = useCallback((id: number) =>
    (data?.groups.find(e => e.id === id)?.name || 'Error'), [data]);

  const roleNames = useCallback((id: number) =>
    (data?.roles.find(e => e.id === id)?.name || 'Error'), [data]);

  const userAddRemoveRole = useUserAddRemoveDiag({
    ensembleType: 'Role',
    mode: 'ensemble',
    userId,
    getEnsembleName: roleNames
  });

  const userAddRemoveGroup = useUserAddRemoveDiag({
    ensembleType: 'Group',
    mode: 'ensemble',
    userId,
    getEnsembleName: groupNames
  });

  const removableGroups = useMemo(() => {
    if (!data) return [];
    const ret: FormAutocompleteRawOption[] = data.userGroups
      .filter(ug => ug.userId === userId)
      .map(ug => ({ value: ug.groupId || 0, label: ug.groupName || "" }));
    ret.unshift(noneSelect);
    return ret;
  }, [data, userId]);

  const addableGroups = useMemo(() => {
    if (!data) return [];
    const ret: FormAutocompleteRawOption[] = data.groups
      .filter(g => !data.userGroups.find(ug => ug.userId === userId && ug.groupId === g.id))
      .map(g => ({ value: g.id || 0, label: g.name || "" }));
    ret.unshift(noneSelect);
    return ret;
  }, [data, userId]);

  const removableRoles = useMemo(() => {
    if (!data) return [];
    const ret: FormAutocompleteRawOption[] = data.userRoles
      .filter(ur => ur.userId === userId)
      .map(ur => ({ value: ur.roleId || 0, label: ur.roleName || "" }));
    ret.unshift(noneSelect);
    return ret;
  }, [data, userId]);

  const addableRoles = useMemo(() => {
    if (!data) return [];
    const ret: FormAutocompleteRawOption[] = data.roles
      .filter(r => !data.userRoles.find(ur => ur.userId === userId && ur.roleId === r.id))
      .map(r => ({ value: r.id || 0, label: r.name || "" }));
    ret.unshift(noneSelect);
    return ret;
  }, [data, userId]);

  const resetValue = useCallback(
    (selectableValues: FormAutocompleteRawOption[], value: number, numberSet: (arg: number) => void) => {
      if (!selectableValues.find(e => (e.value || 0) === value)) {
        numberSet(0);
      }
    }, []);

  useMemo(() => resetValue(removableGroups, removeFromGroupId, setRemoveFromGroupId),
    [removableGroups, removeFromGroupId, setRemoveFromGroupId])
  useMemo(() => resetValue(addableGroups, addToGroupId, setAddToGroupId),
    [addableGroups, addToGroupId, setAddToGroupId])
  useMemo(() => resetValue(removableRoles, removeFromRoleId, setRemoveFromRoleId),
    [removableRoles, removeFromRoleId, setRemoveFromRoleId])
  useMemo(() => resetValue(addableRoles, addToRoleId, setAddToRoleId),
    [addableRoles, addToRoleId, setAddToRoleId])

  const withCompanies = useMemo(() => {
    return (data) ? !!(data.users.find(u => u.id === userId)?.companyId) : false;
  }, [data, userId]);

  const groupFilter = useCallback(function (data: ntgIdAllDataExtended) {
    if (!data)
      return [];
    const ret: Array<flatGroup | flatNoGroup | flatCompany> = [];

    const groups = data.userGroups.filter(ug => (ug.userId === userId && ug.groupId !== removeFromGroupId));
    groups.forEach(ug => {
      const flattenGroups = data.flatGroups.get(ug.groupId || 0);
      if (flattenGroups) {
        ret.push(...flattenGroups);
      }
    });

    const roles = data.userRoles.filter(ur => (ur.userId === userId && ur.roleId !== removeFromRoleId));
    roles.forEach(r => {
      const role = data.flatRoles.get(r.roleId || 0);
      if (role) {
        ret.push(role);
      }
    });

    const user = data.users.find(u => u.id === userId);
    if (user && user.companyId) {
      const companies = data.flatCompanies.get(user.companyId);
      companies?.forEach(c => {
        ret.push(c);
      }
      );
    }
    ret.sort((a, b) => ((a.companyId || 0) - (b.companyId || 0)) || ((a.groupId || 0) - (b.groupId || 0)));

    const addedRole = data.flatRoles.get(addToRoleId);
    if (addedRole) {
      ret.push(addedRole);
    }
    const addedGroup = data.flatGroups.get(addToGroupId);
    if (addedGroup) {
      ret.push(...addedGroup);
    }

    return ret;
  }, [userId, removeFromGroupId, removeFromRoleId, addToGroupId, addToRoleId]);

  return (<QueryWrapper QueryResult={qAll}>
    <ConfirmDialog {...userAddRemoveGroup.addConfirmDiagProps} />
    <ConfirmDialog {...userAddRemoveGroup.removeConfirmDiagProps} />
    <ConfirmDialog {...userAddRemoveRole.addConfirmDiagProps} />
    <ConfirmDialog {...userAddRemoveRole.removeConfirmDiagProps} />
    <FilteredGroups groupFilter={groupFilter} withCompanies={withCompanies} />
    <Table size='small'>
      <TableBody>
        <TableRow>
          <TableCell>
            <FormAutoCompleteComboRaw
              label='Remove from Group'
              value={removeFromGroupId}
              onValueChange={setRemoveFromGroupId}
              options={removableGroups} />
            <RemoveButtonIcon onClick={() => userAddRemoveGroup.startRemoveDiag(removeFromGroupId)} />
          </TableCell>

          <TableCell>
            <FormAutoCompleteComboRaw
              label='Remove from Role'
              value={removeFromRoleId}
              onValueChange={(setRemoveFromRoleId)}
              options={removableRoles} />
            <RemoveButtonIcon onClick={() => userAddRemoveRole.startRemoveDiag(removeFromRoleId)} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <FormAutoCompleteComboRaw
              label='Add to Group'
              value={addToGroupId}
              onValueChange={(setAddToGroupId)}
              options={addableGroups} />
            <AddButtonIcon onClick={() => userAddRemoveGroup.startAddDiag(addToGroupId)} />
          </TableCell>

          <TableCell>
            <FormAutoCompleteComboRaw
              label='Add to Role'
              value={addToRoleId}
              onValueChange={(setAddToRoleId)}
              options={addableRoles} />
            <AddButtonIcon onClick={() => userAddRemoveRole.startAddDiag(addToRoleId)} />

          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="center" colSpan={3}>
            <TextButton onClick={() => {
              setAddToGroupId(0);
              setAddToRoleId(0);
              setRemoveFromGroupId(0);
              setRemoveFromRoleId(0);
            }} fullWidth>Clear</TextButton>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </QueryWrapper>);
}