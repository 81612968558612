import { Box, BoxProps, OutlinedTextFieldProps, TextField } from "@mui/material";
import React, { ReactNode } from "react";

export interface OutlinedBoxProps extends Omit<OutlinedTextFieldProps, 'variant'> {
  children: ReactNode,
  label?: string,
}

interface boxPropEmpty {
};

const InputHL = React.forwardRef<unknown, boxPropEmpty>((props, ref) => <Box {...props} />);

export function OutlinedBox({ children, label, ...other }: OutlinedBoxProps) {
  return (
    <TextField
      variant="outlined"
      label={label}
      multiline
      InputLabelProps={{ shrink: true }}
      InputProps={{
        inputComponent: InputHL
      }}
      inputProps={{ children: children }}
      {...other}
    />
  );
};