import { Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ConfirmDialog, useAxiosDescription, useConfirmDialog } from "../../hooks/useConfirmDialog";
import { FlagKind, FlagMark } from "../../utils/baseScopeUtils";
import { useScope } from "../../utils/ntgidapi/hooks/useScope";
import { ntgIdApi } from "../../utils/ntgidapi/NtgIdApi";
import { baseScope } from "../../utils/ntgidapi/ntgIdTypes";
import { ntgIdAllDataExtended } from "../../utils/ntgidapi/useNtgIdGetAll";
import { QueryWrapper } from "../../utils/QueryWrapper";
import { FilteredRoles } from "../Roles/RoleList";
import { ScopeBase, ScopeBaseMode, ScopeEntityStrict } from "./ScopeBase";

export function RoutedScope() {
  const param = useParams();
  const id = +(param.ScopeId || 0);

  if (id > 0)
    return <Scope id={id} />
  else
    return <div>Error</div>;
}

export function Scope({ id }: { id: number }) {
  const qScope = useScope(id);
  const [scopeBaseMode, setscopeBaseMode] = useState<ScopeBaseMode>('view');
  const scopeEditMutation = useConfirmDialog({ mutationFn: (scope: ScopeEntityStrict) => ntgIdApi.v0ScopeUpdate(scope) });
  const descCallback = useCallback((arg: ScopeEntityStrict) => (<>
    <Typography variant="h6">Old Description: </Typography>
    <Typography variant="body1">{qScope.data?.description}</Typography>
    <Typography variant="h6">New Description: </Typography>
    <Typography variant="body1">{arg.description}</Typography>
  </>), [qScope.data]);
  const description = useAxiosDescription(descCallback);

  const scopeBaseSubmit = useCallback((scope: ScopeEntityStrict) => {
    if (scopeBaseMode === 'view') {
      setscopeBaseMode('edit');
    } else if (scopeBaseMode === 'edit') {
      scopeEditMutation.startDiag(scope);
    }
  }, [scopeBaseMode, scopeEditMutation.startDiag, setscopeBaseMode]);

  const Scope = useMemo(() => ({
    id: qScope.data?.id || 0,
    name: qScope.data?.name || '',
    description: qScope.data?.description || ''
  }), [qScope.data]);

  const roleFilter = useCallback((data: ntgIdAllDataExtended) => {
    const allRoles = Array.from(data.flatRoles.values());
    return allRoles.filter(r => r.scopes.find(s => s.id === Scope.id));
  }, [Scope.id]);

  const scopeFlagger = useCallback((s: baseScope) => {
    if (s.id === Scope.id) {
      const ret: FlagMark = {
        type: FlagKind.Mark
      }
      return ret;
    }
  }, [Scope.id]);

  const roleTitle = useMemo(() => <Typography variant='h6'>Roles</Typography>, []);

  return (<>
    <Typography variant='h5'>Scope</Typography>
    <QueryWrapper QueryResult={qScope}>
      <ConfirmDialog ConfirmDialogHookResult={scopeEditMutation} Description={description} Title={() => `Edit scope ${Scope.name}`} onSuccess={() => setscopeBaseMode('view')} CloseOnSuccess={true} />
      <ScopeBase mode={scopeBaseMode} Scope={Scope} onSubmit={scopeBaseSubmit} onCancel={scopeBaseMode === 'edit' ? () => setscopeBaseMode('view') : undefined} />
      <FilteredRoles title={roleTitle} roleFilter={roleFilter} scopeFlagger={scopeFlagger} hideIfZeroRows />
    </QueryWrapper>
  </>);
}