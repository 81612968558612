import { Typography } from "@mui/material";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ConfirmDialog, useAxiosDescription, useConfirmDialog } from "../../hooks/useConfirmDialog";
import { ntgIdApi } from "../../utils/ntgidapi/NtgIdApi";
import { AudienceBase, AudienceEntityStrict } from "./AudienceBase";

export function AudienceCreate() {
  const audCreateMut = useConfirmDialog({ mutationFn: (aud: AudienceEntityStrict) => ntgIdApi.v0AudienceCreate(aud) });
  const descCallback = useCallback((arg: AudienceEntityStrict) => (<>
    <Typography variant="h6">Description</Typography>
    <Typography variant="body1">{arg.description}</Typography>
  </>), []);
  const description = useAxiosDescription(descCallback);

  const audBaseSubmit = useCallback((aud: AudienceEntityStrict) => {
    audCreateMut.startDiag(aud);
  }, [audCreateMut.startDiag]);

  const Audience = {
    id: 0,
    name: '',
    description: ''
  }

  const navigate = useNavigate();

  return (<>
    <Typography variant='h5'>Create audience</Typography>
    <ConfirmDialog ConfirmDialogHookResult={audCreateMut} Description={description} Title={(arg) => `Create audience ${arg.name}`} onSuccess={(data) => navigate(`/Audience/${data.data.id}`)} CloseOnSuccess={true} />
    <AudienceBase mode='create' Audience={Audience} onSubmit={audBaseSubmit} />
  </>);
}