import TextField, { BaseTextFieldProps, OutlinedTextFieldProps, TextFieldProps } from "@mui/material/TextField";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import React, { useCallback, useState } from "react";
import { FormInputProp } from "./FormInputPropType";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export interface FormInputTextProp<TfieldValues extends FieldValues> extends Omit<OutlinedTextFieldProps, 'name'|  'label' | 'control' | 'onChange' | 'value' | 'variant' > {
  name: Path<TfieldValues>;
  control: Control<TfieldValues>;
  label: string;
}

export function FormInputText<TfieldValues extends FieldValues> ({ name, control, label, ...other }: FormInputTextProp<TfieldValues>) {
  return (<Controller
    name={name}
    control={control}
    render={({ field: { onChange, value } }) => (
      <FormInputTextRaw onChange={onChange} value={value} label={label} size='small'  {...other}  />
    )}
  />);
};

export interface FormInputTextRawProp<TfieldValues extends FieldValues> extends Omit<OutlinedTextFieldProps, 'variant' > {
}

export function FormInputTextRaw<TfieldValues extends FieldValues> ({ ...other }: FormInputTextRawProp<TfieldValues>) {
  return (<TextField  variant="outlined" size='small' {...other}/>);
};
