import { createTable } from "@tanstack/react-table";
import { useMemo } from "react";
import { CompanyEntity } from "../../utils/ntgidapi/generatedApi/data-contracts";
import { useNtgIdGetAll } from "../../utils/ntgidapi/useNtgIdGetAll";
import { AxiosError } from "../Errors/AxiosErrorHandler";
import { CustomLink } from "../Link/CustomLink";
import { Loading } from "../Loading/Loading";
import GroupName from "../Groups/GroupName";
import { TableUnified } from "../Table/TableUnified";
import { TextButton } from "../Buttons/TextButton";
import { useNavigate } from "react-router-dom";

let table = createTable().setRowType<CompanyEntity>();

export function CompanyList() {
  const { isLoading, isError, data, error } = useNtgIdGetAll();

  const columns = useMemo(() => ([
    table.createDataColumn('name', {
      header: () => <span>Company Name</span>,
      footer: props => props.column.id,
      cell: info => {
        const compId = info.row.original?.id;
        return <CustomLink key={compId} to={"/Company/" + compId}>{info.getValue()}</CustomLink>
      }
    }),
    table.createDataColumn('googleHd', {
      header: () => <span>Google Hd</span>,
      footer: props => props.column.id,
      cell: info => info.getValue()
    }),
    table.createDataColumn('groupId', {
      header: () => <span>Group</span>,
      footer: props => props.column.id,
      cell: info => <CustomLink key={info.getValue()} to={"/Group/" + info.getValue()}><GroupName id={info.getValue() || 0}/></CustomLink>
    }),
  ]), []);

  const navigate = useNavigate();

  if (isLoading)
    return <Loading />;
  if (isError)
    return <AxiosError text={'Error when loading companies'} error={error} />;

  return (
    <>
      <TextButton onClick={() => navigate('/CreateCompany')}>Create Company</TextButton>
      <TableUnified table={table} columns={columns} data={data.companies} />
    </>);
}