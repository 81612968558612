import { ColumnDef, Row, SortingState, Table, TableInstance, useTableInstance, getCoreRowModel, getSortedRowModel, Column, ColumnFiltersState, getFilteredRowModel } from "@tanstack/react-table";
import { table } from "console";
import { ReactNode, useState } from "react";
import { Table as TableMui, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { FormInputTextRaw } from "../Form/FormInputText";
import { ArrowDropDownOutlined, ArrowDropUpOutlined, SortOutlined } from "@mui/icons-material";

interface rowSpans {
  [id: string]: { value: any, count: number };
}

interface rowsWithRowSpans {
  row: Row<any>;
  rowspans: rowSpans
}

export interface TableUnifiedRootProps {
  hideIfZeroRows?: boolean;
  alternativeIfZeroRows?: ReactNode;
  title?: ReactNode;
  withFilters?: boolean;
}

export interface TableUnifiedProps extends TableUnifiedRootProps {
  table: Table<any>;
  columns: ColumnDef<any>[];
  data: any;
  columnIds?: string[];
  withLastRow?: boolean;
}

function Filter({
  column,
  instance,
}: {
  column: Column<any>
  instance: TableInstance<any>
}) {
  let firstValue;
  try {
    firstValue =
      instance.getPreFilteredRowModel().flatRows[0]?.getValue(column.id);
  } catch {
    return <></>;
  }

  const columnFilterValue = column.getFilterValue()

  if (typeof firstValue === 'string') {
    return (<FormInputTextRaw
      inputProps={{ type: 'search' }}
      value={(columnFilterValue ?? '') as string}
      onChange={e => column.setFilterValue(e.target.value)}
      label='Search'
    />);
  }

  return <></>;
}

function rowsWithoutSpan(instance: TableInstance<any>, rows: Row<any>[]) {
  return (<TableBody>
    {rows
      .map(row => {
        return (
          <TableRow key={row.id}>
            {row.getVisibleCells().map(cell => {
              return <TableCell key={cell.id}>{cell.renderCell()}</TableCell>
            })}
          </TableRow>
        )
      })}
  </TableBody>);
}

function rowsWithSpan(instance: TableInstance<any>, columnIds: string[], rows: Row<any>[], skipSpanForLast?: boolean) {
  const curRow: { [id: string]: rowsWithRowSpans } = {};
  const rowsWithSpan: rowsWithRowSpans[] = [];
  const lastRowIdx = rows.length - 1;
  rows.forEach((row, index) => {
    const cells = row._getAllCellsByColumnId();
    const rowspans: rowSpans = {};
    columnIds.forEach(cId => rowspans[cId] = { value: cells[cId].getValue(), count: 1 });

    const rowWithSpan = { row: row, rowspans: rowspans }

    rowsWithSpan.push(rowWithSpan);
    if (rowsWithSpan.length === 1) {
      columnIds.forEach(cId => {
        curRow[cId] = rowWithSpan;
      });
      return;
    }
    if (skipSpanForLast && lastRowIdx === index) {
      return;
    }
    columnIds.forEach(cId => {
      const prevRowWithSpan = curRow[cId];
      if (prevRowWithSpan.rowspans[cId].value === rowWithSpan.rowspans[cId].value) {
        prevRowWithSpan.rowspans[cId].count++;
        rowWithSpan.rowspans[cId].count = 0;
      } else {
        curRow[cId] = rowWithSpan;
      }
    });
  });
  return (<TableBody>
    {rowsWithSpan.map(rowWS => {
      return (
        <TableRow key={rowWS.row.id}>
          {rowWS.row.getVisibleCells().map(cell => {
            const rowSpanCount = columnIds.find(i => i === cell.column.id) ? (rowWS.rowspans[cell.column.id].count) : 1;
            if (rowSpanCount === 0) {
              return;
            } else if (rowSpanCount === 1) {
              return <TableCell key={cell.id}>{cell.renderCell()}</TableCell>;
            } else {
              return <TableCell key={cell.id} rowSpan={rowSpanCount}>{cell.renderCell()}</TableCell>;
            }
          })}
        </TableRow>
      )
    })}
  </TableBody>);
}

function bodyGen(instance: TableInstance<any>, columnIds?: string[], withLastRow?: boolean) {
  let rows = instance.getRowModel().rows;
  let lastRowIdx = -1;

  if (withLastRow) {
    lastRowIdx = rows.findIndex(row => row.original?.last);
    if (lastRowIdx >= 0) {
      rows.push(rows.splice(lastRowIdx, 1)[0]);
    }
  }

  if (columnIds && columnIds.length > 0) {
    return rowsWithSpan(instance, columnIds, rows, withLastRow && lastRowIdx >= 0);
  } else {
    return rowsWithoutSpan(instance, rows);
  }
}

export function TableUnified({ table, columns, data, columnIds, title, hideIfZeroRows, withLastRow, alternativeIfZeroRows, withFilters }: TableUnifiedProps) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('')
  const instance = useTableInstance(table, {
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),

    state: {
      sorting,
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),

    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),

    debugTable: true
  });

  if (instance.getRowModel().rows.length === 0) {
    if (hideIfZeroRows)
      return <></>;
    if (alternativeIfZeroRows)
      return <>{alternativeIfZeroRows}</>;
  }

  return (<>{title}
    <TableMui size='small' stickyHeader={true}>
      <TableHead>
        {instance.getHeaderGroups().map(headerGroup => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map(header => {
              return (
                <TableCell key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <>
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {header.renderHeader()}
                        {{
                          asc: <ArrowDropUpOutlined sx={{display: 'inline-flex', verticalAlign: 'top'}} />,
                          desc: <ArrowDropDownOutlined sx={{display: 'inline-flex', verticalAlign: 'top'}} />,
                        }[header.column.getIsSorted() as string] ?? 
                          <SortOutlined sx={{display: 'inline-flex', verticalAlign: 'top'}} />}
                      </div>
                      {withFilters && header.column.getCanFilter() ? (
                        <div>
                          <Filter
                            column={header.column}
                            instance={instance}
                          />
                        </div>) : null}
                    </>
                  )}

                </TableCell>
              )
            })}
          </TableRow>
        ))}
      </TableHead>
      {bodyGen(instance, columnIds, withLastRow)}
    </TableMui>
    <div>{instance.getRowModel().rows.length} Rows</div>
  </>);
}