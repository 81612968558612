import { Box, Grid, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { ConfirmDialog, mutationStatus, useAxiosDescription, useConfirmDialog } from "../../hooks/useConfirmDialog";
import { UserEntity } from "../../utils/ntgidapi/generatedApi/data-contracts";
import { useUser } from "../../utils/ntgidapi/hooks/useUser";
import { ntgIdApi } from "../../utils/ntgidapi/NtgIdApi";
import { useNtgIdGetAll } from "../../utils/ntgidapi/useNtgIdGetAll";
import { QueryWrapper } from "../../utils/QueryWrapper";
import { TextButton } from "../Buttons/TextButton";
import { FormAutoCompleteComboRaw } from "../Form/FormAutocompleteCombo";
import { FormPasswordRaw } from "../Form/FormPassword";
import { UserBase, UserEntityStrict, useStrictUser } from "../Users/UserBase";
import { Visible } from "../Utils/visible";
import { BasePassword } from "./BasePassword";

export interface PasswordProps {
  User: UserEntityStrict;
  onSuccess?: () => void;
  buttonText?: string;
}

interface UserEntityStrictWithPass extends UserEntityStrict {
  password: string;
}

export function UsersPassword() {
  const qUsers = useQuery(['v0UserList', 'all'], () => { return ntgIdApi.v0UserList(); });
  const options = useMemo(() => {
    const ret = [{ label: '', value: 0 }];
    qUsers.data?.data.forEach(u => ret.push({ label: u.userName || ' ', value: u.id || 0 }));
    return ret;
  }, [qUsers.data]);
  const [userId, setUserId] = useState(0);
  const qUser = useUser(userId);
  const User = useStrictUser(qUser.data);

  return (<QueryWrapper QueryResult={qUsers}>
    <Grid container direction='column' spacing={2} alignItems='center'>
      <Grid item>
        <FormAutoCompleteComboRaw value={userId} onValueChange={setUserId} options={options} label='User' sx={{ minWidth: 300 }} />
      </Grid>
      <Grid item>
        <Visible visible={!!userId}>
          <QueryWrapper QueryResult={qUser}>
            <Password User={User} onSuccess={() => setUserId(0)} />
          </QueryWrapper>
        </Visible>
      </Grid>
    </Grid>
  </QueryWrapper>);
}

export function Password({ User, onSuccess, buttonText }: PasswordProps) {

  const passEditMutation = useConfirmDialog({ mutationFn: (user: UserEntityStrictWithPass) => ntgIdApi.v0UserUpdate2(user.id || 0, user) });

  const description = useCallback((arg: UserEntityStrict, status: mutationStatus, _data?: unknown, error?: unknown) => {
    if (status !== 'error') {
      return (<>
        <UserBase mode='view' Entity={arg} onSubmit={() => { }} noButtons />
      </>);
    } else {
      const err: any = error;
      if (err && err.response && err.response.status === 400 && err.response.data?.message) {
        return (<Box maxWidth='sm'>
          <Typography variant='h6'>Error</Typography>
          {err.response.data.message}
        </Box>);
      } else {
        return <>Error while trying to change password</>;
      }
    }
  }, []);
  const passWordUpdate = useCallback((user: UserEntityStrict, password: string) => {
    passEditMutation.startDiag({ ...user, password: password });
  }, [passEditMutation.startDiag]);

  const passControl = useCallback((password: string, resetPassword: () => void) => {
    return (<>
      <ConfirmDialog
        ConfirmDialogHookResult={passEditMutation}
        Description={description} Title={() => `Edit password for user ${User.userName}`}
        onSuccess={() => { resetPassword(); onSuccess && onSuccess(); }}
        CloseOnSuccess={true}
        maxWidth='xl'
        fullWidth={false} />
      <TextButton onClick={() => passWordUpdate(User, password)}>{buttonText || 'Ok'}</TextButton>
    </>);
  }, [User, passEditMutation]);

  return <BasePassword passwordAction={passControl} />;
}