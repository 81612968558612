import { useQuery } from "react-query";
import { ntgIdApi } from "../../utils/ntgidapi/NtgIdApi";

export default function GroupName({ id }: { id: number }) {
  const { isLoading, isError, data, error } = useQuery(['group', id], () => {
    return ntgIdApi.v0GroupDetail(id);
  });

  if (isLoading)
    return (<div>Loading ...</div>);

  if (isError)
    return (<div>###Error###</div>);

  return (<div>{data.data.name}</div>);
}