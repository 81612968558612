import { useQuery } from "react-query";
import { AudienceEntity } from "../generatedApi/data-contracts";
import { ntgIdApi } from "../NtgIdApi";

export function useAudience(audienceId: number) {
  const qAudience = useQuery(['audience', audienceId], () => {
    if (audienceId <= 0) {
      return new Promise<AudienceEntity>((resolve) => {
        const emptyUser: AudienceEntity = {
          id: 0,
          name: "Audience Name",
          description: 'Audience description'
        }
        resolve(emptyUser);
      });
    }
    else {
      return ntgIdApi.v0AudienceDetail(audienceId).then(response => response.data);
    }
  });

  return qAudience;
}