import { OutlinedTextFieldProps, TextField } from "@mui/material";
import React, { ReactNode } from "react";
import SyntaxHighlighter, { SyntaxHighlighterProps } from 'react-syntax-highlighter';

export interface MuiSyntaxHighlighterProps extends Omit<OutlinedTextFieldProps, 'variant'> {
  children: string | string[],
  label: string,
  syntaxHighlighterProps?: SyntaxHighlighterProps,
  language: string
}

const InputHL = React.forwardRef<unknown, SyntaxHighlighterProps>((props, ref) => <SyntaxHighlighter {...props} />);

export function MuiSyntaxHighlighter({ children, label, syntaxHighlighterProps, language, ...other }: MuiSyntaxHighlighterProps) {
  return (
    <TextField
      variant="outlined"
      label={label}
      multiline
      InputLabelProps={{ shrink: true }}
      InputProps={{
        inputComponent: InputHL
      }}
      inputProps={{ ...syntaxHighlighterProps, children: children, language }}
      {...other}
    />
  );
};