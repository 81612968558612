import { createTable } from "@tanstack/react-table";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { GoogleClientIdEntity } from "../../utils/ntgidapi/generatedApi/data-contracts";
import { useAudience } from "../../utils/ntgidapi/hooks/useAudience";
import { ntgIdApi } from "../../utils/ntgidapi/NtgIdApi";
import { QueryWrapper } from "../../utils/QueryWrapper";
import { CustomLink } from "../Link/CustomLink";
import { TableUnified } from "../Table/TableUnified";
import { Todo } from "../Todo/Todo";
import { GoogleClientEntityStrict } from "./GoogleClientIdBase";

let table = createTable().setRowType<GoogleClientEntityStrict>();

export interface AudienceNameProps {
  id: number;
}

function AudienceName({id}: AudienceNameProps) {
  const aud = useAudience(id);
  
  if (id <= 0) {
    return <>###ERROR###</>;
  } else {
    return (
      <QueryWrapper QueryResult={aud}>
        {aud.data?.name}
      </QueryWrapper>);
  }
}

export interface GoogleClientIdFilteredListProp {
  filter?: (arg: GoogleClientIdEntity) => boolean;
}

export function GoogleClientIdFilteredList({ filter } : GoogleClientIdFilteredListProp) {
  const qGIds = useQuery(["gIds"], () => ntgIdApi.v0GoogleClientIdList());

  const data = useMemo(() => {
    if (!qGIds.data) {
      return [];
    }
    const filtered = filter ? qGIds.data.data.filter(filter) : qGIds.data.data;
    return filtered.map(g => ({
      id: g.id || 0,
      audienceId: g.audienceId,
      googleClientId: g.googleClientId,
      description: g.description
    }));
  }, [qGIds, filter]);

  const columns = useMemo(() => ([
    table.createDataColumn('googleClientId', {
      header: () => <span>Google Client Id</span>,
      footer: props => props.column.id,
      cell: info => {
        const audienceId = info.row.original?.audienceId || 0;
        const googleClientId = info.getValue()
        return (
          <CustomLink key={googleClientId + audienceId} to={`/GoogleClientId/${audienceId}/${googleClientId}`}>
            {googleClientId}
          </CustomLink>);
      }
    }),
    table.createDataColumn('audienceId', {
      header: () => <span>Audience Name</span>,
      footer: props => props.column.id,
      cell: info => {
        const audienceId = info.row.original?.audienceId || 0;
        return (<CustomLink key={audienceId} to={"/Audience/" + audienceId}>
          <AudienceName id={audienceId} />
        </CustomLink>);
      }
    }),
    table.createDataColumn('description', {
      header: () => <span>Description</span>,
      footer: props => props.column.id,
      cell: info => info.getValue()
    }),
  ]), []);

  return (<QueryWrapper QueryResult={qGIds}>
    <TableUnified table={table} columns={columns} data={data} withFilters />
  </QueryWrapper>);
}

export function GoogleClientIdList() {
  return <GoogleClientIdFilteredList />;
}