import React, { useCallback, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import {
  Route,
  Routes
} from "react-router-dom";
import Login from './components/Login/Login';
import TokenDisplay from './components/TokenDisplay/TokenDisplay';
import { ntgIdApi, NtgIdServerType } from './utils/ntgidapi/NtgIdApi';
import Layout from './components/Layout/Layout';
import Home from './components/Home/Home';
import UserList from './components/Users/UserList';
import { RoutedUser } from './components/Users/User';
import { Todo } from './components/Todo/Todo';
import { Error404 } from './components/Errors/Error404';
import { RoutedGroup } from './components/Groups/Group';
import { Roles } from './components/Roles/RoleList';
import { Groups } from './components/Groups/Groups';
import { RoutedRole } from './components/Roles/Role';
import { Audiences } from './components/Audience/AudienceList';
import { CompanyList } from './components/Company/CompanyList';
import { RoutedAudience } from './components/Audience/Audience';
import { AudienceCreate } from './components/Audience/AudienceCreate';
import { Scopes } from './components/Scope/ScopeList';
import { RoutedScope } from './components/Scope/Scope';
import { ScopeCreate } from './components/Scope/ScopeCreate';
import { RoleCreate } from './components/Roles/RoleCreate';
import { GroupCreate } from './components/Groups/GroupCreate';
import { UserCreate } from './components/Users/UserCreate';
import { RoutedCompany } from './components/Company/Company';
import { CompanyCreate } from './components/Company/CompanyCreate';
import { GoogleClientIdList } from './components/GoogleClientId/GoogleClientIdList';
import { RoutedGoogleClientId } from './components/GoogleClientId/GoogleClientId';
import { GoogleClientCreate } from './components/GoogleClientId/GoogleClientIdCreate';
import { Password, UsersPassword } from './components/Password/Password';
import { SelfPassword } from './components/Password/SelfPassword';

export const UserContext = React.createContext<string>("");

function App(props: any) {
  //const [token, setToken] = useState<string | undefined>();
  //const [ntgIdApi, setNtgIdApi] = useState<NtgIdApi | undefined>();
  const [userName, setUserName] = useState("");
  const [serverType, setServerType] = useState(ntgIdApi.getServerType());
  const setServerTypeCb = useCallback((t: NtgIdServerType) => {
    ntgIdApi.SetNtgIdServer(t);
    setServerType(t);
  }, [setServerType, ntgIdApi]);
  const setAuthenticated = useState(false)[1];

  function logginCallback(authenticated: boolean, username: string) {
    setUserName(username);
    setAuthenticated(authenticated);
  }

  ntgIdApi.isLoggedInCallback = logginCallback;

  if (!ntgIdApi.isLoggedIn()) {
    return (
      <Layout serverType={serverType}>
        <Routes>
          <Route path="/*" element={<Login serverType={serverType} setServerType={setServerTypeCb} />}>  </Route>
          <Route path="/Token" element={<TokenDisplay token={""} />}>  </Route>
        </Routes>
      </Layout>
    );
  }
  return (
    <UserContext.Provider value={userName}>
      <Layout serverType={serverType}>
        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="Users" element={<UserList />} > </Route>
          <Route path="User/:UserId" element={<RoutedUser />} > </Route>
          <Route path="CreateUser" element={<UserCreate />} > </Route>

          <Route path="Audience/:AudienceId" element={<RoutedAudience />} />
          <Route path="Audiences" element={<Audiences />} />
          <Route path="CreateAudience" element={<AudienceCreate />} />

          <Route path="Companies" element={<CompanyList />} />
          <Route path="Company/:CompanyId" element={<RoutedCompany />} />
          <Route path="CreateCompany" element={<CompanyCreate />} />

          <Route path="Groups" element={<Groups />} />
          <Route path="Group/:GroupId" element={<RoutedGroup />} />
          <Route path="CreateGroup" element={<GroupCreate/>} />

          <Route path="Roles" element={<Roles />} />
          <Route path="Role/:RoleId" element={<RoutedRole />} />
          <Route path="CreateRole" element={<RoleCreate/>} />

          <Route path="Scopes" element={<Scopes />} />
          <Route path="Scope/:ScopeId" element={<RoutedScope />} />
          <Route path="CreateScope" element={<ScopeCreate />} />

          <Route path="GoogleClientIds" element={<GoogleClientIdList />} />
          <Route path="GoogleClientId/:AudienceId/:GoogleClientId" element={<RoutedGoogleClientId />} />
          <Route path="CreateGoogleClientId" element={<GoogleClientCreate />} />

          <Route path="CurrentToken" element={<TokenDisplay token={ntgIdApi.getToken()} />} />
          <Route path="Token" element={<TokenDisplay token={''} />} />
          <Route path="Password" element={<UsersPassword />} />
          <Route path="SelfPassword" element={<SelfPassword />} />

          <Route path="*" element={<Error404 />} />
        </Routes>
      </Layout>
    </UserContext.Provider>
  );
}

export default App;