import { useMemo } from "react";
import { useQuery } from "react-query";
import { ntgIdApi } from "../../utils/ntgidapi/NtgIdApi";
import { AudienceEntity, CompanyEntity, GroupEntity, GroupRoleEntity, RoleEntity, RoleRightEntity, UserEntity, UserGroupEntity, UserRoleEntity } from "./generatedApi/data-contracts";
import { AudienceWithScopesAndUsers, baseScope, baseUser, flatCompany, flatCompanyDictionary, flatGroup, flatGroupDictionary, flatNoGroup, flatNoGroupDictionary } from "./ntgIdTypes";

export interface ntgIdAllData {
  users: UserEntity[],
  userGroups: UserGroupEntity[],
  userRoles: UserRoleEntity[],
  groupRoles: GroupRoleEntity[],
  roles: RoleEntity[],
  roleRights: RoleRightEntity[],
  audiences: AudienceEntity[],
  groups: GroupEntity[],
  companies: CompanyEntity[]
}

export interface ntgIdAllDataExtended extends ntgIdAllData {
  flatRoles: flatNoGroupDictionary,
  flatGroups: flatGroupDictionary,
  flatCompanies: flatCompanyDictionary,
}

export interface ntgIdGetAllResponseError {
  isError: true,
  isLoading: false,
  error: unknown,
  data: undefined
}

export interface ntgIdGetAllResponseData<T extends ntgIdAllData> {
  isError: false,
  isLoading: false,
  error: null,
  data: T
}

export interface ntgIdGetAllResponseLoading {
  isError: false,
  isLoading: true,
  error: null,
  data: undefined
}

export interface ntgIdGetAllResponseLoadingError {
  isError: true,
  isLoading: true,
  error: unknown,
  data: undefined
}

export type ntgIdGetAllResponse<T extends ntgIdAllData> = ntgIdGetAllResponseData<T> | ntgIdGetAllResponseError | ntgIdGetAllResponseLoading | ntgIdGetAllResponseLoadingError;

export function useNtgIdGetAll(): ntgIdGetAllResponse<ntgIdAllData> {
  const qUsers = useQuery(['v0UserList', 'all'], () => { return ntgIdApi.v0UserList(); });
  const qUserGroups = useQuery(['v0UsergroupList', 'all'], () => { return ntgIdApi.v0UsergroupList(); });
  const qUserRoles = useQuery(['v0UserroleList', 'all'], () => { return ntgIdApi.v0UserroleList(); });
  const qGroupRoles = useQuery(['v0GrouproleList', 'all'], () => { return ntgIdApi.v0GrouproleList(); });
  const qRoles = useQuery(['v0RoleList', 'all'], () => { return ntgIdApi.v0RoleList(); });
  const qRoleRights = useQuery(['v0RolerightList', 'all'], () => { return ntgIdApi.v0RolerightList(); });
  const qAudiences = useQuery(['v0AudienceList', 'all'], () => { return ntgIdApi.v0AudienceList(); });
  const qCompanies = useQuery(['v0CompanyList', 'all'], () => { return ntgIdApi.v0CompanyList(); });
  const qGroups = useQuery(['v0GroupList', 'all'], () => { return ntgIdApi.v0GroupList(); });

  const ret = useMemo<ntgIdGetAllResponse<ntgIdAllData>>(() => {
    const isLoading = qUsers.isLoading || qUserGroups.isLoading || qUserRoles.isLoading || qGroupRoles.isLoading || qRoles.isLoading || qRoleRights.isLoading || qAudiences.isLoading || qCompanies.isLoading || qGroups.isLoading;
    const isError = qUsers.isError || qUserGroups.isError || qUserRoles.isError || qGroupRoles.isError || qRoles.isError || qRoleRights.isError || qAudiences.isError || qCompanies.isError || qGroups.isError;

    const qResults = [qUsers, qUserGroups, qUserRoles, qGroupRoles, qRoles, qRoleRights, qAudiences, qCompanies, qGroups];
    let data = undefined;

    if (isLoading) {
      if (isError) {
        const error = qResults.filter(e => e.error).map(a => a.error);
        return { isError, isLoading, error, data: undefined };
      } else {
        return { isError, isLoading, error: null, data: undefined };
      }
    }
    if (isError) {
      const error = qResults.filter(e => e.error).map(a => a.error);
      return { isError, isLoading, error, data };
    } else {

      data = {
        users: qUsers.data.data,
        userGroups: qUserGroups.data.data,
        userRoles: qUserRoles.data.data,
        groupRoles: qGroupRoles.data.data,
        roles: qRoles.data.data,
        roleRights: qRoleRights.data.data,
        audiences: qAudiences.data.data,
        /*
        flatRoles,
        flatGroups,
        flatCompanies,
        */
        groups: qGroups.data.data,
        companies: qCompanies.data.data,
      };
      return { isError, isLoading, error: null, data };
    }
  }, [
    qUsers.data, qUserGroups.data, qUserRoles.data, qGroupRoles.data, qRoles.data, qRoleRights.data, qAudiences.data, qCompanies.data, qGroups.data,
    qUsers.isLoading, qUserGroups.isLoading, qUserRoles.isLoading, qGroupRoles.isLoading, qRoles.isLoading, qRoleRights.isLoading, qAudiences.isLoading, qCompanies.isLoading, qGroups.isLoading,
    qUsers.isError, qUserGroups.isError, qUserRoles.isError, qGroupRoles.isError, qRoles.isError, qRoleRights.isError, qAudiences.isError, qCompanies.isError, qGroups.isError
  ]);

  return ret;
}

export function useNtgIdGetAllExtended(): ntgIdGetAllResponse<ntgIdAllDataExtended> {
  const ntgidData = useNtgIdGetAll();

  const ret = useMemo(() => {
    if (ntgidData.isError || ntgidData.isLoading) {
      return ntgidData;
    }

    const baseData = ntgidData.data;

    const flatRoles: flatNoGroupDictionary = new Map<number, flatNoGroup>();
    const audiences = baseData.audiences;
    const roleRights = baseData.roleRights;
    baseData.roles.forEach(r => {
      flatRoles.set(r.id || 0, {
        companyId: undefined,
        companyName: undefined,
        groupId: undefined,
        groupName: undefined,
        roleId: r.id || 0,
        roleName: r.name,
        audienceId: r.audienceId,
        audienceName: audiences.find(a => a.id === r.audienceId)?.name || "",
        scopes: roleRights.filter(rr => rr.roleId === r.id).map(rr => {
          return { id: rr.scopeId || 0, name: rr.scopeName || "" };
        }).filter(s => s.id > 0),
      });
    });

    const flatGroups: flatGroupDictionary = new Map<number, flatGroup[]>();
    baseData.groupRoles.forEach(gr => {
      const flatRole = flatRoles.get(gr.roleId || 0);
      if (flatRole) {
        const fgs = flatGroups.get(gr.groupId || 0) || [];
        fgs.push({ ...flatRole, groupId: gr.groupId || 0, groupName: gr.groupName || "" });
        flatGroups.set(gr.groupId || 0, fgs);
      }
    });
    baseData.groups.forEach(gr => {
      const fgs = flatGroups.get(gr.id || 0) || [];
      const fg: flatGroup ={
        groupId: gr.id || 0,
        groupName: gr.name || "",
        companyId: undefined,
        companyName: undefined,
        roleId: 0,
        roleName: "",
        audienceId: 0,
        audienceName: "",
        scopes: []
      } ;
      if (fgs.length === 0)
        fgs.push(fg);
      flatGroups.set(gr.id || 0, fgs);
    });

    const flatCompanies: flatCompanyDictionary = new Map<number, flatCompany[]>();
    baseData.companies.forEach(c => {
      if (c.groupId && c.id) {
        const matchingGroups = flatGroups.get(c.groupId);
        if (matchingGroups)
          flatCompanies.set(c.id, matchingGroups.map(g => ({ ...g, companyId: c.id || 0, companyName: c.name })));
      }
    });

    return {
      ...ntgidData,
      data: {
        ...ntgidData.data,
        flatRoles,
        flatGroups,
        flatCompanies
      }
    };
  }, [ntgidData.isError, ntgidData.isLoading, ntgidData.data, ntgidData.error])
  return ret;
}

export interface ntgIdFullAudiences extends ntgIdAllData {
  fullAudiences: AudienceWithScopesAndUsers[];
}

export function useNtgIdGetFullAudiences(): ntgIdGetAllResponse<ntgIdFullAudiences> {
  const ntgidData = useNtgIdGetAll();
  const qUserRights = useQuery(['userRight'], () => ntgIdApi.v0RightList());

  const ret = useMemo((): ntgIdGetAllResponse<ntgIdFullAudiences> => {
    if (ntgidData.isLoading || qUserRights.isLoading) {
      if (ntgidData.isError || qUserRights.isError)
        return {
          error: null,
          isLoading: true,
          isError: true,
          data: undefined
        }
      else return {
        error: null,
        isLoading: true,
        isError: false,
        data: undefined
      }
    }

    if (ntgidData.isError || qUserRights.isError) {
      const error = ntgidData.error || [];
      if (qUserRights.isError && Array.isArray(error)) {
        error.push(qUserRights.error);
      }
      return {
        error,
        data: undefined,
        isError: true,
        isLoading: false
      }
    }

    const baseData = ntgidData.data;
    const audiencesMap = new Map<number, AudienceWithScopesAndUsers>();

    function AddScope(scope: baseScope, audienceIds?: number[]) {
      audienceIds?.forEach(aId => {
        const aud = audiencesMap.get(aId);
        if (aud) {
          aud.scopes.set(scope.id, scope);
        }
      });
    }

    function AddUser(user: baseUser, audienceIds?: number[]) {
      audienceIds?.forEach(aId => {
        const aud = audiencesMap.get(aId);
        if (aud) {
          aud.users.set(user.id, user);
        }
      });
    }

    baseData.audiences.forEach(a => {
      const fullAud: AudienceWithScopesAndUsers = {
        id: a.id || 0,
        name: a.name,
        scopes: new Map<number, baseScope>(),
        users: new Map<number, baseUser>()
      }
      if (fullAud.id > 0) {
        audiencesMap.set(fullAud.id, fullAud);
      }
    });

    const RoleToAudience = new Map<number, number>();
    const GroupToAudiences = new Map<number, number[]>();
    const CompanyToAudiences = new Map<number, number[]>();

    baseData.roles.forEach(r => {
      RoleToAudience.set(r.id || 0, r.audienceId);
    });

    baseData.groupRoles.forEach(gr => {
      if (gr.groupId && gr.roleId) {
        const audienceIds = GroupToAudiences.get(gr.groupId) || [];
        if (audienceIds.length == 0) {
          GroupToAudiences.set(gr.groupId, audienceIds);
        }
        const aId = RoleToAudience.get(gr.roleId);
        if (aId) {
          audienceIds.push(aId);
        }
      }
    });

    baseData.companies.forEach(c => {
      if (c.groupId && c.id) {
        const audIds = GroupToAudiences.get(c.groupId);
        if (audIds)
          CompanyToAudiences.set(c.id, audIds);
      }
    });

    baseData.users.forEach(u => {
      if (u.companyId && u.id && u.userName) {
        AddUser({ id: u.id, userName: u.userName }, CompanyToAudiences.get(u.companyId));
      }
    })

    baseData.userGroups.forEach(ug => {
      if (ug.userId && ug.groupId && ug.userName) {
        AddUser({ id: ug.userId, userName: ug.userName }, GroupToAudiences.get(ug.groupId));
      }
    })

    baseData.userRoles.forEach(ur => {
      if (ur.userId && ur.roleId && ur.userName) {
        const audId = RoleToAudience.get(ur.roleId);
        if (audId)
          AddUser({ id: ur.userId, userName: ur.userName }, [audId]);
      }
    })

    qUserRights.data.data.forEach(ur => {
      if (ur.audienceId) {
        if (ur.userId && ur.userName)
          AddUser({ id: ur.userId, userName: ur.userName }, [ur.audienceId]);
        if (ur.scopeId && ur.scopeName)
          AddScope({ id: ur.scopeId, name: ur.scopeName }, [ur.audienceId])
      }
    });

    baseData.roleRights.forEach(rr => {
      if (rr.roleId && rr.scopeId && rr.scopeName) {
        const audId = RoleToAudience.get(rr.roleId);
        if (audId)
          AddScope({ id: rr.scopeId, name: rr.scopeName }, [audId])
      }
    })

    return {
      ...ntgidData,
      data: {
        ...ntgidData.data,
        fullAudiences: Array.from(audiencesMap.values())
      }
    };
  }, [ntgidData.isError, ntgidData.isLoading, ntgidData.data, ntgidData.error,
  qUserRights.data, qUserRights.isError, qUserRights.isLoading, qUserRights.error])

  return ret;
}