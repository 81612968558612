import { Badge, Typography } from "@mui/material";
import { ReactNode } from "react";
import { CustomLink } from "../components/Link/CustomLink";
import { baseScope } from "./ntgidapi/ntgIdTypes";

// less specific scope (less ':' in) are considered bigger
// "*"" is considered bigger than other string

// finally alphabetical order if it comes to that
function scopeSort(a: baseScope, b: baseScope) {
  const aSplit = a.name.split(':');
  const bSplit = b.name.split(':');

  let sizeCompare = Math.sign(aSplit.length - bSplit.length);
  if (sizeCompare !== 0)
    return -sizeCompare;

  const minLength = Math.min(aSplit.length, bSplit.length);

  let ret = 0;
  for (let i = 0; i < minLength; i++) {
    const aSub = aSplit[i];
    const bSub = bSplit[i];
    const aHasWildcard = aSub.includes("*") ? 1 : 0;
    const bHasWildcard = bSub.includes("*") ? 1 : 0;

    if (aHasWildcard ^ bHasWildcard) {
      return aHasWildcard - bHasWildcard;
    }
    if (ret === 0)
      ret = aSub.localeCompare(bSub);
  }

  return ret;
}

export enum FlagKind {
  None,
  Mark,
  Custom
};

interface FlagNone {
  type: FlagKind.None;
}

export interface FlagMark {
  type: FlagKind.Mark;
}

interface FlagCustom {
  type: FlagKind.Custom;
  wrapperFn: (arg: ReactNode) => ReactNode;
}

type Flags = FlagNone | FlagMark | FlagCustom | undefined;

export type ScopeFlaggerType = (s: baseScope) => Flags;

export function enhancedScopeRenderer(scope: baseScope, flagger?: (s: baseScope) => Flags) {
  const base = <CustomLink to={"/Scope/" + scope.id}>{scope.name}</CustomLink>;
  const flags = flagger && flagger(scope);
  if (flags && flags.type === FlagKind.Mark) {
    return <Badge variant='dot' color='success'>{base}</Badge>;
  }
  return base;
}

export function scopesCellFormater(scopes: baseScope[], flagger?: (s: baseScope) => Flags) {
  return (<div style={{ wordSpacing: '1em' }}>
    {scopes.sort(scopeSort).map(scope => (
      <span key={scope.id}>{enhancedScopeRenderer(scope, flagger)} </span>))}
  </div>);
}