import { Box, Container, Grid } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { UserEntity } from "../../utils/ntgidapi/generatedApi/data-contracts";
import { useScopes } from "../../utils/ntgidapi/hooks/useScopes";
import { ntgIdApi } from "../../utils/ntgidapi/NtgIdApi";
import { useNtgIdGetAll } from "../../utils/ntgidapi/useNtgIdGetAll";
import { QueryWrapper } from "../../utils/QueryWrapper";
import { TextButton } from "../Buttons/TextButton";
import { BaseMode } from "../Common/BaseScopeAudience";
import { FormAutoCompleteCombo } from "../Form/FormAutocompleteCombo";
import { FormInputDropdown, FormInputDropdownOption } from "../Form/FormInputDropDown";
import { FormInputText, FormInputTextRaw } from "../Form/FormInputText";
import { FormTextArea } from "../Form/FormTextArea";
import { Visible } from "../Utils/visible";

export interface UserEntityStrict {
  id: number;
  userName: string;
  //  password: string;
  firstName: string;
  lastName: string;
  companyId: number;
  email: string;
}

export function useStrictUser(user?: UserEntity): UserEntityStrict{
  const strictUser = useMemo(() => ({
    id: user?.id || 0,
    userName: user?.userName || '',
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
    companyId: user?.companyId || 0,
  }), [user]);
  return strictUser;
}

export type UserBaseMode = BaseMode;

export interface UserBaseProps {
  Entity: UserEntityStrict;
  mode: UserBaseMode;
  onSubmit: (ent: UserEntityStrict) => void;
  onCancel?: () => void;
  ButtonLabel?: string;
  noButtons?: boolean;
}

export function UserBase({ Entity, mode, ButtonLabel, onSubmit, onCancel, noButtons }: UserBaseProps) {
  const { register, handleSubmit, control, formState: { errors }, reset } = useForm<UserEntityStrict>({
    defaultValues: Entity
  });
  const qCompanies = useQuery(['v0CompanyList'], () => { return ntgIdApi.v0CompanyList(); });

  const cancelButton = useMemo(() => {
    if (onCancel)
      return <TextButton onClick={() => {onCancel(); reset(Entity);}}>Cancel</TextButton>;
    return undefined;
  }, [onCancel]);

  const formButtons = useMemo(() => {
    if (noButtons) {
      return;
    }
    let buttonLabel: string | undefined = ButtonLabel;
    if (!buttonLabel) {
      if (mode === 'view') {
        buttonLabel = 'Edit';
      } else if (mode === 'edit') {
        buttonLabel = 'Save';
      } else {
        buttonLabel = 'Create';
      }
    }
    if (cancelButton) {
      return (<>
        {cancelButton}
        <TextButton onClick={handleSubmit(onSubmit)}>{buttonLabel}</TextButton>
      </>);
    }
    else return <TextButton onClick={handleSubmit(onSubmit)}>{buttonLabel}</TextButton>;
  }, [mode, ButtonLabel, cancelButton, noButtons]);

  const extraFieldProp = {
    readOnly: (mode === 'view'),
  }

  const companySelector = useMemo(() => {
    if (!qCompanies.data) {
      return;
    }
    const label = 'Company';
    const companies = qCompanies.data.data;
    if (mode !== 'create') {
      const companyName = (Entity.companyId === 0)
      ? ''
      : (companies.find(c => c.id === Entity.companyId)?.name || 'Error company name');
      return <FormInputTextRaw label={label} name="companyName" value={companyName} {...extraFieldProp} />;
    } else {
      const options: FormInputDropdownOption[] = companies.map(c => ({ value: c.id || 0, label: c.name }));
      options.unshift({ label: '', value: 0 });
      return <FormAutoCompleteCombo control={control} label={label} name="companyId" options={options} />
    }
  }, [mode, qCompanies.data, Entity.companyId]);

  return (
    <Container maxWidth="xl">
      <Box component="form" >
        <input hidden defaultValue={Entity.id} {...register('id')} />
        <Grid container alignItems="left" direction="column" spacing={1}>
          <Grid item>
            <FormInputText label="UserName" control={control} name="userName" InputProps={extraFieldProp} />
          </Grid>
          <Grid item>
            <FormInputText label="First Name" control={control} name="firstName" InputProps={extraFieldProp} />
          </Grid>
          <Grid item>
            <FormInputText label="Last Name" control={control} name="lastName" InputProps={extraFieldProp} />
          </Grid>
          <Grid item>
            <FormInputText label="email" control={control} name="email" InputProps={extraFieldProp} />
          </Grid>
          <QueryWrapper QueryResult={qCompanies}>
            <Grid item>
              {companySelector}
            </Grid>
            <Grid item >
              {formButtons}
            </Grid>
          </QueryWrapper>
        </Grid>
      </Box>
    </Container>
  );
}