import { Box, Container, Grid } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNtgIdGetAll } from "../../utils/ntgidapi/useNtgIdGetAll";
import { QueryWrapper } from "../../utils/QueryWrapper";
import { TextButton } from "../Buttons/TextButton";
import { BaseMode } from "../Common/BaseScopeAudience";
import { FormAutoCompleteCombo } from "../Form/FormAutocompleteCombo";
import { FormInputDropdown } from "../Form/FormInputDropDown";
import { FormInputText, FormInputTextRaw } from "../Form/FormInputText";
import { FormTextArea } from "../Form/FormTextArea";

export interface CompanyEntityStrict {
  id: number;
  name: string;
  description: string;
  groupId: number;
  googleHd: string;
}

export type CompanyBaseMode = BaseMode;

export type CompanyBaseProps = CompanyBasePropsWithButtons | CompanyBasePropsWithoutButtons;

export interface CompanyBasePropsWithButtons {
  Entity: CompanyEntityStrict;
  mode: CompanyBaseMode;
  onSubmit: (ent: CompanyEntityStrict) => void;
  onCancel?: () => void;
  ButtonLabel?: string;
  noButtons?: false;
}

export interface CompanyBasePropsWithoutButtons {
  Entity: CompanyEntityStrict;
  mode: 'view';
  noButtons: true;
  ButtonLabel?: undefined;
  onSubmit?: undefined;
  onCancel?: undefined;
}

export function CompanyBase({ Entity, mode, ButtonLabel, onSubmit, onCancel, noButtons }: CompanyBaseProps) {
  const { register, handleSubmit, control, formState: { errors }, reset } = useForm<CompanyEntityStrict>({
    defaultValues: Entity
  });
  const ntgIdAllData = useNtgIdGetAll();

  const onCancelWrap = useCallback( () => {
    reset();
    if (onCancel) {
      onCancel();
    }
  }, [onCancel, reset]);

  const cancelButton = useMemo(() => {
    if (onCancel)
      return <TextButton onClick={onCancelWrap}>Cancel</TextButton>;
    return undefined;
  }, [onCancelWrap, onCancel]);

  const formButtons = useMemo(() => {
    if (noButtons) {
      return undefined;
    }
    let buttonLabel: string | undefined = ButtonLabel;
    if (!buttonLabel) {
      if (mode === 'view') {
        buttonLabel = 'Edit';
      } else if (mode === 'edit') {
        buttonLabel = 'Save';
      } else {
        buttonLabel = 'Create';
      }
    }
    if (cancelButton) {
      return (<>
        {cancelButton}
        <TextButton onClick={handleSubmit(onSubmit)}>{buttonLabel}</TextButton>
      </>);
    }
    else return <TextButton onClick={handleSubmit(onSubmit)}>{buttonLabel}</TextButton>;
  }, [mode, ButtonLabel, cancelButton, noButtons]);

  const inputProps = {
    readOnly: (mode === 'view')
  };

  const groupSelector = useMemo(() => {
    if (!ntgIdAllData.data) {
      return;
    }
    const label = 'Group';
    const groups = ntgIdAllData.data.groups;
    if (mode !== 'create') {
      const groupName = groups.find(g => g.id === Entity.groupId)?.name || 'Error audience name';
      const extraProp = {
        readOnly: (mode === 'view'),
        disabled: (mode === 'edit'),
      }
      return <FormInputTextRaw label={label} name="groupName" value={groupName} {...extraProp} />;
    } else {
      const options = ntgIdAllData.data.groups.map(g => ({ value: g.id || 0, label: g.name }));
      return <FormAutoCompleteCombo control={control} label={label} name="groupId" options={options} />
    }
  }, [mode, ntgIdAllData.data, Entity.groupId]);

  return (
    <Container maxWidth="xl">
      <Box component="form" >
        <input hidden defaultValue={Entity.id} {...register('id')} />
        <Grid container alignItems="left" direction="column" spacing={1}>
          <Grid item>
            <FormInputText label="Name" control={control} name="name" InputProps={inputProps} />
          </Grid>
          <Grid item alignItems="center">
            <FormTextArea label="Description" control={control} name="description" minRows={2} fullWidth={true} InputProps={inputProps} />
          </Grid>
          <Grid item alignItems="center">
            <FormInputText label="Google Hd" control={control} name="googleHd" InputProps={inputProps} />
          </Grid>
          <QueryWrapper QueryResult={ntgIdAllData}>
            <Grid item>
              {groupSelector}
            </Grid>
            <Grid item >
              {formButtons}
            </Grid>
          </QueryWrapper>
        </Grid>
      </Box>
    </Container>
  );
}