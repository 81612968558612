import { useCallback } from "react";
import { BaseEntityStrict, BaseScopeAudience, BaseProps, BaseMode } from "../Common/BaseScopeAudience";

export type GroupEntityStrict = BaseEntityStrict;

export interface GroupBaseProps extends Omit<BaseProps, 'Entity'> {
  Group: GroupEntityStrict;
}

export type GroupBaseMode = BaseMode;

export function GroupBase({Group, ...rest}: GroupBaseProps) {
  const ButtonLabel = useCallback((mode: BaseMode) => (mode === 'view') ? 'Edit' : undefined, []);
  return <BaseScopeAudience {...{ButtonLabel, ...rest, Entity: Group, noDisabled: true}} />;
}