import { createTable } from "@tanstack/react-table";
import { ReactNode, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { flatGroup, flatNoGroup } from "../../utils/ntgidapi/ntgIdTypes";
import { ntgIdAllDataExtended, useNtgIdGetAllExtended } from "../../utils/ntgidapi/useNtgIdGetAll";
import { ScopeFlaggerType, scopesCellFormater } from "../../utils/baseScopeUtils";
import { TableUnifiedRootProps, TableUnified } from "../Table/TableUnified";
import { QueryWrapper } from "../../utils/QueryWrapper";
import { CustomLink } from "../Link/CustomLink";
import { TextButton } from "../Buttons/TextButton";
import { MutationFunction } from "react-query";
import { ConfirmDialog, useAxiosDescription, useConfirmDialog } from "../../hooks/useConfirmDialog";
import { FormInputDropdownOption, FormInputDropdownRaw } from "../Form/FormInputDropDown";
import { AddButtonIcon } from "../Buttons/AddButtonIcon";
import { RemoveButtonIcon } from "../Buttons/RemoveButtonIcon";
import { Visible } from "../Utils/visible";
import { Typography } from "@mui/material";
import { FormAutoCompleteComboRaw, FormAutocompleteRawOption } from "../Form/FormAutocompleteCombo";

interface fNGWithLast extends flatNoGroup {
  last: false;
}

interface fGWithLast extends flatGroup {
  last: false;
}

interface LastRole extends Omit<flatNoGroup, 'roleName'> {
  last: true;
  roleName: ReactNode;
}

type RoleWithLast = fNGWithLast | fGWithLast | LastRole;

let table = createTable().setRowType<RoleWithLast>();

type FilteredRolesProps = FilteredRolesPropsWithoutAdd | FilteredRolesPropsWithAddRemove;

interface FilteredRolesCommonProps extends TableUnifiedRootProps {
  roleFilter: (data: ntgIdAllDataExtended) => Array<flatNoGroup | flatGroup>;
  scopeFlagger?: ScopeFlaggerType;
  hideAudColumn?: boolean;
}

interface FilteredRolesPropsWithoutAdd extends FilteredRolesCommonProps {
  roleAddFn?: undefined;
  roleRemoveFn?: undefined;
  ensembleType?: undefined;
  ensembleName?: undefined;
}

interface FilteredRolesPropsWithAddRemove extends FilteredRolesCommonProps {
  roleAddFn: MutationFunction<unknown, number>;
  roleRemoveFn: MutationFunction<unknown, number>;
  ensembleType: string;
  ensembleName: string;
}

export function FilteredRoles({ hideAudColumn, roleFilter, scopeFlagger, roleAddFn, roleRemoveFn, ensembleType, ensembleName, ...extra }: FilteredRolesProps) {
  const qNtgIdAll = useNtgIdGetAllExtended();
  const dummyMut = useCallback(() => new Promise((resolve) => resolve(undefined)), []);
  const addConfirmDiag = useConfirmDialog({ mutationFn: (roleAddFn || dummyMut) });
  const removeConfirmDiag = useConfirmDialog({ mutationFn: (roleRemoveFn || dummyMut) });
  const [roleToAddId, setRoleToAddId] = useState(0);
  const descCallback = useCallback((roleId: number) => {
    return (<QueryWrapper QueryResult={qNtgIdAll}>
      <Typography variant='body1'>
        {ensembleType}: {ensembleName}
      </Typography>
      <Typography variant='body1'>
        Role: {qNtgIdAll.data?.roles.find(r => r.id === roleId)?.name}
      </Typography>
    </QueryWrapper>);
  }, [qNtgIdAll.data?.roles]);
  const desc = useAxiosDescription(descCallback);
  const removeTitle = useCallback(() => {
    return `Remove Role from ${ensembleType}`;
  }, [ensembleType]);
  const addTitle = useCallback(() => {
    return `Add Role to ${ensembleType}`;
  }, [ensembleType]);

  const flatRolesStart: Array<RoleWithLast> = useMemo(() => {
    if (!qNtgIdAll.data)
      return [];
    return roleFilter(qNtgIdAll.data).sort((a, b) => a.audienceId - b.audienceId).map(r => ({ ...r, last: false }));
  }, [qNtgIdAll.data, roleFilter]);

  const roleOptions = useMemo(() => {
    const selectableRoles = qNtgIdAll.data?.roles.filter(r => !flatRolesStart.find(fr => fr.roleId === r.id)) || [];
    const selectOptions: FormAutocompleteRawOption[] = selectableRoles.sort((a, b) => a.name.localeCompare(b.name)).map(r => ({ label: r.name, value: r.id || 0 }));
    selectOptions.unshift({ label: '', value: 0 });
    return selectOptions;
  }, [flatRolesStart, qNtgIdAll.data, roleToAddId]);

  const flatRoles: RoleWithLast[] = useMemo(() => {
    const selectedLastRole = qNtgIdAll.data?.flatRoles.get(roleToAddId);
    const lastRole: RoleWithLast = {
      roleId: 0,
      roleName: (<><FormAutoCompleteComboRaw label="Role" value={roleToAddId} onValueChange={setRoleToAddId} options={roleOptions} />
        <AddButtonIcon onClick={() => {
          if (roleToAddId > 0)
            addConfirmDiag.startDiag(roleToAddId);
        }} />
      </>),
      audienceId: selectedLastRole?.audienceId || 0,
      audienceName: selectedLastRole?.audienceName || '',
      scopes: selectedLastRole?.scopes || [],
      companyId: undefined,
      companyName: undefined,
      groupId: undefined,
      groupName: undefined,
      last: true
    };
    return roleAddFn ? flatRolesStart.concat([lastRole]) : flatRolesStart;
  }, [flatRolesStart, roleToAddId, roleOptions]);

  const columns = useMemo(() => {
    const ret = [
      table.createDataColumn('roleName', {
        header: () => <span>Role Name</span>,
        footer: props => props.column.id,
        cell: info => {
          const last = info.row.original?.last;
          if (!last) {
            const roleId = info.row.original?.roleId || 0;
            return (<>
              <CustomLink to={"/Role/" + roleId}>{info.getValue()}</CustomLink>
              <Visible visible={!!roleAddFn}>
                <RemoveButtonIcon onClick={() => removeConfirmDiag.startDiag(+roleId)} />
              </Visible>
            </>);
          } else {
            return info.getValue();
          }
        }
      }),
      table.createDataColumn('audienceName', {
        header: () => <span>Audience Name</span>,
        footer: props => props.column.id,
        cell: info => {
          const audienceId = info.row.original?.audienceId;
          return <CustomLink to={"/Audience/" + audienceId}>{info.getValue()}</CustomLink>
        }
      }),
      table.createDataColumn('scopes', {
        header: () => <span>Scopes</span>,
        footer: props => props.column.id,
        cell: info => scopesCellFormater(Array.from(info.getValue()), scopeFlagger)
      }),
    ];
    if (hideAudColumn) {
      var idx = ret.findIndex(e => e.id === 'audienceName');
      if (idx >= 0)
        ret.splice(idx, 1);
    }
    return ret;
  }, [!!roleAddFn, hideAudColumn]);

  return (<QueryWrapper QueryResult={qNtgIdAll}>
    <ConfirmDialog ConfirmDialogHookResult={addConfirmDiag} Description={desc} CloseOnSuccess onSuccess={() => { setRoleToAddId(0) }} Title={addTitle} />
    <ConfirmDialog ConfirmDialogHookResult={removeConfirmDiag} Description={desc} CloseOnSuccess onSuccess={() => { }} Title={removeTitle} />
    <TableUnified table={table} columns={columns} data={flatRoles} columnIds={hideAudColumn ? [] : ["audienceName"]} withLastRow {...extra} />
  </QueryWrapper>);
}

export function Roles() {
  const filter = useCallback(function (data: ntgIdAllDataExtended) {
    return Array.from(data.flatRoles.values());
  }, []);
  const navigate = useNavigate();

  return (<>
    <TextButton onClick={() => navigate('/CreateRole')}>Create Role</TextButton>
    <FilteredRoles roleFilter={filter} withFilters />
  </>);
}