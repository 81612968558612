import { AxiosRequestConfig } from "axios";
import { useEffect } from "react";
import { JsxElement } from "typescript";
import { ntgIdApi } from "../../utils/ntgidapi/NtgIdApi";

export function AxiosError({ error, text = undefined }: { error: any, text: string | undefined }) {
  // Code skeleton and comments from https://axios-http.com/docs/handling_errors

  useEffect(() => {
    const errors = Array.isArray(error) ? error : [error];

    errors.forEach(err => {
      if (err.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(err.response.data);
        console.log(err.response.status);
        console.log(err.response.headers);
        if (err.response.status === 401) {
          ntgIdApi.isLoggedIn();
        }
      } else if (err.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(err.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', err.message);
      }
      console.log(err.config);
    });
  });

  if (text)
    return <div>Error loading {text}</div>;
  else
    return <div>Error loading</div>;
}