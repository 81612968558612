import { Typography } from "@mui/material";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ConfirmDialog, useAxiosDescription, useConfirmDialog } from "../../hooks/useConfirmDialog";
import { ntgIdApi } from "../../utils/ntgidapi/NtgIdApi";
import { GroupBase, GroupEntityStrict } from "./GroupBase";

export function GroupCreate() {
  const audCreateMut = useConfirmDialog({ mutationFn: (aud: GroupEntityStrict) => ntgIdApi.v0GroupCreate(aud) });
  const descCallback = useCallback((arg: GroupEntityStrict) => (<>
    <Typography variant="h6">Description</Typography>
    <Typography variant="body1">{arg.description}</Typography>
  </>), []);
  const description = useAxiosDescription(descCallback);

  const audBaseSubmit = useCallback((aud: GroupEntityStrict) => {
    audCreateMut.startDiag(aud);
  }, [audCreateMut.startDiag]);

  const Group = {
    id: 0,
    name: '',
    description: ''
  }

  const navigate = useNavigate();

  return (<>
    <Typography variant='h5'>Create group</Typography>
    <ConfirmDialog ConfirmDialogHookResult={audCreateMut} Description={description} Title={(arg) => `Create group ${arg.name}`} onSuccess={(data) => navigate(`/Group/${data.data.id}`)} CloseOnSuccess={true} />
    <GroupBase mode='create' Group={Group} onSubmit={audBaseSubmit} />
  </>);
}