import { Box, Container, Grid } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNtgIdGetAll } from "../../utils/ntgidapi/useNtgIdGetAll";
import { QueryWrapper } from "../../utils/QueryWrapper";
import { BaseMode } from "../Common/BaseScopeAudience";
import { useFormButtons } from "../Common/hooks/useFormButtons";
import { FormAutoCompleteCombo } from "../Form/FormAutocompleteCombo";
import { FormInputDropdown } from "../Form/FormInputDropDown";
import { FormInputText, FormInputTextRaw } from "../Form/FormInputText";
import { FormTextArea } from "../Form/FormTextArea";

export interface GoogleClientEntityStrict {
  id: number;
  googleClientId: string;
  description: string;
  audienceId: number;
}

export type GoogleClientBaseMode = BaseMode;

export interface GoogleClientBaseProps {
  Entity: GoogleClientEntityStrict;
  mode: GoogleClientBaseMode;
  onSubmit: (ent: GoogleClientEntityStrict) => void;
  onCancel?: () => void;
  noButtons?: boolean;
}

export function GoogleClientBase({ Entity, mode, onSubmit, onCancel, noButtons }: GoogleClientBaseProps) {
  const { register, handleSubmit, control, formState: { errors }, reset } = useForm<GoogleClientEntityStrict>({
    defaultValues: Entity
  });
  const ntgIdAllData = useNtgIdGetAll();
  const ButtonLabel = useCallback((m: BaseMode) => m === 'view' ? 'Edit Description' : undefined, []);

  const formButtons = useFormButtons({ mode, onSubmit, onCancel, noButtons, reset, resetParam: Entity, handleSubmit, ButtonLabel });

  const audSelector = useMemo(() => {
    if (!ntgIdAllData.data) {
      return;
    }
    const label = 'Audience';
    const auds = ntgIdAllData.data.audiences;
    if (mode !== 'create') {
      const audName = auds.find(a => a.id === Entity.audienceId)?.name || 'Error audience name';
      const extraProp = {
        readOnly: (mode === 'view'),
        disabled: (mode === 'edit'),
      }
      return <FormInputTextRaw label={label} name="audienceName" value={audName} {...extraProp} />;
    } else {
      const options = ntgIdAllData.data.audiences.map(a => ({ value: a.id || 0, label: a.name }));
      return <FormAutoCompleteCombo control={control} label={label} name="audienceId" options={options} />
    }
  }, [mode, ntgIdAllData.data, Entity.audienceId]);

  const inputProps = useMemo(() => ({
    readOnly: (mode === 'view'),
    disabled: (mode === 'edit')
  }), [mode]);

  const inputPropsDescription = useMemo(() => ({
    readOnly: (mode === 'view')
  }), [mode]);

  return (
    <Container maxWidth="xl">
      <Box component="form" >
        <input hidden defaultValue={Entity.id} {...register('id')} />
        <Grid container alignItems="left" direction="column" spacing={1}>
          <Grid item>
            <FormInputText label="Google Client Id" control={control} name="googleClientId" InputProps={inputProps} />
          </Grid>
          <Grid item alignItems="center">
            <FormTextArea label="Description" control={control} name="description" minRows={2} fullWidth={true} InputProps={inputPropsDescription} />
          </Grid>
          <QueryWrapper QueryResult={ntgIdAllData}>
            <Grid item>
              {audSelector}
            </Grid>
            <Grid item >
              {formButtons}
            </Grid>
          </QueryWrapper>
        </Grid>
      </Box>
    </Container>
  );
}